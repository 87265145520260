import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../../../hooks/useNotification";
import { IItemDetails, INameValueResponseDto } from '../../../../models/CommonModels';
import { ICustomerContactResponseDto } from '../../../../models/CustomerModels';
import CustomerService from '../../../../services/CustomerService';
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";
import ItemsDetails from "../../../Base/ItemsDetailsComponent/ItemsDetails";
import CustomerContactsDialog from './CustomerContactsDialog';

const displayContact = (contact?: INameValueResponseDto): string => {
    if (!contact || (!contact.name && !contact.value)) {
        return '';
    }

    if (contact.name && contact.value) {
        return `${contact.name} - ${contact.value}`;
    }

    if (contact.name) {
        return contact.name;
    }

    if (contact.value) {
        return contact.value;
    }

    return '';
};

interface IProps {
    customerId: string;
    readonly: boolean;
}
const CustomerContacts = (props: IProps) => {
    const { customerId } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [data, setData] = useState<ICustomerContactResponseDto>();

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const [dialog, setDialog] = useState(false);

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await CustomerService.fetchContacts(customerId);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    useEffect(() => {
        retrieveData();
    }, [refresh, retrieveData]);

    const items: IItemDetails[] = [{
        id: `${data?.email}-email`,
        primary: t('EMAIL'),
        secondary: data?.email
    }, {
        id: `${data?.phone}-phone`,
        primary: t('PHONE'),
        secondary: data?.phone
    }, {
        id: `contact1`,
        primary: t('CONTACT 1'),
        secondary: displayContact(data?.contact1)
    }, {
        id: `contact2`,
        primary: t('CONTACT 2'),
        secondary: displayContact(data?.contact2)
    }, {
        id: `contact3`,
        primary: t('CONTACT 3'),
        secondary: displayContact(data?.contact3)
    }];

    const dialogToggleHandler = useCallback(() => {
        setDialog(dialog => !dialog);
    }, []);

    const refreshHandler = useCallback(() => {
        setRefresh(refresh => !refresh);
    }, []);

    return (
        <>
            <BackdropProgress open={loading || false}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title={t('EDIT')} placement='top' >
                        <span>
                            <Button
                                onClick={dialogToggleHandler}
                                size='small'
                                variant='outlined'>
                                <EditIcon fontSize='small' />&nbsp;{t('EDIT')}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
                <ItemsDetails data={items} />
            </BackdropProgress>

            {dialog && data &&
                <CustomerContactsDialog
                    open={dialog}
                    entity={data}
                    customerId={customerId}
                    onCloseBtnClick={dialogToggleHandler}
                    onSubmitBtnClick={refreshHandler}
                />
            }
        </>
    );
}
export default CustomerContacts;