import { useDispatch } from "react-redux";
import { IAppPreference, IGlobalPreference, ISystemPreference, IUserPreference } from "../models/PreferenceModels";
import { PreferenceActions } from "../store/reducers/preferenceSlice";

export const usePreference = () => {
    const dispatch = useDispatch();

    const updateGlobalPreference = (preference: IGlobalPreference) => {
        dispatch(PreferenceActions.updateGlobalPreference(preference));
    };

    const updateUserPreference = (preference: IUserPreference) => {
        dispatch(PreferenceActions.updateUserPreference(preference));
    };

    const updateAppPreference = (preference: IAppPreference) => {
        dispatch(PreferenceActions.updateAppPreference(preference));
    };

    const updateSystemPreference = (preference: ISystemPreference) => {
        dispatch(PreferenceActions.updateSystemPreference(preference));
    };

    return { updateGlobalPreference, updateUserPreference, updateAppPreference, updateSystemPreference } as const;
};