import { IFilter } from "../models/CommonModels";
import { ICustomerContactRequestDto, ICustomerDocumentRequestDto, ICustomerRequestDto, ICustomerStatusReputationRequestDto } from "../models/CustomerModels";
import { IReviewRequestDto } from "../models/ReviewModels";
import { API } from "./APIService";

class CustomerService {

    public static fetchDocuments = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `customer/${uuid}/documents`);
        return [error, response];
    };

    public static createDocument = async (customerId: string, data: ICustomerDocumentRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `customer/${customerId}/documents`, data);
        return [error, response];
    };

    public static updateDocument = async (customerId: string, documentId: string, data: ICustomerDocumentRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `customer/${customerId}/documents/${documentId}`, data);
        return [error, response];
    };

    public static removeDocument = async (customerId: string, documentId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `customer/${customerId}/documents/${documentId}`);
        return [error, response];
    };

    public static createDocumentAttachment = async (customerId: string, documentId: string, data: FormData): Promise<any> => {
        const [error, response] = await API('PUT', `customer/${customerId}/documents/${documentId}/attachments`, data, undefined, 'multipart/form-data');
        return [error, response];
    };

    public static removeDocumentAttachment = async (customerId: string, documentId: string, attachmentId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `customer/${customerId}/documents/${documentId}/attachments/${attachmentId}`);
        return [error, response];
    };

    public static fetchAllByFilter = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'customer/all', JSON.stringify(filter));
        return [error, response];
    };

    public static downloadReport = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'customer/report', filter, undefined, undefined, 'blob');
        return [error, response];
    };

    public static fetchCoverFaceData = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `customer/${uuid}/coverFace`);
        return [error, response];
    };

    public static remove = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', `customer/${uuid}`)
        return [error, response];
    };

    public static createFavorites = async (ids: string[]): Promise<any> => {
        const [error, response] = await API('POST', 'customer/favorite', ids);
        return [error, response];
    };

    public static removeFavorites = async (ids: string[]): Promise<any> => {
        const [error, response] = await API('DELETE', 'customer/favorite', ids);
        return [error, response];
    };

    public static create = async (data: ICustomerRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'customer', data);
        return [error, response];
    };

    public static fetchOverview = async (customerId: string): Promise<any> => {
        const [error, response] = await API('GET', `customer/${customerId}/overview`);
        return [error, response];
    };

    public static updateOverview = async (customerId: string, data: ICustomerRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `customer/${customerId}/overview`, data);
        return [error, response];
    };

    public static fetchDescription = async (customerId: string): Promise<any> => {
        const [error, response] = await API('GET', `customer/${customerId}/description`);
        return [error, response];
    };

    public static updateDescription = async (customerId: string, description?: string): Promise<any> => {
        const [error, response] = await API('PUT', `customer/${customerId}/description`, description);
        return [error, response];
    };

    public static fetchContacts = async (customerId: string): Promise<any> => {
        const [error, response] = await API('GET', `customer/${customerId}/contacts`);
        return [error, response];
    };

    public static updateContacts = async (customerId: string, data: ICustomerContactRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `customer/${customerId}/contacts`, data);
        return [error, response];
    };

    public static fetchAutocomplete = async (): Promise<any> => {
        const [error, response] = await API('GET', 'customer/autocomplete');
        return [error, response];
    };

    public static fetchStatusAndReputation = async (customerId: string): Promise<any> => {
        const [error, response] = await API('GET', `customer/${customerId}/statusAndReputation`);
        return [error, response];
    };

    public static updateStatusAndReputation = async (customerId: string, data: ICustomerStatusReputationRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `customer/${customerId}/statusAndReputation`, data);
        return [error, response];
    };

    public static isIdnoAvailable = async (idno: string): Promise<any> => {
        const [error, response] = await API('GET', `customer/available/idno/${idno}`);
        return [error, response];
    };

    public static fetchReviews = async (customerId: string): Promise<any> => {
        const [error, response] = await API('GET', `customer/${customerId}/reviews`);
        return [error, response];
    };

    public static createReview = async (customerId: string, data: IReviewRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `customer/${customerId}/reviews`, data);
        return [error, response];
    };

    public static updateReview = async (customerId: string, reviewId: string, data: IReviewRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `customer/${customerId}/reviews/${reviewId}`, data);
        return [error, response];
    };

    public static removeReview = async (customerId: string, reviewId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `customer/${customerId}/reviews/${reviewId}`);
        return [error, response];
    };

    public static getFavorites = async (): Promise<any> => {
        const [error, response] = await API('GET', 'customer/favorite');
        return [error, response];
    };
}
export default CustomerService;