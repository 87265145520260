import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

interface IProps {
    label?: string;
    required?: boolean;
    disabled?: boolean;
    value?: any;
    data: string[];
    size?: 'small' | 'medium';
    fullWidth?: boolean;
    disableItems?: string[];
    onChange?: (event: SelectChangeEvent) => void;
}
const StatusSelect = (props: IProps) => {
    const {
        value, label = 'Status', fullWidth, required, disabled = false,
        onChange, data, size = 'small', disableItems = []
    } = props;
    const [selectedValue, setSelectedValue] = useState<string>('');

    useEffect(() => {
        setSelectedValue(value ? value.toString() : '');
    }, [value]);

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
    }, [onChange]);

    return (
        <FormControl disabled={disabled} fullWidth={fullWidth} required={required} size={size}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem value=''><em>None</em></MenuItem>
                {
                    data.map(item =>
                        <MenuItem
                            disabled={disableItems.includes(item)}
                            key={item}
                            value={item}
                        >
                            {item.replaceAll('_', ' ')}
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    );
}
export default StatusSelect;