import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IMenuAppBarBtn } from '../../models/CommonModels';

const activeStyle = {
    color: 'white',
    backgroundColor: '#ffffff66',
    cursor: 'default'
};

const inactiveStyle = {
    color: 'white'
};

interface IProps {
    item: IMenuAppBarBtn;
    active?: boolean;
    onClick: (path: string) => void;
}
const MenuBtn = (props: IProps) => {
    const { item, active = false, onClick } = props;
    const { t } = useTranslation();

    const onClickHandler = useCallback(() => {
        if (!active) {
            onClick(item.path);
        }
    }, [active, item.path, onClick]);

    const style: {} = active ? activeStyle : inactiveStyle;

    return (
        <Tooltip title={t(item.tooltip)} placement={'bottom'} arrow>
            <span>
                <Button
                    sx={style}
                    onClick={onClickHandler}
                    disabled={item.disabled}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={0.5}
                        marginTop={0.5}
                    >
                        {item.icon}
                        <Typography variant='caption'>{t(item.label)}</Typography>
                    </Box>
                </Button>
            </span>
        </Tooltip>
    );
}
export default MenuBtn;