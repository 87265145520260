import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Box, Checkbox, FormControlLabel, IconButton, SelectChangeEvent, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { isEmpty } from '../../../../helpers/generalHelper';
import { isBlank } from '../../../../helpers/textHelper';
import { useNotification } from '../../../../hooks/useNotification';
import { EFragilityLevel } from "../../../../models/CommonModels";
import { ELoadAppointmentType, ELoadItemDirection, ELoadItemStatus, ELoadItemType, ELoadShipperConsigneeDirection, ILoadItemDetailsResponseDto, ILoadItemPalletTypeQuantityRequestDto, ILoadItemPalletTypeQuantityResponseDto, ILoadItemRequestDto, ILoadShipperConsigneeResponseDto } from "../../../../models/LoadModels";
import LoadService from '../../../../services/LoadService';
import { RootState } from '../../../../store/store';
import BaseCrudDialog from '../../../Base/BaseCrudDialogComponent/BaseCrudDialog';
import DateField from '../../../Base/DateComponent/DateField';
import TimeField from '../../../Base/DateComponent/TimeField';
import IdnoGenerator from '../../../Base/IdnoGeneratorComponent/IdnoGenerator';
import NumberField from '../../../Base/NumberFieldComponent/NumberField';
import StatusSelect from '../../../Base/StatusSelectComponent/StatusSelect';
import TextareaField from '../../../Base/TextareaFieldComponent/TextareaField';
import FragilityLevelSelect from '../../Fragility/FragilityLevelSelect';
import PalletTypeQuantity from '../../PalletTypeQuantity/PalletTypeQuantity';
import ShipperConsigneeAutocomplete from '../../ShipperConsignee/ShipperConsigneeAutocomplete';
import ShipperConsigneeDialog from '../../ShipperConsignee/ShipperConsigneeDialog';
import LoadItemTypeSelect from '../../Type/LoadItemTypeSelect';

const formId: string = 'load-item-form';
const statusData: string[] = Object.keys(ELoadItemStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== ELoadItemStatus[ELoadItemStatus.NONE])
    .map(key => key.toString());

const convertPalletsResponseToRequest = (pallets: ILoadItemPalletTypeQuantityResponseDto[])
    : ILoadItemPalletTypeQuantityRequestDto[] => {
    return pallets.map((item: ILoadItemPalletTypeQuantityResponseDto) => {
        return {
            palletTypeId: item.pallet.uuid,
            quantity: item.quantity
        };
    });
}

interface IProps {
    open: boolean;

    loadId: string;
    direction: ELoadItemDirection;
    entity?: ILoadItemDetailsResponseDto;
    copy?: boolean;

    onCloseBtnClick: () => void;
    onSubmitBtnClick: () => void;
}
const LoadItemsDialog = (props: IProps) => {
    const { open, loadId, direction, entity, copy = false, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { temperature, mass } = useSelector((state: RootState) => state.preferenceSlice.global);
    const { dateFormat } = useSelector((state: RootState) => state.preferenceSlice.user);

    const [loading, setLoading] = useState(false);
    const [shipperConsignee, setShipperConsignee] = useState<ILoadShipperConsigneeResponseDto>();

    const [shipperConsigneeToggle, setShipperConsigneeToggle] = useState<boolean>(false);
    const [shipperConsigneeRefresh, setShipperConsigneeRefresh] = useState<boolean>(false);
    const [showTime, setShowTime] = useState<boolean>(entity ? entity.showTime : false);

    const { register, setValue, getValues, setError, clearErrors, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<ILoadItemRequestDto>({
        defaultValues: {
            direction: direction,
            shipperConsigneeId: entity ? entity.shipperConsigneeId : '',
            idno: entity && !copy ? entity.idno : '',
            description: entity ? entity.description || '' : '',
            type: entity ? entity.type : ELoadItemType.NONE,
            quantity: entity ? entity.quantity : NaN,
            pallets: entity && entity.pallets ? convertPalletsResponseToRequest(entity.pallets) : [],
            highValue: entity ? entity.highValue : false,
            highValueNotes: entity ? entity.highValueNotes || '' : '',
            fragility: entity ? entity.fragility : EFragilityLevel.NONE,
            weight: entity ? entity.weight : NaN,
            temperatureFrom: entity ? entity.temperatureFrom : NaN,
            temperatureTo: entity ? entity.temperatureTo : NaN,
            notes: entity ? entity.notes || '' : '',
            showTime: entity ? entity.showTime : false,
            date: entity && !copy ? entity.date : NaN,
            time: entity && !copy ? entity.time : undefined,
            status: entity && !copy ? entity.status : ELoadItemStatus.OPEN
        }
    });

    const createData = useCallback((data: ILoadItemRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.createItem(loadId, data);
            if (response) {
                displayNotification({ message: t(`${direction} was successfully created.`) });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t]);

    const updateData = useCallback((uuid: string, data: ILoadItemRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateItem(loadId, uuid, data);
            if (response) {
                displayNotification({ message: t(`${direction} was successfully updated.`) });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t]);

    const onSubmit = useCallback((data: ILoadItemRequestDto) => {
        if (entity && !copy) {
            updateData(entity.uuid, data);
        } else {
            createData(data);
        }
    }, [copy, createData, entity, updateData]);

    const validateIdnoField = useCallback((value: string) => {
        return !isBlank(value) && !errors?.idno;
    }, [errors?.idno]);

    register('idno', { validate: validateIdnoField });
    const onChangeIdnoHandler = useCallback((value: string, error?: string) => {
        if (error) {
            setError('idno', { message: error });
        } else {
            clearErrors('idno');
        }

        setValue('idno', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [clearErrors, setError, setValue]);

    register('description');
    const onDescriptionChangeHandler = useCallback((value: string | undefined) => {
        setValue('description', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('notes');
    const onNotesChangeHandler = useCallback((value: string | undefined) => {
        setValue('notes', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateLoadItemTypeField = useCallback((value: ELoadItemType) => {
        return ELoadItemType[value] !== undefined && value !== ELoadItemType.NONE;
    }, []);

    register('type', { validate: validateLoadItemTypeField });
    const onLoadItemTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: ELoadItemType = event.target.value as ELoadItemType;
        setValue('type', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateFragilityLevelField = useCallback((value: EFragilityLevel) => {
        return EFragilityLevel[value] !== undefined && value !== EFragilityLevel.NONE;
    }, []);

    register('fragility', { validate: validateFragilityLevelField });
    const onFragilityLevelChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: EFragilityLevel = event.target.value as EFragilityLevel;
        setValue('fragility', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateNumberField = useCallback((value?: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    const validateQuantityField = useCallback((value?: number) => {
        if (value === undefined || isNaN(value)) {
            return false;
        }

        const pallets: ILoadItemPalletTypeQuantityRequestDto[] | undefined = getValues('pallets');
        if (pallets && !isEmpty(pallets)) {
            const sumQuantity: number = pallets.reduce((sum, item) => sum + item.quantity, 0);
            if (value !== sumQuantity) {
                return false;
            }
        }

        return true;
    }, [getValues]);

    register('quantity', { validate: validateQuantityField });
    const onQuantityChangeHandler = useCallback((value: number) => {
        setValue('quantity', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('weight', { validate: validateNumberField });
    const onWeightChangeHandler = useCallback((value: number) => {
        setValue('weight', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('temperatureFrom');
    const onTemperatureFromChangeHandler = useCallback((value?: number) => {
        setValue('temperatureFrom', value || NaN, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('temperatureTo');
    const onTemperatureToChangeHandler = useCallback((value?: number) => {
        setValue('temperatureTo', value || NaN, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateShipperConsigneeField = useCallback((uuid: string) => {
        return uuid !== undefined && !isBlank(uuid);
    }, []);

    register('shipperConsigneeId', { validate: validateShipperConsigneeField });
    const onShipperConsigneeChangeHandler = useCallback((value: ILoadShipperConsigneeResponseDto | null) => {
        setValue('shipperConsigneeId', value ? value.uuid : '', {
            shouldValidate: true,
            shouldDirty: true
        });

        const showTime: boolean = (value && value.appointments === ELoadAppointmentType.YES) || false;
        setValue('showTime', showTime, { shouldValidate: true });
        setValue('time', undefined, { shouldValidate: true });

        setShowTime(showTime);
        setShipperConsignee(value || undefined);
    }, [setValue]);

    const onShipperConsigneeInitHandler = useCallback((value: ILoadShipperConsigneeResponseDto | null) => {
        const showTime: boolean = (value && value.appointments === ELoadAppointmentType.YES) || false;
        setValue('showTime', showTime, { shouldValidate: true });
        setShowTime(showTime);
        setShipperConsignee(value || undefined);
    }, [setValue]);

    register('highValue');
    const onHighValueChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = event.target.checked;
        setValue('highValue', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('highValueNotes');
    const onHighValueNotesChangeHandler = useCallback((value: string) => {
        setValue('highValueNotes', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateDateField = useCallback((value?: number) => {
        if (showTime && (!value || isNaN(value))) {
            return false;
        }
        return true;
    }, [showTime]);

    register('date', { validate: validateDateField });
    const onDateChangeHandler = useCallback((value?: number) => {
        setValue('date', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateTimeField = useCallback((value?: string) => {
        if (showTime && (!value || isBlank(value))) {
            return false;
        }
        return true;
    }, [showTime]);

    register('time', { validate: validateTimeField });
    const onTimeChangeHandler = useCallback((value?: string) => {
        setValue('time', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('pallets');
    const onPalletsChangeHandler = useCallback((value?: ILoadItemPalletTypeQuantityRequestDto[]) => {
        setValue('pallets', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateStatusField = useCallback((value: ELoadItemStatus) => {
        return ELoadItemStatus[value] !== undefined && value !== ELoadItemStatus.NONE;
    }, []);

    register('status', { validate: validateStatusField });
    const onChangeStatusHandler = useCallback((event: SelectChangeEvent) => {
        setValue('status', event.target.value as ELoadItemStatus, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onShipperConsigneeToggleHandler = useCallback(() => {
        setShipperConsigneeToggle(shipperConsigneeToggle => !shipperConsigneeToggle);
    }, []);

    const onSubmitShipperConsigneeHandler = useCallback((entityId?: string) => {
        if (entityId) {
            setValue('shipperConsigneeId', entityId, {
                shouldValidate: true,
                shouldDirty: true
            });
            setShipperConsigneeRefresh(shipperConsigneeRefresh => !shipperConsigneeRefresh);
        }
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack direction='row' spacing={2}>
                    <Stack direction='column' spacing={2} width={'100%'}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                            <ShipperConsigneeAutocomplete
                                direction={direction.toString() as ELoadShipperConsigneeDirection}
                                label={t(direction.toString())}
                                required
                                value={getValues('shipperConsigneeId')}
                                disableInactiveItems
                                refresh={shipperConsigneeRefresh}
                                onChange={onShipperConsigneeChangeHandler}
                                onInit={onShipperConsigneeInitHandler}
                            />

                            <Tooltip title={t(`CREATE ${direction}`)}>
                                <IconButton onClick={onShipperConsigneeToggleHandler}>
                                    <PlaylistAddIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <TextareaField
                            label={t('ADDRESS')}
                            required
                            rows={2}
                            readOnly
                            value={shipperConsignee?.address.value || ''}
                        />

                        <TextField
                            label={t('CONTACT NAME')}
                            value={shipperConsignee?.contactName || ''}
                            slotProps={{ htmlInput: { readOnly: true } }}
                        />

                        <TextField
                            label={t('CONTACT EMAIL')}
                            value={shipperConsignee?.contactEmail || ''}
                            slotProps={{ htmlInput: { readOnly: true } }}
                        />

                        <Stack direction='row' spacing={2}>
                            <TextField
                                label={t('PHONE')}
                                fullWidth
                                value={shipperConsignee?.phone || ''}
                                slotProps={{ htmlInput: { readOnly: true } }}
                            />

                            <TextField
                                label={t('TOLL FREE')}
                                fullWidth
                                value={shipperConsignee?.tollFree || ''}
                                slotProps={{ htmlInput: { readOnly: true } }}
                            />
                        </Stack>

                        <Stack direction='row' spacing={2}>
                            <TextField
                                label={t('ACCEPTED HOURS')}
                                fullWidth
                                value={shipperConsignee?.hours || ''}
                                slotProps={{ htmlInput: { readOnly: true } }}
                            />

                            <TextField
                                label={t('APPOINTMENTS')}
                                required
                                sx={{ width: '50%' }}
                                value={shipperConsignee?.appointments || ''}
                                slotProps={{ htmlInput: { readOnly: true } }}
                            />
                        </Stack>

                        <TextField
                            label={t('MAJOR INTERSECTIONS / DIRECTIONS')}
                            value={shipperConsignee?.majorIntersectionsDirections || ''}
                            slotProps={{ htmlInput: { readOnly: true } }}
                        />
                    </Stack>

                    <Stack direction='column' spacing={2} width={'100%'}>
                        <IdnoGenerator
                            required
                            value={getValues('idno')}
                            label={t('ID#')}
                            generateBtnTooltip={t('GENERATE')}
                            errorMessage={t('The value is used. Choose another value.')}
                            onChange={onChangeIdnoHandler}
                        />

                        <TextareaField
                            label={t('DESCRIPTION')}
                            rows={2}
                            value={getValues('description')}
                            onChange={onDescriptionChangeHandler}
                        />

                        <TextareaField
                            label={t(`${direction} NOTES`)}
                            rows={1}
                            maxLength={50}
                            value={getValues('notes')}
                            onChange={onNotesChangeHandler}
                        />

                        <Stack direction='row' spacing={2}>
                            <LoadItemTypeSelect
                                label={t('TYPE')}
                                required
                                value={getValues('type')}
                                onChange={onLoadItemTypeChangeHandler}
                            />

                            <FragilityLevelSelect
                                label={t('FRAGILITY')}
                                required
                                value={getValues('fragility')}
                                onChange={onFragilityLevelChangeHandler}
                            />
                        </Stack>

                        <Stack direction='row' spacing={2}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={getValues('highValue')}
                                    onChange={onHighValueChangeHandler}
                                />}
                                label={t('HIGH VALUE')}
                                sx={{ width: '50%' }}
                            />

                            <Box width={'100%'}>
                                <TextareaField
                                    label={t('HIGH VALUE NOTES')}
                                    rows={1}
                                    value={getValues('highValueNotes')}
                                    onChange={onHighValueNotesChangeHandler}
                                />
                            </Box>
                        </Stack>

                        <Stack direction='row' spacing={2}>
                            <NumberField
                                label={t('TEMPERATURE FROM')}
                                value={getValues('temperatureFrom')}
                                allowNegative={false}
                                units={temperature}
                                onChange={onTemperatureFromChangeHandler}
                            />

                            <NumberField
                                label={t('TEMPERATURE TO')}
                                value={getValues('temperatureTo')}
                                allowNegative={false}
                                units={temperature}
                                onChange={onTemperatureToChangeHandler}
                            />
                        </Stack>

                        <Stack direction='row' spacing={2}>
                            <NumberField
                                required
                                label={t('WEIGHT')}
                                value={getValues('weight')}
                                allowNegative={false}
                                units={mass}
                                onChange={onWeightChangeHandler}
                            />

                            <NumberField
                                required
                                label={t('QUANTITY')}
                                value={getValues('quantity')}
                                scale={0}
                                allowNegative={false}
                                onChange={onQuantityChangeHandler}
                            />
                        </Stack>
                    </Stack>

                    <Stack direction='column' spacing={2} width={'100%'}>
                        <PalletTypeQuantity
                            data={entity ? entity.pallets : undefined}
                            maxQuantity={getValues('quantity')}
                            onChange={onPalletsChangeHandler}
                        />

                        <DateField
                            label={t('DATE')}
                            required={getValues('showTime')}
                            size='medium'
                            value={getValues('date') || NaN}
                            format={dateFormat}
                            onChange={onDateChangeHandler}
                        />

                        <Stack direction='row' spacing={2}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={getValues('showTime')}
                                />}
                                label={t('SHOW TIME')}
                                sx={{ width: '100%' }}
                            />

                            <TimeField
                                label={t('TIME')}
                                required={getValues('showTime')}
                                size='medium'
                                hideSeconds
                                disabled={!getValues('showTime')}
                                fullWidth
                                value={getValues('time')}
                                onChange={onTimeChangeHandler}
                            />
                        </Stack>

                        <StatusSelect
                            label={t('STATUS')}
                            required
                            data={statusData}
                            value={getValues('status')}
                            size='medium'
                            onChange={onChangeStatusHandler}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [
        dateFormat, direction, entity, getValues, handleSubmit, mass, onChangeIdnoHandler,
        onChangeStatusHandler, onDateChangeHandler, onDescriptionChangeHandler,
        onFragilityLevelChangeHandler, onHighValueChangeHandler, onHighValueNotesChangeHandler,
        onLoadItemTypeChangeHandler, onNotesChangeHandler, onPalletsChangeHandler,
        onQuantityChangeHandler, onShipperConsigneeChangeHandler, onShipperConsigneeInitHandler,
        onShipperConsigneeToggleHandler, onSubmit, onTemperatureFromChangeHandler,
        onTemperatureToChangeHandler, onTimeChangeHandler, onWeightChangeHandler,
        shipperConsignee?.address.value, shipperConsignee?.appointments,
        shipperConsignee?.contactEmail, shipperConsignee?.contactName, shipperConsignee?.hours,
        shipperConsignee?.majorIntersectionsDirections, shipperConsignee?.phone,
        shipperConsignee?.tollFree, shipperConsigneeRefresh, t, temperature
    ]);

    return (
        <>
            <BaseCrudDialog
                loading={loading}
                open={open}
                title={t(`${entity && !copy ? `EDIT ${entity.direction}` : `CREATE ${direction}`}`)}
                maxWidth={'xl'}
                formId={formId}
                buildContent={onBuildContent}
                saveBtnDisabled={!isValid || !isDirty}
                saveBtnLabel={t('SAVE')}
                onCloseBtnClick={onCloseBtnClick}
                closeBtnLabel={t('CLOSE')}
            />

            {shipperConsigneeToggle &&
                <ShipperConsigneeDialog
                    open={shipperConsigneeToggle}
                    direction={direction.toString() as ELoadShipperConsigneeDirection}
                    onSubmitBtnClick={onSubmitShipperConsigneeHandler}
                    onCloseBtnClick={onShipperConsigneeToggleHandler}
                />
            }
        </>
    );
}
export default LoadItemsDialog;