import { IAppPreference, IPreferenceDateTimeRequestDto, IPreferenceLanguageRequestDto, IUserPreferenceNotificationRequestDto } from "../models/PreferenceModels";
import { API } from "./APIService";

class UserPreferenceService {
    public static fetch = async (): Promise<any> => {
        const [error, response] = await API('GET', "userPreference");
        return [error, response];
    };

    public static updateLanguage = async (data: IPreferenceLanguageRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', 'userPreference/language', JSON.stringify(data));
        return [error, response];
    };

    public static updateDateTime = async (data: IPreferenceDateTimeRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', 'userPreference/dateTime', JSON.stringify(data));
        return [error, response];
    };

    public static updateNotification = async (data: IUserPreferenceNotificationRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', 'userPreference/notification', JSON.stringify(data));
        return [error, response];
    };

    public static fetchApp = async (): Promise<any> => {
        const [error, response] = await API('GET', 'userPreference/webapp');
        return [error, response];
    };

    public static updateApp = async (data: IAppPreference): Promise<any> => {
        const dataAsStr: string = JSON.stringify(data);
        const [error, response] = await API('PUT', 'userPreference/webapp', dataAsStr);
        return [error, response];
    };
}
export default UserPreferenceService;