import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { EFrequency } from "../../../../models/CommonModels";
import { EUserPayType, IUserPayRequestDto, IUserPayResponseDto } from "../../../../models/UserModels";
import UserService from "../../../../services/UserService";
import { RootState } from "../../../../store/store";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import CurrencyField from "../../../Base/CurrencyFieldComponent/CurrencyField";
import DateField from "../../../Base/DateComponent/DateField";
import FrequencySelect from "../../../Base/FrequencyComponent/FrequencySelect";
import UserPaysTypeSelect from "./UserPaysTypeSelect";

const recurringTypes: EUserPayType[] = [
    EUserPayType.RECURRING_MINUS,
    EUserPayType.RECURRING_PLUS
];

const normaliseRequest = (data: IUserPayRequestDto): IUserPayRequestDto => {
    if (data.frequency === EFrequency.NONE) {
        data.frequency = undefined;
    }
    return data;
};

interface IProps {
    userId: string;
    pay?: IUserPayResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const UserPaysDialog = (props: IProps) => {
    const { open, userId, pay, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'user-pays-form';
    const isEdit = useRef<boolean>(pay !== undefined);
    const { dateFormat } = useSelector((state: RootState) => state.preferenceSlice.user);

    const [maxDate, setMaxDate] = useState<number | undefined>(pay?.startDate ? pay.startDate : undefined);
    const [minDate, setMinDate] = useState<number | undefined>(pay?.endDate ? pay.endDate : undefined);

    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, setError, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IUserPayRequestDto>({
        defaultValues: {
            type: isEdit.current ? pay?.type : EUserPayType.NONE,
            frequency: isEdit.current ? pay?.frequency : EFrequency.NONE,
            note: isEdit.current ? pay?.note : '',
            startDate: isEdit.current ? pay?.startDate : NaN,
            endDate: isEdit.current ? pay?.endDate : NaN,
            amount: isEdit.current ? pay?.amount : NaN
        }
    });

    const updateData = useCallback((uuid: string, data: IUserPayRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.updatePay(userId, uuid, data);
            if (response) {
                displayNotification({ message: t('Item was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, t]);

    const createData = useCallback((data: IUserPayRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.createPay(userId, data);
            if (response) {
                displayNotification({ message: t('Item was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, t]);

    const onSubmit = useCallback((data: IUserPayRequestDto) => {
        data = normaliseRequest(data);
        if (pay) {
            updateData(pay.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, pay, updateData]);

    const validateTypeField = useCallback((value: EUserPayType) => {
        return EUserPayType[value] !== undefined && value !== EUserPayType.NONE;
    }, []);

    register('type', { validate: validateTypeField });
    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('type', event.target.value as EUserPayType, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateFrequencyField = useCallback((value?: EFrequency) => {
        if (recurringTypes.includes(getValues('type'))) {
            return value !== undefined && EFrequency[value] !== undefined && value !== EFrequency.NONE;
        }
        return true;
    }, [getValues]);

    register('frequency', { validate: validateFrequencyField });
    const onFrequencyChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('frequency', event.target.value as EFrequency, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateDate = useCallback(() => {
        return getValues('startDate') !== undefined || !isNaN(getValues('startDate'));
    }, [getValues]);

    register('startDate', { validate: validateDate });
    const onChangeStartDateHandler = useCallback((timestamp?: number) => {
        setValue('startDate', timestamp || NaN, {
            shouldValidate: true,
            shouldDirty: true
        });
        setMinDate(timestamp);
    }, [setValue]);

    register('endDate', { validate: validateDate });
    const onChangeEndDateHandler = useCallback((timestamp?: number) => {
        setValue('endDate', timestamp, {
            shouldValidate: true,
            shouldDirty: true
        });
        setMaxDate(timestamp);
    }, [setValue]);

    const validateNotes = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('note', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const validateAmountField = useCallback((value: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('amount', { validate: validateAmountField });
    const onAmountChangeHandler = useCallback((value: number) => {
        setValue('amount', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <UserPaysTypeSelect
                        required
                        label={t('TYPE')}
                        value={getValues('type')}
                        onChange={onTypeChangeHandler}
                    />

                    {recurringTypes.includes(getValues('type')) &&
                        <FrequencySelect
                            required
                            label={t('FREQUENCY')}
                            value={getValues('frequency')}
                            onChange={onFrequencyChangeHandler}
                        />
                    }

                    <TextField
                        {...register('note', {
                            required: true,
                            validate: validateNotes
                        })}
                        required
                        autoComplete='off'
                        label={t('NOTES')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.note}
                        helperText={errors.note?.message}
                    />

                    {getValues('type') !== EUserPayType.NONE &&
                        <DateField
                            required
                            label={recurringTypes.includes(getValues('type')) ? t('START DATE') : t('DATE')}
                            size='medium'
                            max={recurringTypes.includes(getValues('type')) ? maxDate : undefined}
                            value={getValues('startDate')}
                            format={dateFormat}
                            onChange={onChangeStartDateHandler}
                        />
                    }

                    {recurringTypes.includes(getValues('type')) &&
                        <DateField
                            label={t('END DATE')}
                            size='medium'
                            min={minDate}
                            value={getValues('endDate')}
                            format={dateFormat}
                            onChange={onChangeEndDateHandler}
                        />
                    }

                    <Stack direction="row" spacing={2}>
                        <CurrencyField
                            required
                            label={t("AMOUNT")}
                            allowNegative={false}
                            value={getValues('amount')}
                            onChange={onAmountChangeHandler}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [
        errors.note, getValues, handleSubmit, maxDate, minDate,
        onAmountChangeHandler, onChangeEndDateHandler, onChangeStartDateHandler,
        onFrequencyChangeHandler, onSubmit, onTypeChangeHandler, register, t,
        validateNotes, dateFormat
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} ITEM`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default UserPaysDialog;