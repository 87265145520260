import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { removeEmptyFields } from "../../../../helpers/generalHelper";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { IDocument } from "../../../../models/DocumentModels";
import { EIssueDocumentType, IIssueDocumentRequestDto } from "../../../../models/IssueModels";
import IssueService from "../../../../services/IssueService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import DocumentTypeSelect from "../../../Base/DocumentTypeSelectComponent/DocumentTypeSelect";

const documentTypeData: string[] = Object.keys(EIssueDocumentType)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== EIssueDocumentType[EIssueDocumentType.NONE])
    .map(key => key.toString())

interface IProps {
    issueId: string;
    document?: IDocument;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const IssueDocumentDialog = (props: IProps) => {
    const { open, issueId, document, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'issue-document-form';
    const isEdit = useRef<boolean>(document !== undefined);

    const [loading, setLoading] = useState(false);

    const { register, setError, clearErrors, setValue, getValues, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IIssueDocumentRequestDto>({
        defaultValues: {
            type: isEdit.current ? document?.type as EIssueDocumentType : undefined,
            caption: isEdit.current ? document?.caption : undefined
        }
    });

    const updateData = useCallback((uuid: string, data: IIssueDocumentRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await IssueService.updateDocument(issueId, uuid, data);
            if (response) {
                displayNotification({ message: t('Document was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueId]);

    const createData = useCallback((data: IIssueDocumentRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await IssueService.createDocument(issueId, data);
            if (response) {
                displayNotification({ message: t('Document was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueId]);

    const onSubmit = useCallback((data: IIssueDocumentRequestDto) => {
        const normalisedData: IIssueDocumentRequestDto = removeEmptyFields(data) as unknown as IIssueDocumentRequestDto;
        if (document) {
            updateData(document.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [createData, document, updateData]);

    const validateType = useCallback((value: EIssueDocumentType) => {
        return EIssueDocumentType[value] !== undefined && value !== EIssueDocumentType.NONE;
    }, []);

    register('type', { validate: validateType });
    const onDocumentTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('type', event.target.value as EIssueDocumentType, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateCaption = useCallback((value?: string) => {
        if (value && value.length > 0 && isBlank(value)) {
            const message: string = t('Only blank spaces are not allowed.');
            setError('caption', { message: message });
            return false;
        }
        clearErrors('caption');
        return true;
    }, [clearErrors, setError, t]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <DocumentTypeSelect
                        label={t('TYPE')}
                        size='medium'
                        data={documentTypeData}
                        required
                        value={getValues('type')}
                        onChange={onDocumentTypeChangeHandler}
                    />

                    <TextField
                        {...register('caption', { validate: validateCaption })}
                        autoComplete={'off'}
                        label={t('NOTES')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.caption}
                        helperText={errors.caption?.message}
                    />
                </Stack>
            </form>
        );
    }, [
        errors.caption, getValues, handleSubmit, onDocumentTypeChangeHandler,
        onSubmit, register, t, validateCaption
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} DOCUMENT`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    )
}
export default IssueDocumentDialog;