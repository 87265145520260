import { Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { CSSProperties, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDateAndTimeFormat } from "../../../helpers/dateHelper";
import { normalizeNavigationUrl } from "../../../helpers/generalHelper";
import { textColorGreen, textColorOrange, textColorRed } from '../../../helpers/gridHelper';
import { EPriority, IFilter, IIdValue } from '../../../models/CommonModels';
import IssueService from '../../../services/IssueService';
import { RootState } from "../../../store/store";
import BaseCrudGrid from "../../Base/BaseCrudGridComponent/BaseCrudGrid";
import DetailsBtn from "../../Base/DetailsBtnComponent/DetailsBtn";
import IssueDialog from "./IssueDialog";
import IssueFilter from './IssueFilter';

const IssueGrid = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.issueSlice.grid);

    const [createDialogToggle, setCreateDialogToggle] = useState<boolean>(false);
    const [filterToggle, setFilterToggle] = useState<boolean>(false);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return IssueService.fetchAllByFilter(filter);
    }, []);

    const onDetailsActionBtnClick = useCallback((uuid: string) => {
        const newUrl = normalizeNavigationUrl(uuid);
        navigate(`/${newUrl}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDownloadActionBtnApiHandler = useCallback((filter: IFilter) => {
        return IssueService.downloadReport(filter);
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onTypeColumnRender = useCallback((value: string) => {
        return value.toString().replaceAll('_', ' ');
    }, []);

    const onStatusColumnRender = useCallback((value: string) => {
        return value.toString().replaceAll('_', ' ');
    }, []);

    const onActionsColumnRender = useCallback((record: any) => {
        const row = record.row;
        return [
            <DetailsBtn onClick={() => onDetailsActionBtnClick(row.uuid)} />
        ];
    }, [onDetailsActionBtnClick]);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onExecutedColumnRender = useCallback((params: GridRenderCellParams) => {
        const executedBy: string = params.row.executedBy;
        const executedDate: number = params.row.executedDate;
        if (!executedBy) {
            return '';
        }

        const dateTime: string = getDateAndTimeFormat(executedDate, dateFormat, timeFormat);
        const tooltip: string = `${executedBy} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {executedBy} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onAssignedColumnRender = useCallback((params: GridRenderCellParams) => {
        const assignedOn = params.row.assignedOn as IIdValue;
        const assignedDate: number = params.row.assignedDate;

        if (!assignedOn) {
            return '';
        }

        const dateTime: string = assignedDate ? getDateAndTimeFormat(assignedDate, dateFormat, timeFormat) : '';
        const tooltip: string = `${assignedOn.name} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {assignedOn.name} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onPriorityColumnRender = useCallback((params: GridRenderCellParams) => {
        const priority: string = EPriority[params.value];

        let style: CSSProperties = {};
        switch (priority) {
            case EPriority[EPriority.LOW]: {
                style = textColorGreen;
                break;
            }
            case EPriority[EPriority.MEDIUM]: {
                style = textColorOrange;
                break;
            }
            case EPriority[EPriority.HIGH]: {
                style = textColorRed;
                break;
            }
        }

        return <span style={style}>{priority}</span>;
    }, []);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 50,
            align: 'center',
            resizable: false,
            disableColumnMenu: true,
            hideable: false,
            getActions: onActionsColumnRender
        }, {
            field: 'idno',
            headerName: t('ID#'),
            width: 100,
            type: 'number',
            align: 'left',
            headerAlign: 'center',
            hideable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'title',
            headerName: t('TITLE'),
            flex: 1,
            minWidth: 150,
            headerAlign: 'center',
            hideable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'type',
            headerName: t('TYPE'),
            width: 170,
            headerAlign: 'center',
            valueFormatter: onTypeColumnRender
        }, {
            field: 'cause',
            headerName: t('CAUSE'),
            flex: 1,
            minWidth: 200,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'priority',
            headerName: t('PRIORITY'),
            width: 100,
            headerAlign: 'center',
            renderCell: onPriorityColumnRender
        }, {
            field: 'assignedOn',
            headerName: t('ASSIGNED'),
            width: 180,
            headerAlign: 'center',
            sortable: false,
            renderCell: onAssignedColumnRender
        }, {
            field: 'executedBy',
            headerName: t('EXECUTED'),
            width: 180,
            headerAlign: 'center',
            renderCell: onExecutedColumnRender
        }, {
            field: 'conclusion',
            headerName: t('CONCLUSION'),
            flex: 1,
            minWidth: 200,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'status',
            headerName: t('STATUS'),
            width: 150,
            headerAlign: 'center',
            valueFormatter: onStatusColumnRender
        }, {
            field: 'created',
            headerName: t('CREATED'),
            width: 180,
            headerAlign: 'center',
            renderCell: onCreatedColumnRender
        }];
    }, [
        onActionsColumnRender, onAssignedColumnRender, onCreatedColumnRender,
        onExecutedColumnRender, onPriorityColumnRender, onStatusColumnRender,
        onTooltipColumnRender, onTypeColumnRender, t
    ]);

    const createDialogToggleHandler = useCallback(() => {
        setCreateDialogToggle(prevState => !prevState);
    }, []);

    const onFilterToggleHandler = useCallback(() => {
        setFilterToggle(prevState => !prevState);
    }, []);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams) => {
        onDetailsActionBtnClick(params.id.toString());
    }, [onDetailsActionBtnClick]);

    return (
        <>
            <BaseCrudGrid
                gridId='issue'
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                refreshActionBtnTooltip={t('Refresh data')}
                addActionBtnTooltip={t('Create task')}
                onAddActionBtnClick={createDialogToggleHandler}
                editActionBtnHide={true}
                deleteActionBtnHide={true}
                filterActionBtnShow
                filterActionBtnTooltip={t('Search task(s)')}
                onFilterActionBtnClick={onFilterToggleHandler}
                downloadActionBtnShow
                downloadActionBtnTooltip={t('Download task(s)')}
                downloadActionBtnApi={onDownloadActionBtnApiHandler}
                onRowDoubleClick={onRowDoubleClickHandler}
            />

            {filterToggle && (
                <IssueFilter
                    open={filterToggle}
                    onClose={onFilterToggleHandler}
                />
            )}

            {createDialogToggle && (
                <IssueDialog
                    open={createDialogToggle}
                    onCloseBtnClick={createDialogToggleHandler}
                />
            )}
        </>
    );
}
export default IssueGrid;