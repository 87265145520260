import BasePage from '../components/Base/BasePageComponent/BasePage';
import InvoiceGrid from '../components/InvoiceModule/Grid/InvoiceGrid';
import { useInvoice } from '../hooks/useInvoice';

const InvoicePage = () => {
    const { resetState, applyFilter } = useInvoice();

    return (
        <BasePage
            onApplyFilter={applyFilter}
            onResetState={resetState}
            mainSection={<InvoiceGrid />}
            stepSection={<></>}
        />
    );
}
export default InvoicePage;