import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICriteria } from "../../models/CommonModels";
import { IReduxGrid, IReduxStep } from "../../models/ReduxModels";

interface LoadState {
    grid: IReduxGrid;
    step: IReduxStep;
    palletTypeGrid: IReduxGrid;
    itemsGrid: IReduxGrid;
    paysGrid: IReduxGrid;
    usersGrid: IReduxGrid;
    transportsGrid: IReduxGrid;
    chargeTypesGrid: IReduxGrid;
    chargesGrid: IReduxGrid;
    shipperConsigneeGrid: IReduxGrid;
};

const initialState: LoadState = {
    grid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    step: {
        refresh: false
    },
    palletTypeGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    itemsGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    paysGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    usersGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    transportsGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    chargeTypesGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    chargesGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    shipperConsigneeGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    }
};

export const LoadSlice = createSlice({
    name: 'loadSlice',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
        gridRefresh(state: LoadState) {
            state.grid.refresh = !state.grid.refresh;
        },
        applyFilter(state: LoadState, action: PayloadAction<ICriteria[]>) {
            state.grid.criterias = action.payload;
        },
        stepRefresh(state: LoadState) {
            state.step.refresh = !state.step.refresh;
        },
        gridUnselectRows(state: LoadState) {
            state.grid.unselectRows = !state.grid.unselectRows;
        },
        palletTypeGridRefresh(state: LoadState) {
            state.palletTypeGrid.refresh = !state.palletTypeGrid.refresh;
        },
        itemsGridRefresh(state: LoadState) {
            state.itemsGrid.refresh = !state.itemsGrid.refresh;
        },
        paysGridRefresh(state: LoadState) {
            state.paysGrid.refresh = !state.paysGrid.refresh;
        },
        usersGridRefresh(state: LoadState) {
            state.usersGrid.refresh = !state.usersGrid.refresh;
        },
        transportsGridRefresh(state: LoadState) {
            state.transportsGrid.refresh = !state.transportsGrid.refresh;
        },
        chargeTypesGridRefresh(state: LoadState) {
            state.chargeTypesGrid.refresh = !state.chargeTypesGrid.refresh;
        },
        chargesGridRefresh(state: LoadState) {
            state.chargesGrid.refresh = !state.chargesGrid.refresh;
        },
        shipperConsigneeGridRefresh(state: LoadState) {
            state.shipperConsigneeGrid.refresh = !state.shipperConsigneeGrid.refresh;
        },
        shipperConsigneeGridApplyFilter(state: LoadState, action: PayloadAction<ICriteria[]>) {
            state.shipperConsigneeGrid.criterias = action.payload;
        }
    }
});

export const LoadActions = LoadSlice.actions;
export const LoadReducer = LoadSlice.reducer;