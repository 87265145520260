import AddIcon from '@mui/icons-material/Add';
import { Box, Button, ImageList, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../../../helpers/generalHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { EFileType } from '../../../../models/FileModel';
import { ITransportImageResponseDto } from "../../../../models/TransportModels";
import TransportService from "../../../../services/TransportService";
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";
import FileViewDialog from '../../../Base/FileComponent/FileViewDialog';
import ImageItem from '../../../Base/FileComponent/Image/ImageItem';
import NoData from '../../../Base/NoDataComponent/NoData';
import TransportImagesDialog from './TransportImagesDialog';

interface IProps {
    transportId: string;
    readonly: boolean;
}
const TransportImagesAdditional = (props: IProps) => {
    const { transportId } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [images, setImages] = useState<ITransportImageResponseDto[]>([]);
    const [image, setImage] = useState<ITransportImageResponseDto>();
    const [prevFileViewBtnDisabled, setPrevFileViewBtnDisabled] = useState<boolean>(true);
    const [nextFileViewBtnDisabled, setNextFileViewBtnDisabled] = useState<boolean>(true);

    const [createDialog, setCreateDialog] = useState<boolean>(false);
    const [viewDialog, setViewDialog] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    const { t } = useTranslation();
    const { displayNotification } = useNotification();

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await TransportService.fetchAdditionalImages(transportId);
            if (response) {
                setImages(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transportId]);

    useEffect(() => {
        retrieveData();
    }, [retrieveData, refresh]);

    const refreshHandler = useCallback(() => {
        setRefresh(refresh => !refresh);
    }, []);

    const createDialogToggleHandler = useCallback(() => {
        setCreateDialog(dialog => !dialog);
    }, []);

    const onRemoveHandler = useCallback((image: ITransportImageResponseDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await TransportService.removeImage(transportId, image.uuid);
            if (response) {
                displayNotification({ message: t('Image was successfully deleted.') });
                setLoading(false);
                refreshHandler();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshHandler, t, transportId]);

    const viewDialogToggleHandler = useCallback(() => {
        setViewDialog(dialog => !dialog);
    }, []);

    const findImageIndex = useCallback((imageId: string) => {
        return images.findIndex(item => item.uuid === imageId);
    }, [images]);

    const setPrevNextFileViewBtnDisabled = useCallback((image: ITransportImageResponseDto) => {
        const currentIndex: number = findImageIndex(image.uuid);
        setPrevFileViewBtnDisabled(currentIndex === 0);
        setNextFileViewBtnDisabled(currentIndex === images.length - 1);
    }, [images.length, findImageIndex]);

    const onViewHandler = useCallback((image: ITransportImageResponseDto) => {
        setImage(image);
        setPrevNextFileViewBtnDisabled(image);
        viewDialogToggleHandler();
    }, [setPrevNextFileViewBtnDisabled, viewDialogToggleHandler]);

    const onNextFileViewBtnClickHandler = useCallback((fileId: string) => {
        const currentIndex: number = findImageIndex(fileId);
        if (currentIndex < images.length - 1) {
            const nextIndex = currentIndex + 1;
            const image: ITransportImageResponseDto = images[nextIndex];
            setImage(image);
            setPrevNextFileViewBtnDisabled(image);
        }
    }, [findImageIndex, images, setPrevNextFileViewBtnDisabled]);

    const onPrevFileViewBtnClickHandler = useCallback((fileId: string) => {
        const currentIndex: number = findImageIndex(fileId);
        if (currentIndex > 0) {
            const prevIndex = currentIndex - 1;
            const image: ITransportImageResponseDto = images[prevIndex];
            setImage(image);
            setPrevNextFileViewBtnDisabled(image);
        }
    }, [findImageIndex, images, setPrevNextFileViewBtnDisabled]);

    return (
        <Box>
            <Tooltip title={t('Add additional image')} placement='top'>
                <Button
                    size='small'
                    variant='contained'
                    onClick={createDialogToggleHandler}
                >
                    <AddIcon fontSize='small' />&nbsp;{t('IMAGE')}
                </Button>
            </Tooltip>

            <BackdropProgress open={loading || false}>
                <Box sx={{ marginTop: '10px', overflowY: 'scroll', height: 305, width: '100%' }}>
                    {
                        isEmpty(images)
                            ?
                            <NoData label={t('There are no images')} height={230} />
                            :
                            <ImageList variant="masonry" cols={3} gap={8}>
                                {images.map((item) => (
                                    <ImageItem
                                        key={item.uuid}
                                        imageId={item.uuid}
                                        image={item?.thumbnail}
                                        original={item.originalFile}
                                        deleteBtnShow
                                        deleteBtnTooltip={t('Delete image')}
                                        deleteBtnClick={() => onRemoveHandler(item)}
                                        viewBtnShow
                                        viewBtnTooltip={t('View image')}
                                        viewBtnClick={() => onViewHandler(item)}
                                        downloadBtnShow
                                        downloadBtnTooltip={t('Download image')}
                                    />
                                ))}
                            </ImageList>
                    }
                </Box>
            </BackdropProgress>

            {createDialog &&
                <TransportImagesDialog
                    open={createDialog}
                    onCloseBtnClick={createDialogToggleHandler}
                    onSubmitBtnClick={refreshHandler}
                    type={EFileType.ADDITIONAL}
                    transportId={transportId}
                />
            }

            {viewDialog && image &&
                <FileViewDialog
                    fileId={image.uuid}
                    file={image.originalFile}
                    open={viewDialog}
                    nextFileViewBtnDisabled={nextFileViewBtnDisabled}
                    onNextFileViewBtnClick={onNextFileViewBtnClickHandler}
                    prevFileViewBtnDisabled={prevFileViewBtnDisabled}
                    onPrevFileViewBtnClick={onPrevFileViewBtnClickHandler}
                    onCloseBtnClick={viewDialogToggleHandler}
                />
            }
        </Box>
    );
}
export default TransportImagesAdditional;