import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../../hooks/useNotification';
import UserService from "../../../../services/UserService";
import BaseDescription from '../../../Base/BaseDescriptionComponent/BaseDescription';
import UserDescriptionDialog from "./UserDescriptionDialog";

interface IProps {
    userId: string;
}
const UserDescription = (props: IProps) => {
    const { userId } = props;

    const maxHeight = useRef<string | number>('300px');

    const [refresh, setRefresh] = useState<boolean>(false);
    const [dialog, setDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<string>();

    const { displayNotification } = useNotification();
    const { t } = useTranslation();

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.fetchDescription(userId);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    useEffect(() => {
        retrieveData();
    }, [retrieveData, refresh]);

    const dialogToggleHandler = useCallback(() => {
        setDialog(dialog => !dialog);
    }, []);

    const refreshHandler = useCallback(() => {
        setRefresh(refresh => !refresh);
    }, []);

    return (
        <>
            <BaseDescription
                data={data}
                loading={loading}
                maxHeight={maxHeight.current}
                editBtnLabel={t('EDIT')}
                editBtnTooltip={t('EDIT')}
                onEditBtnClick={dialogToggleHandler}
            />

            {dialog &&
                <UserDescriptionDialog
                    open={dialog}
                    onCloseBtnClick={dialogToggleHandler}
                    onSubmitBtnClick={refreshHandler}
                    userId={userId}
                    value={data}
                />
            }
        </>
    );
}
export default UserDescription;