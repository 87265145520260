import { IAddressResponseDto, IAddressShortResponseDto } from "./AddressModels";
import { EReputationLevel, INameValueResponseDto } from "./CommonModels";

export enum ECustomerDocumentType {
    NONE,
    DOCUMENT = 'DOCUMENT'
}

export enum ECustomerStatus {
    NONE,
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    INCOMPLETE = 'INCOMPLETE'
}

export interface ICustomerResponseDto {
    uuid: string;
    idno?: string;
    name: string;
    email?: string;
    phone?: string;
    lastLoadDate?: number;
    status: ECustomerStatus;
    reputationLevel: EReputationLevel;
    reviewMark: number;
    totalNumberOfLoads: number;
    numberOfPayedLoads: number;
    totalAmount: number;
    payedAmount: number;
    address: IAddressResponseDto;
    favorite: boolean;
    createdBy: string;
    createdDate: number;
    updatedBy?: string;
    updatedDate?: number;
}

export interface ICustomerCoverFaceResponseDto {
    uuid: string;
    name: string;
    status: ECustomerStatus;
    reputationLevel: EReputationLevel;
    reviewMark: number;
    readonly: boolean;
    favorite: boolean;
}

export interface ICustomerAutocompleteResponseDto {
    uuid: string;
    idno?: string;
    name: string;
    status: ECustomerStatus;
    reputationLevel: EReputationLevel;
    reviewMark: number;
    address: IAddressShortResponseDto;
}

export interface ICustomerOverviewResponseDto {
    uuid: string;
    idno?: string;
    name: string;
    address: IAddressShortResponseDto;
}

export interface ICustomerRequestDto {
    idno?: string;
    name: string;
    addressId: string;
    email?: string;
    phone?: string;
    contact1?: INameValueResponseDto;
    contact2?: INameValueResponseDto;
    contact3?: INameValueResponseDto;
    statusActive?: boolean;
}

export interface ICustomerContactResponseDto {
    email?: string;
    phone?: string;
    contact1?: INameValueResponseDto;
    contact2?: INameValueResponseDto;
    contact3?: INameValueResponseDto;
}

export interface ICustomerContactRequestDto {
    email?: string;
    phone?: string;
    contact1?: INameValueResponseDto;
    contact2?: INameValueResponseDto;
    contact3?: INameValueResponseDto;
}

export interface ICustomerDocumentRequestDto {
    type: ECustomerDocumentType;
    expirationDate?: number;
    number?: string;
    caption?: string;
}

export interface ICustomerIdNameResponseDto {
    uuid: string;
    name: string;
}

export interface ICustomerIdNameAddressResponseDto {
    uuid: string;
    name: string;
    address: IAddressShortResponseDto;
}

export interface ICustomerStatusReputationRequestDto {
    status: ECustomerStatus;
    reputationLevel: EReputationLevel;
}

export interface ICustomerStatusReputationResponseDto {
    uuid: string;
    status: ECustomerStatus;
    reputationLevel: EReputationLevel;
}

export interface IFavoritesCustomer {
    uuid: string,
    idno?: string,
    name: string,
    phone: string,
    lastLoadDate: number,
    status: string,
    reputationLevel: string,
    reviewMark: number,
    email: string
}