import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useState } from "react";
import { ETemperature } from "../../../models/PreferenceModels";

interface IProps {
    label?: string;
    required?: boolean;
    value?: ETemperature;
    onChange?: (event: SelectChangeEvent) => void;
}
const MeasureTemperatureSelect = (props: IProps) => {
    const { label = 'Temperature', required = false, onChange } = props;
    const [selectedValue, setSelectedValue] = useState<string>(props.value ? props.value.toString() : '');

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormControl fullWidth required={required}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem key={ETemperature.CELSIUS} value={ETemperature.CELSIUS}>{`CELSIUS (${ETemperature.CELSIUS})`}</MenuItem>
                <MenuItem key={ETemperature.FAHRENHEIT} value={ETemperature.FAHRENHEIT}>{`FAHRENHEIT (${ETemperature.FAHRENHEIT})`}</MenuItem>
            </Select>
        </FormControl>
    );
}
export default MeasureTemperatureSelect;