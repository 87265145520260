import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../helpers/textHelper";
import { useNotification } from "../../hooks/useNotification";
import { EIssueStatus, IIssueResolveRequest } from "../../models/IssueModels";
import IssueService from "../../services/IssueService";
import BaseCrudDialog from "../Base/BaseCrudDialogComponent/BaseCrudDialog";
import StatusSelect from "../Base/StatusSelectComponent/StatusSelect";

const statusData: string[] = [EIssueStatus.UNRESOLVED.toString(), EIssueStatus.DONE.toString()];

interface IProps {
    issueId: string;
    idno: number;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const IssueResolveDialog = (props: IProps) => {
    const { issueId, idno, open, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'issue-resolve-form';

    const [loading, setLoading] = useState(false);

    const { register, setValue, setError, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IIssueResolveRequest>({
        defaultValues: {
            conclusion: '',
            status: undefined
        }
    });

    const onSubmit = useCallback((data: IIssueResolveRequest) => {
        setLoading(true);
        (async () => {
            const [error, response] = await IssueService.resolve(issueId, data)
            if (response) {
                displayNotification({ message: t('Task was successfully resolved.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueId, t]);

    const validateConclusion = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('conclusion', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const validateStatusField = useCallback((value: EIssueStatus) => {
        return EIssueStatus[value] !== undefined;
    }, []);

    register('status', { validate: validateStatusField });
    const onStatusChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('status', event.target.value as EIssueStatus, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <TextField
                        {...register('conclusion', {
                            required: true,
                            validate: validateConclusion
                        })}
                        required
                        error={!!errors.conclusion}
                        label={t('CONCLUSION')}
                        multiline
                        rows={5}
                        slotProps={{ htmlInput: { minLength: 1 } }}
                        helperText={errors.conclusion?.message}
                    />

                    <StatusSelect
                        required
                        fullWidth
                        size='medium'
                        label={t('STATUS')}
                        data={statusData}
                        onChange={onStatusChangeHandler}
                    />
                </Stack>
            </form>
        );
    }, [errors.conclusion, handleSubmit, onStatusChangeHandler, onSubmit, register, t, validateConclusion]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`RESOLVE TASK #${idno}`)}
            maxWidth={'sm'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    )
}
export default IssueResolveDialog;