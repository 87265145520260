import { IFilter } from "../models/CommonModels";
import { ELoadBulkStatus, ELoadCopyItemType, ELoadShipperConsigneeDirection, ILoadChargeTypeRequestDto, ILoadDocumentRequestDto, ILoadItemRequestDto, ILoadPayRequestDto, ILoadRequestDto, ILoadShipperConsigneeRequestDto, ILoadStatusRequestDto, IPalletTypeRequestDto } from "../models/LoadModels";
import { API } from "./APIService";

class LoadService {

    public static fetchAllByFilter = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'load/all', JSON.stringify(filter));
        return [error, response];
    };

    public static downloadReport = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'load/report', filter, undefined, undefined, 'blob');
        return [error, response];
    };

    public static fetchCoverFaceData = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `load/${uuid}/coverFace`);
        return [error, response];
    };

    public static create = async (data: ILoadRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'load', JSON.stringify(data));
        return [error, response];
    };

    public static remove = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${uuid}`)
        return [error, response];
    };

    public static fetchOverview = async (loadId: string): Promise<any> => {
        const [error, response] = await API('GET', `load/${loadId}/overview`);
        return [error, response];
    };

    public static updateOverview = async (loadId: string, data: ILoadRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/overview`, data);
        return [error, response];
    };

    public static fetchAutocomplete = async (): Promise<any> => {
        const [error, response] = await API('GET', 'load/autocomplete');
        return [error, response];
    };

    public static isIdnoAvailable = async (idno: string): Promise<any> => {
        const [error, response] = await API('GET', `load/available/idno/${idno}`);
        return [error, response];
    };

    public static createFavorites = async (ids: string[]): Promise<any> => {
        const [error, response] = await API('POST', 'load/favorite', ids);
        return [error, response];
    };

    public static removeFavorites = async (ids: string[]): Promise<any> => {
        const [error, response] = await API('DELETE', 'load/favorite', ids);
        return [error, response];
    };

    public static fetchDocuments = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `load/${uuid}/documents`);
        return [error, response];
    };

    public static createDocument = async (loadId: string, data: ILoadDocumentRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/documents`, data);
        return [error, response];
    };

    public static updateDocument = async (loadId: string, documentId: string, data: ILoadDocumentRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/documents/${documentId}`, data);
        return [error, response];
    };

    public static removeDocument = async (loadId: string, documentId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${loadId}/documents/${documentId}`);
        return [error, response];
    };

    public static createDocumentAttachment = async (loadId: string, documentId: string, data: FormData): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/documents/${documentId}/attachments`, data, undefined, 'multipart/form-data');
        return [error, response];
    };

    public static removeDocumentAttachment = async (loadId: string, documentId: string, attachmentId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${loadId}/documents/${documentId}/attachments/${attachmentId}`);
        return [error, response];
    };

    public static fetchPalletTypes = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/palletTypes/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static createPalletType = async (data: IPalletTypeRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'load/palletTypes', data);
        return [error, response];
    };

    public static updatePalletType = async (palletTypeId: string, data: IPalletTypeRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/palletTypes/${palletTypeId}`, data);
        return [error, response];
    };

    public static fetchPalletTypesAutocomplete = async (): Promise<any> => {
        const [error, response] = await API('GET', 'load/palletTypes/autocomplete');
        return [error, response];
    };

    public static removePalletType = async (palletTypeId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/palletTypes/${palletTypeId}`);
        return [error, response];
    };

    public static fetchDescription = async (loadId: string): Promise<any> => {
        const [error, response] = await API('GET', `load/${loadId}/description`);
        return [error, response];
    };

    public static updateDescription = async (loadId: string, description?: string): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/description`, description);
        return [error, response];
    };

    public static fetchItems = async (loadId: string, filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/items/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static fetchItem = async (loadId: string, loadItemId: string): Promise<any> => {
        const [error, response] = await API('GET', `load/${loadId}/items/${loadItemId}`);
        return [error, response];
    };

    public static fetchCopyItemsAutocomplete = async (loadId: string, type: ELoadCopyItemType): Promise<any> => {
        const [error, response] = await API('GET', `load/${loadId}/items/autocomplete/${type.toString()}`);
        return [error, response];
    };

    public static createItem = async (loadId: string, data: ILoadItemRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/items`, data);
        return [error, response];
    };

    public static updateItem = async (loadId: string, loadItemId: string, data: ILoadItemRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/items/${loadItemId}`, data);
        return [error, response];
    };

    public static removeItem = async (loadId: string, loadItemId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${loadId}/items/${loadItemId}`);
        return [error, response];
    };

    public static fetchPays = async (loadId: string, filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/pays/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static createPay = async (loadId: string, data: ILoadPayRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/pays`, data);
        return [error, response];
    };

    public static updatePay = async (loadId: string, loadPayId: string, data: ILoadPayRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/pays/${loadPayId}`, data);
        return [error, response];
    };

    public static removePay = async (loadId: string, loadPayId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${loadId}/pays/${loadPayId}`);
        return [error, response];
    };

    public static fetchUsers = async (loadId: string, filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/users/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static createUser = async (loadId: string, userId: string): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/users`, userId);
        return [error, response];
    };

    public static updateUser = async (loadId: string, loadUserId: string, userId: string): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/users/${loadUserId}`, userId);
        return [error, response];
    };

    public static removeUser = async (loadId: string, loadUserId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${loadId}/users/${loadUserId}`);
        return [error, response];
    };

    public static fetchTransports = async (loadId: string, filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/transports/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static createTransport = async (loadId: string, transportId: string): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/transports`, transportId);
        return [error, response];
    };

    public static updateTransport = async (loadId: string, loadTransportId: string, transportId: string): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/transports/${loadTransportId}`, transportId);
        return [error, response];
    };

    public static removeTransport = async (loadId: string, loadTransportId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${loadId}/transports/${loadTransportId}`);
        return [error, response];
    };

    public static fetchStatus = async (loadId: string): Promise<any> => {
        const [error, response] = await API('GET', `load/status/${loadId}`);
        return [error, response];
    };

    public static updateStatus = async (loadId: string, data: ILoadStatusRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/status/${loadId}`, data);
        return [error, response];
    };

    public static fetchItemsStatuses = async (loadId: string): Promise<any> => {
        const [error, response] = await API('GET', `load/${loadId}/items/status`);
        return [error, response];
    };

    public static fetchChargeTypes = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/chargeTypes/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static removeChargeType = async (chargeTypeId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/chargeTypes/${chargeTypeId}`);
        return [error, response];
    };

    public static createChargeType = async (data: ILoadChargeTypeRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'load/chargeTypes', data);
        return [error, response];
    };

    public static updateChargeType = async (chargeTypeId: string, data: ILoadChargeTypeRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/chargeTypes/${chargeTypeId}`, data);
        return [error, response];
    };

    public static fetchChargeTypesAutocomplete = async (): Promise<any> => {
        const [error, response] = await API('GET', 'load/chargeTypes/autocomplete');
        return [error, response];
    };

    public static fetchCharges = async (loadId: string, filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/charges/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static createCharge = async (loadId: string, data: FormData): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/charges`, data, null, 'multipart/form-data');
        return [error, response];
    };

    public static updateCharge = async (loadId: string, loadChargeId: string, data: FormData): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/charges/${loadChargeId}`, data, null, 'multipart/form-data');
        return [error, response];
    };

    public static removeCharge = async (loadId: string, loadChargeId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${loadId}/charges/${loadChargeId}`);
        return [error, response];
    };

    public static fetchShipperConsignee = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/shippersConsignees/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static removeShipperConsignee = async (shipperConsigneeId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/shippersConsignees/${shipperConsigneeId}`);
        return [error, response];
    };

    public static createShipperConsignee = async (data: ILoadShipperConsigneeRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'load/shippersConsignees', data);
        return [error, response];
    };

    public static updateShipperConsignee = async (shipperConsigneeId: string, data: ILoadShipperConsigneeRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/shippersConsignees/${shipperConsigneeId}`, data);
        return [error, response];
    };

    public static fetchShipperConsigneeAutocomplete = async (direction: ELoadShipperConsigneeDirection): Promise<any> => {
        const [error, response] = await API('GET', `load/shippersConsignees/autocomplete/${direction}`);
        return [error, response];
    };

    public static fetchStatusBulkPreviewByFilter = async (filter: IFilter, fromStatus: ELoadBulkStatus, toStatus: ELoadBulkStatus): Promise<any> => {
        const [error, response] = await API('POST', `load/status/bulk/${fromStatus}/${toStatus}/preview`, JSON.stringify(filter));
        return [error, response];
    };

    public static updateStatusBulkByFilter = async (filter: IFilter, fromStatus: ELoadBulkStatus, toStatus: ELoadBulkStatus): Promise<any> => {
        const [error, response] = await API('PUT', `load/status/bulk/${fromStatus}/${toStatus}`, JSON.stringify(filter));
        return [error, response];
    };
}
export default LoadService;