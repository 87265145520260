import { Box, Paper } from "@mui/material";
import LoadStatus from "./LoadStatus";

interface IProps {
    loadId: string;
}
const LoadStatusStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <LoadStatus entityId={props.loadId} />
            </Box>
        </Paper>
    );
}
export default LoadStatusStep;