import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const base64Prefix: string = 'data:application/pdf;base64,';
const style: {} = {
    marginBottom: '20px',
    border: '2px solid #ccc',
    backgroundColor: '#FFFFFF'
};
const pageZoom: number = 800;

interface IProps {
    value: string;
    width: number;
    heigth: number;
}
const FilePdfView = (props: IProps) => {
    const { value, width, heigth } = props;
    const data: string = `${base64Prefix}${value}`;
    const [numPages, setNumPages] = useState<number>();

    const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }): void => {
        setNumPages(numPages);
    }, []);

    return (
        <Box
            width={`${width * 0.7}px`}
            height={`${heigth * 0.7}px`}
            sx={{ display: 'flex', justifyContent: 'center' }}
        >
            <Document file={data} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (_, index) => (
                    <div key={`page_${index}`} style={style}>
                        <Page
                            width={pageZoom}
                            pageNumber={index + 1}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                        />
                    </div>
                ))}
            </Document>
        </Box>
    );
}
export default FilePdfView;