import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationUrl, removeEmptyFields } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { emailRegex } from "../../../helpers/ValidatorHelper";
import { useNotification } from "../../../hooks/useNotification";
import { useUser } from "../../../hooks/useUser";
import { IAddressShortResponseDto } from "../../../models/AddressModels";
import { IRoleIdNameResponseDto } from "../../../models/RoleModules";
import { IUserOverviewResponseDto, IUserRequestDto } from "../../../models/UserModels";
import UserService from "../../../services/UserService";
import { RootState } from "../../../store/store";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import ColorPickerField from "../../Base/ColorComponent/ColorPickerField";
import DateField from "../../Base/DateComponent/DateField";
import Address from "../../Base/GeolocationComponent/Address";
import RoleAutocomplete from "../../RoleModule/RoleAutocomplete";

interface IProps {
    open: boolean;
    entity?: IUserOverviewResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const UserDialog = (props: IProps) => {
    const { open, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { corporateEmailHostDomain } = useSelector((state: RootState) => state.preferenceSlice.system);
    const { dateFormat } = useSelector((state: RootState) => state.preferenceSlice.user);

    const [corporateEmailType, setCorporateEmailType] = useState<boolean>(
        entity ? entity.email.includes(corporateEmailHostDomain) : true
    );

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useUser();
    const formId: string = 'user-form';

    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, setError, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<IUserRequestDto>({
        defaultValues: {
            firstName: entity ? entity.firstName : '',
            lastName: entity ? entity.lastName : '',
            email: entity ? entity?.email : '',
            roleId: entity ? entity?.role.uuid : '',
            color: entity ? entity?.color : '',
            addressId: entity ? entity?.address ? entity.address.uuid : undefined : undefined,
            birthday: entity ? entity?.birthday : NaN
        }
    });

    const updateData = useCallback((uuid: string, data: IUserRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.updateOverview(uuid, data);
            if (response) {
                displayNotification({ message: t('User was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: IUserRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.create(data);
            if (response) {
                displayNotification({ message: t('User was successfully created.') });

                const uuid = response.data.response.entityId;
                const newUrl: string = normalizeNavigationUrl(uuid);
                navigate(`/${newUrl}`);

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: IUserRequestDto) => {
        const normalisedData: IUserRequestDto = removeEmptyFields(data) as unknown as IUserRequestDto;
        if (entity) {
            updateData(entity.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [createData, entity, updateData]);

    const validateIsNotBlank = useCallback((value: string) => {
        return !isBlank(value);
    }, []);

    const validateEmailField = useCallback((value: string, corporateEmail?: boolean) => {
        if (isBlank(value)) {
            return false;
        }

        if (!emailRegex.test(value)) {
            const message: string = t('Invalid email address.');
            setError('email', { message: message });
            return false;
        }

        if ((corporateEmailType || corporateEmail) && !value.includes(`@${corporateEmailHostDomain}`)) {
            const message: string = t(`The email address is not part of the ${corporateEmailHostDomain} space.`);
            setError('email', { message: message });
            return false;
        }

        if (!entity || (entity?.email !== value)) {
            (async () => {
                const [, response] = await UserService.isEmailAvailable(value);
                if (response) {
                    const available: boolean = response.data.body;
                    if (!available) {
                        const message: string = t('The email address is in use.');
                        setError('email', { message: message });
                        return false;
                    }
                }
            })();
        }

        return true;
    }, [corporateEmailHostDomain, corporateEmailType, entity, setError, t]);

    const onEmailTypeChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = event.target.checked;
        setCorporateEmailType(value);
        validateEmailField(getValues('email'), value);
    }, [getValues, validateEmailField]);

    const buildCorporateEmail = useCallback((email: string) => {
        const lastChar: string = email[email.length - 1];
        if (lastChar === '@' && (email.split('@').length === 2)) {
            email += corporateEmailHostDomain;
        }
        return email;
    }, [corporateEmailHostDomain]);

    const onEmailChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let value: string = event.target.value.trim();

        if (corporateEmailType) {
            value = buildCorporateEmail(value);
        }

        setValue('email', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [buildCorporateEmail, corporateEmailType, setValue]);

    const validateForIsBlankField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    register('roleId', { validate: validateForIsBlankField });
    const onRoleChangeHandler = useCallback((value: IRoleIdNameResponseDto | null) => {
        setValue('roleId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('color', { validate: validateForIsBlankField });
    const onColorChangeHandler = useCallback((value: string) => {
        setValue('color', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('addressId');
    const onChangeAddressHandler = useCallback((address: IAddressShortResponseDto) => {
        setValue('addressId', address.uuid, { shouldDirty: true });
    }, [setValue]);

    register('birthday');
    const onChangeBirthdayHandler = useCallback((value?: number) => {
        setValue('birthday', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <Stack spacing={3} direction={'row'}>
                        <TextField
                            {...register('firstName', { required: true, validate: validateIsNotBlank })}
                            required
                            label={t('FIRST NAME')}
                            fullWidth
                            slotProps={{ htmlInput: { minLength: 2, maxLength: 55 } }}
                        />

                        <TextField
                            {...register('lastName', { required: true, validate: validateIsNotBlank })}
                            required
                            label={t('LAST NAME')}
                            fullWidth
                            slotProps={{ htmlInput: { minLength: 2, maxLength: 55 } }}
                        />
                    </Stack>

                    <FormControlLabel
                        control={<Checkbox
                            checked={corporateEmailType}
                            onChange={onEmailTypeChangeHandler}
                        />}
                        label={`${t('CORPORATE EMAIL')} | @${corporateEmailHostDomain.toUpperCase()}`}
                    />

                    <TextField
                        {...register('email', {
                            required: true,
                            maxLength: 58,
                            minLength: 3,
                            validate: (value) => validateEmailField(value)
                        })}
                        required
                        error={!!errors.email}
                        autoComplete='off'
                        label={t('EMAIL')}
                        fullWidth
                        slotProps={{ htmlInput: { minLength: 3, maxLength: 58 } }}
                        onChange={onEmailChangeHandler}
                        helperText={errors.email?.message}
                    />

                    <Stack spacing={3} direction={'row'}>
                        <RoleAutocomplete
                            required
                            label={t('ROLE')}
                            value={getValues('roleId')}
                            onChange={onRoleChangeHandler}
                        />

                        <ColorPickerField
                            required
                            label={t('COLOR')}
                            value={getValues('color')}
                            random
                            onChange={onColorChangeHandler}
                        />
                    </Stack>

                    <Address
                        value={entity?.address}
                        onChange={onChangeAddressHandler}
                    />

                    <DateField
                        label={t('BIRTHDAY')}
                        size='medium'
                        value={getValues('birthday')}
                        format={dateFormat}
                        onChange={onChangeBirthdayHandler}
                    />
                </Stack>
            </form>
        );
    }, [
        corporateEmailHostDomain, corporateEmailType, entity?.address, errors.email,
        getValues, handleSubmit, onChangeAddressHandler, onChangeBirthdayHandler,
        onColorChangeHandler, onEmailChangeHandler, onEmailTypeChangeHandler, validateIsNotBlank,
        onRoleChangeHandler, onSubmit, register, t, validateEmailField, dateFormat
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${entity ? 'EDIT' : 'CREATE'} USER`)}
            maxWidth={'sm'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default UserDialog;