import DoneIcon from '@mui/icons-material/Done';
import { Button, Checkbox, FormControlLabel, Stack, TextField, Tooltip } from "@mui/material";
import { isNumber } from '@mui/x-data-grid/internals';
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { isBlank } from '../../../helpers/textHelper';
import { IAddress, IAddressRequestDto, IAddressShortResponseDto } from "../../../models/AddressModels";
import AddressService from '../../../services/AddressService';
import BaseDialog from "../BaseDialogComponent/BaseDialog";
import AddressAutocomplete from "./AddressAutocomplete";
import GoogleMap from './GoogleMap';

const buildStreet = (street: string, poBox: string, apt: string, suite: string): string => {
    if (isBlank(street) && isBlank(poBox)) {
        return '';
    }

    let streetValue: string = '';

    if (!isBlank(street)) {
        streetValue = street;
    }

    if (!isBlank(streetValue) && !isBlank(poBox)) {
        streetValue += ', P.O.BOX ' + poBox;
    } else if (!isBlank(poBox)) {
        streetValue = 'P.O.BOX ' + poBox;
    }

    if (isBlank(streetValue)) {
        return '';
    }

    if (!isBlank(apt)) {
        streetValue += ', APT. ' + apt;
    }

    if (!isBlank(suite)) {
        streetValue += ', SUITE. ' + suite;
    }

    return streetValue;
};

interface IProps {
    open: boolean;
    onCloseBtnClick: () => void;
    onApplyBtnClick: (address: IAddressShortResponseDto) => void;
}
const AddressDialog = (props: IProps) => {
    const { open, onCloseBtnClick, onApplyBtnClick } = props;

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [address, setAddress] = useState<IAddress | null>();
    const [shortFormat, setShortFormat] = useState<boolean>(true);

    const [latitude, setLatitude] = useState<number>(NaN);
    const [longitude, setLongitude] = useState<number>(NaN);
    const [country, setCountry] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [county, setCounty] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [village, setVillage] = useState<string>('');
    const [street, setStreet] = useState<string>('');
    const [poBox, setPoBox] = useState<string>('');
    const [apt, setApt] = useState<string>('');
    const [suite, setSuite] = useState<string>('');
    const [zipcode, setZipcode] = useState<string>('');

    const buildAddressRequest = useCallback((): IAddressRequestDto => {
        return {
            location: { country, state, county, city, village },
            latitude, longitude, zipcode,
            street: buildStreet(street, poBox, apt, suite)
        }
    }, [apt, city, country, county, latitude, longitude, poBox, state, street, suite, village, zipcode]);

    const onApplyBtnClickHandler = useCallback(() => {
        const countryPresent: boolean = !isBlank(country);
        const coordinatesPresent: boolean = isNumber(latitude) && isNumber(longitude);
        const streetPresent: boolean = !isBlank(street) || !isBlank(poBox);

        if (countryPresent && coordinatesPresent && streetPresent) {
            setLoading(true);

            const address: IAddressRequestDto = buildAddressRequest();
            (async () => {
                const [error, response] = await AddressService.fetchOrCreate(address);
                if (response) {
                    onApplyBtnClick({ uuid: response.uuid, value: response.fullAddress });

                    setLoading(false);
                    onCloseBtnClick();
                }

                if (error) {
                    setLoading(false);
                }
            })();
        }
    }, [buildAddressRequest, country, latitude, longitude, onApplyBtnClick, onCloseBtnClick, poBox, street]);

    const setShortLongValues = useCallback((address: IAddress | null, short: boolean) => {
        setLatitude(address?.latitude || NaN);
        setLongitude(address?.longitude || NaN);
        setCountry((short ? address?.shortCountry : address?.country) || '');
        setState((short ? address?.shortState : address?.state) || '');
        setCounty((short ? address?.shortCounty : address?.county) || '');
        setCity((short ? address?.shortCity : address?.city) || '');
        setVillage((short ? address?.shortVillage : address?.village) || '');
        setStreet((short ? address?.shortStreet : address?.street) || '');
    }, []);

    const onChangeShortFormatHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = event.target.checked;

        setShortLongValues(address || null, value);

        setShortFormat(value);
    }, [address, setShortLongValues]);

    const onChangeAddressHandler = useCallback((address: IAddress | null) => {
        setShortLongValues(address, shortFormat);

        setZipcode(address?.zipcode || '');
        setPoBox('');
        setApt('');
        setSuite('');

        setAddress(address);
    }, [setShortLongValues, shortFormat]);

    const onChangeCountryHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setCountry(value);
    }, []);

    const onChangeStateHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setState(value);
    }, []);

    const onChangeCountyHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setCounty(value);
    }, []);

    const onChangeCityHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setCity(value);
    }, []);

    const onChangeVillageHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setVillage(value);
    }, []);

    const onChangeStreetHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setStreet(value);
    }, []);

    const onChangePoBoxHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setPoBox(value);
    }, []);

    const onChangeAptHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setApt(value);
    }, []);

    const onChangeSuiteHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setSuite(value);
    }, []);

    const onChangeZipcodeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setZipcode(value);
    }, []);

    const onBuildContent = useCallback(() => {
        return (
            <Stack direction='column' spacing={2}>
                <Stack direction='row' spacing={2}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={shortFormat}
                            onChange={onChangeShortFormatHandler}
                        />}
                        label={t('SHORT FORMAT')}
                    />

                    <AddressAutocomplete
                        onChange={onChangeAddressHandler}
                    />
                </Stack>

                <Stack height={latitude ? 165 : 300}>
                    <GoogleMap
                        markers={
                            (!isNaN(latitude) && !isNaN(longitude))
                                ? [{ lat: latitude, lng: longitude }]
                                : []
                        }
                    />
                </Stack>

                {address &&
                    <Stack direction='column' spacing={2}>
                        <Stack direction='row' spacing={1}>
                            <TextField
                                autoComplete='off'
                                required
                                fullWidth
                                label={t('COUNTRY')}
                                value={country}
                                error={isBlank(country)}
                                onChange={onChangeCountryHandler}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('STATE')}
                                value={state}
                                onChange={onChangeStateHandler}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('COUNTY')}
                                value={county}
                                onChange={onChangeCountyHandler}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('CITY')}
                                value={city}
                                onChange={onChangeCityHandler}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('VILLAGE')}
                                value={village}
                                onChange={onChangeVillageHandler}
                            />
                        </Stack>

                        <Stack direction='row' spacing={1}>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('STREET')}
                                value={street}
                                error={isBlank(street) && isBlank(poBox)}
                                onChange={onChangeStreetHandler}
                            />

                            <TextField
                                sx={{ width: 180 }}
                                autoComplete='off'
                                label={t('P.O.BOX')}
                                slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                value={poBox}
                                error={isBlank(street) && isBlank(poBox)}
                                onChange={onChangePoBoxHandler}
                            />

                            <TextField
                                sx={{ width: 180 }}
                                autoComplete='off'
                                label={t('APT.')}
                                slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                value={apt}
                                onChange={onChangeAptHandler}
                            />

                            <TextField
                                sx={{ width: 180 }}
                                autoComplete='off'
                                label={t('SUITE')}
                                slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                value={suite}
                                onChange={onChangeSuiteHandler}
                            />

                            <TextField
                                sx={{ width: 180 }}
                                autoComplete='off'
                                label={t('ZIPCODE')}
                                value={zipcode}
                                slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                onChange={onChangeZipcodeHandler}
                            />
                        </Stack>
                    </Stack>
                }
            </Stack >
        );
    }, [
        shortFormat, onChangeShortFormatHandler, t, onChangeAddressHandler,
        latitude, longitude, address, country, onChangeCountryHandler, state,
        onChangeStateHandler, county, onChangeCountyHandler, city, onChangeCityHandler,
        village, onChangeVillageHandler, street, poBox, onChangeStreetHandler,
        onChangePoBoxHandler, apt, onChangeAptHandler, suite, onChangeSuiteHandler,
        zipcode, onChangeZipcodeHandler
    ]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('APPLY')} arrow>
                <span>
                    <Button
                        variant='contained'
                        disabled={isBlank(country) || (isBlank(street) && isBlank(poBox)) || (isNaN(latitude) || isNaN(longitude))}
                        onClick={onApplyBtnClickHandler}
                        size='small'
                        startIcon={<DoneIcon />}
                    >
                        {t('APPLY')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [country, latitude, longitude, onApplyBtnClickHandler, poBox, street, t]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            title={t('ADDRESS MANAGEMENT')}
            maxWidth={'md'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default AddressDialog;