import { useJsApiLoader } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const libraries: any = ['places'];

const AuthInitConfig = () => {
    const { googleMapsCommonServiceApiKey } = useSelector((state: RootState) => state.preferenceSlice.system);

    useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleMapsCommonServiceApiKey,
        libraries: libraries
    });

    return (<></>);
}
export default AuthInitConfig;