import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useState } from "react";

interface IProps {
    label?: string;
    required?: boolean;
    value?: any;
    data: string[];
    size?: 'small' | 'medium';
    disableItems?: string[];
    onChange?: (event: SelectChangeEvent) => void;
}
const DocumentTypeSelect = (props: IProps) => {
    const { label = 'Type', required, onChange, data, size = 'small', disableItems = [] } = props;
    const [selectedValue, setSelectedValue] = useState<string>(props.value ? props.value.toString() : '');

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormControl fullWidth required={required} size={size}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem value=''><em>None</em></MenuItem>
                {
                    data.map(item =>
                        <MenuItem
                            key={item}
                            disabled={disableItems.includes(item)}
                            value={item}
                        >
                            {item.replaceAll('_', ' ')}
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    );
}
export default DocumentTypeSelect;