import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useState } from "react";
import { ELanguage } from "../../../models/PreferenceModels";

interface IProps {
    label?: string;
    required?: boolean;
    value?: ELanguage;
    onChange?: (value: ELanguage) => void;
}
const LanguageSelect = (props: IProps) => {
    const { label = 'Language', required = false, onChange } = props;
    const [selectedValue, setSelectedValue] = useState<string>(props.value ? props.value.toString() : '');

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event.target.value as ELanguage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormControl fullWidth required={required}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem key={ELanguage.EN} value={ELanguage.EN}>{'ENGLISH'}</MenuItem>
                <MenuItem key={ELanguage.RU} value={ELanguage.RU}>{'РУССКИЙ'}</MenuItem>
            </Select>
        </FormControl>
    );
}
export default LanguageSelect;