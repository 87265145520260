import { Box, Paper } from "@mui/material";
import LoadItemsGrid from "./LoadItemsGrid";

interface IProps {
    loadId: string;
}
const LoadItemsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px', height: 500 }}>
                <LoadItemsGrid loadId={props.loadId} />
            </Box>
        </Paper>
    );
}
export default LoadItemsStep;