import { API } from "./APIService";

class FavoriteListService {

  public static getFavorites = async (key: string): Promise<any> => {
    const [error, response] = await API('GET', key + '/favorite' );
    return [error, response];
  }

  public static createFavorites = async (body: any, key: string): Promise<any> => {
    const [error, response] = await API('POST', key + '/favorite', JSON.stringify(body));
    return [error, response];
  }

  public static deleteFavorites = async (body: any, key: string): Promise<any> => {
    const [error, response] = await API('DELETE', key + '/favorite', body);
    return [error, response];
  }

}

export default FavoriteListService;