import { IFilter } from "../models/CommonModels";
import { EInvoiceType } from "../models/InvoiceModels";
import { API } from "./APIService";

class InvoiceService {
    public static fetchAllByFilter = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'invoices/all', JSON.stringify(filter));
        return [error, response];
    };

    public static fetchDownloadPreviewByFilter = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'invoices/downloadPreview', JSON.stringify(filter));
        return [error, response];
    };

    public static downloadInvoices = async (filter: IFilter, type: EInvoiceType): Promise<any> => {
        const [error, response] = await API('POST', `invoices/download/${type}`, JSON.stringify(filter), undefined, undefined, 'blob');
        return [error, response];
    };
}
export default InvoiceService;