import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../../hooks/useNotification';
import CustomerService from '../../../../services/CustomerService';
import BaseDescription from '../../../Base/BaseDescriptionComponent/BaseDescription';
import CustomerDescriptionDialog from './CustomerDescriptionDialog';

interface IProps {
    customerId: string;
}
const CustomerDescription = (props: IProps) => {
    const { customerId } = props;

    const maxHeight = useRef<string | number>('300px');

    const [refresh, setRefresh] = useState<boolean>(false);
    const [dialog, setDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<string>();

    const { displayNotification } = useNotification();
    const { t } = useTranslation();

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await CustomerService.fetchDescription(customerId);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    useEffect(() => {
        retrieveData();
    }, [retrieveData, refresh]);

    const dialogToggleHandler = useCallback(() => {
        setDialog(dialog => !dialog);
    }, []);

    const refreshHandler = useCallback(() => {
        setRefresh(refresh => !refresh);
    }, []);

    return (
        <>
            <BaseDescription
                data={data}
                loading={loading}
                maxHeight={maxHeight.current}
                editBtnLabel={t('EDIT')}
                editBtnTooltip={t('EDIT')}
                onEditBtnClick={dialogToggleHandler}
            />

            {dialog &&
                <CustomerDescriptionDialog
                    open={dialog}
                    onCloseBtnClick={dialogToggleHandler}
                    onSubmitBtnClick={refreshHandler}
                    customerId={customerId}
                    value={data}
                />
            }
        </>
    );
}
export default CustomerDescription;