import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Stack, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { isEmpty } from '../../../helpers/generalHelper';
import { IItemDetails } from '../../../models/CommonModels';
import { IDocument, IDocumentAttachment } from '../../../models/DocumentModels';
import { EButtonColor } from '../../../models/EnumGeneral';
import ButtonConfirmation from '../ActionConfirmationComponent/ButtonConfirmation';
import FileUploadBtn from '../FileComponent/Upload/FileUploadBtn';
import ItemsDetails from '../ItemsDetailsComponent/ItemsDetails';
import styles from './BaseDocument.module.scss';
import BaseDocumentItemAttachments from './BaseDocumentItemAttachments';

interface IProps {
    document: IDocument;
    expanded: boolean;
    maxHeight: string | number;
    readonly?: boolean;

    onChangeAcordeon: (value: string | false) => void;

    summary: string;
    itemsDetails: IItemDetails[];

    editBtnLabel?: string;
    editBtnTooltip?: string;
    onEditBtnClick: (document: IDocument) => void;

    deleteBtnLabel?: string;
    deleteBtnTooltip?: string;
    onDeleteBtnClick: (uuid: string) => void;

    addAttachmentBtnLabel?: string;
    addAttachmentBtnTooltip?: string;
    onSubmitAttachment: (documentId: string, file: File) => void;
    onDeleteAttachment: (documentId: string, attachmentId: string) => void;
}
const BaseDocumentItem = (props: IProps) => {
    const {
        document, summary, itemsDetails, expanded, editBtnLabel = 'EDIT',
        editBtnTooltip = 'Edit document', onEditBtnClick, deleteBtnLabel = 'DELETE',
        deleteBtnTooltip = 'Delete document', onDeleteBtnClick, addAttachmentBtnLabel = 'ATTACHMENT',
        addAttachmentBtnTooltip = 'Add attachment', onSubmitAttachment, onDeleteAttachment,
        onChangeAcordeon, maxHeight, readonly = false
    } = props;

    const attachments: IDocumentAttachment[] = document.attachments || [];
    const areAttachmentsExists: boolean = !isEmpty(attachments);

    const acceptedFileTypes = '.png, .jpg, .jpeg, .txt, .pdf';
    const adjustedHeight: string = '130px';

    const onChangeAcordeonHandler = useCallback((value: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        onChangeAcordeon(isExpanded ? value : false);
    }, [onChangeAcordeon]);

    const onEditBtnClickHandler = useCallback(() => {
        onEditBtnClick(document);
    }, [document, onEditBtnClick]);

    const onDeleteBtnClickHandler = useCallback(() => {
        onDeleteBtnClick(document.uuid);
    }, [document.uuid, onDeleteBtnClick]);

    const onSubmitAttachmentHandler = useCallback((file: File) => {
        onSubmitAttachment(document.uuid, file);
    }, [document.uuid, onSubmitAttachment]);

    const onDeleteAttachmentHandler = useCallback((attachmentId: string) => {
        onDeleteAttachment(document.uuid, attachmentId);
    }, [document.uuid, onDeleteAttachment]);

    return (
        <Accordion
            disableGutters={true}
            expanded={expanded}
            onChange={onChangeAcordeonHandler(document.uuid)}
            className={styles.item}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {summary}
            </AccordionSummary>
            <AccordionDetails className={styles.itemDetails}>
                <Stack direction='row' className={styles.itemDetailsToolbar}>
                    <Box flex={1} className={styles.itemDetailsToolbarLeft}>
                        <Tooltip title={editBtnTooltip} placement='top' arrow>
                            <span>
                                <Button
                                    variant={'outlined'}
                                    size={'small'}
                                    disabled={readonly}
                                    onClick={onEditBtnClickHandler}
                                >
                                    <EditIcon fontSize='small' />{editBtnLabel}
                                </Button>
                            </span>
                        </Tooltip>

                        <ButtonConfirmation
                            variant='outlined'
                            onConfirm={onDeleteBtnClickHandler}
                            label={deleteBtnLabel}
                            icon={<DeleteIcon fontSize='small' />}
                            color={EButtonColor.error}
                            tooltip={deleteBtnTooltip}
                            disabled={readonly}
                        />
                    </Box>

                    <Box flex={1} className={styles.itemDetailsToolbarRight}>
                        <FileUploadBtn
                            label={addAttachmentBtnLabel}
                            tooltip={addAttachmentBtnTooltip}
                            variant='outlined'
                            size='small'
                            icon={<AddIcon fontSize='small' />}
                            acceptedFiles={acceptedFileTypes}
                            onSelect={onSubmitAttachmentHandler}
                            disabled={readonly}
                        />
                    </Box>
                </Stack>

                <Divider className={styles.itemDetailsDivider} />

                <Stack direction='row' className={styles.itemDetailsContent}>
                    <Box flex={1} sx={{ paddingRight: areAttachmentsExists ? '20px' : '0px', maxHeight: `calc(${maxHeight} - ${adjustedHeight})`, overflowY: 'scroll' }}>
                        <ItemsDetails data={itemsDetails} />
                    </Box>
                    {
                        areAttachmentsExists &&
                        <Box flex={1} sx={{ paddingLeft: '20px', borderLeft: '1px dashed silver', maxHeight: `calc(${maxHeight} - ${adjustedHeight})`, overflowY: 'scroll' }}>
                            <BaseDocumentItemAttachments
                                data={attachments}
                                readonly={readonly}
                                onDeleteBtnClick={onDeleteAttachmentHandler}
                            />
                        </Box>
                    }
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
export default BaseDocumentItem;