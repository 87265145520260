import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../../hooks/useNotification';
import { IDescriptionRequestDto } from "../../../../models/CommonModels";
import UserService from "../../../../services/UserService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import RichTextEditor from "../../../Base/RichTextEditorComponent/RichTextEditor";

interface IProps {
    userId: string;
    value: string | undefined;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const UserDescriptionDialog = (props: IProps) => {
    const { userId, value, open, onCloseBtnClick, onSubmitBtnClick } = props;

    const { displayNotification } = useNotification();
    const { t } = useTranslation();
    const formId: string = 'user-description-form';

    const [loading, setLoading] = useState<boolean>(false);

    const { register, setValue, getValues, handleSubmit, formState: { isDirty, isValid } } = useForm<IDescriptionRequestDto>({
        defaultValues: { value: value }
    });

    const onSubmit = useCallback((data: IDescriptionRequestDto) => {
        (async () => {
            setLoading(true);

            const [error, response] = await UserService.updateDescription(userId, data.value);
            if (response) {
                displayNotification({ message: t('User description was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, onCloseBtnClick, onSubmitBtnClick, t]);

    register('value');
    const onChangeHandler = useCallback((data: string) => {
        setValue('value', data, { shouldDirty: true });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Box height={400}>
                    <RichTextEditor
                        value={getValues('value')}
                        onChange={onChangeHandler}
                        autofocus
                    />
                </Box>
            </form>
        );
    }, [getValues, handleSubmit, onChangeHandler, onSubmit]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t('EDIT DESCRIPTION')}
            maxWidth={'md'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default UserDescriptionDialog;