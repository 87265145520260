import { configureStore } from '@reduxjs/toolkit'
import auditSlice from './reducers/auditSlice'
import cnfSlice from './reducers/cnfSlice'
import { CustomerReducer } from './reducers/customerSlice'
import favoritesSlice from './reducers/favoritesSlice'
import { GlobalDataReducer } from './reducers/globalDataSlice'
import { InvoiceReducer } from './reducers/invoiceSlice'
import { IssueReducer } from './reducers/issueSlice'
import { LoadReducer } from './reducers/loadSlice'
import { NotificationReducer } from './reducers/notificationSlice'
import { PreferenceReducer } from './reducers/preferenceSlice'
import { TransportReducer } from './reducers/transportSlice'
import { UserProfileReducer } from './reducers/userProfileSlice'
import { UserReducer } from './reducers/userSlice'
import { WarehouseReducer } from './reducers/warehouseSlice'

export const store = configureStore({
  reducer: {
    cnfSlice,
    favoritesSlice,
    auditSlice,
    notification: NotificationReducer,
    globalDataSlice: GlobalDataReducer,
    userProfileSlice: UserProfileReducer,
    transportSlice: TransportReducer,
    warehouseSlice: WarehouseReducer,
    customerSlice: CustomerReducer,
    loadSlice: LoadReducer,
    issueSlice: IssueReducer,
    userSlice: UserReducer,
    invoiceSlice: InvoiceReducer,
    preferenceSlice: PreferenceReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch