import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { useNotification } from "../../../hooks/useNotification";
import { EBrandType, IBrandRequestDto, IBrandResponseDto } from "../../../models/MerchandiseModels";
import { EFuelType, ETransmissionType, EVehicleType, IVehicleRequestDto, IVehicleResponseDto } from "../../../models/TransportModels";
import TransportService from "../../../services/TransportService";
import { RootState } from "../../../store/store";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import BrandAutocomplete from "../../Base/BrandComponent/BrandAutocomplete";
import NumberField from "../../Base/NumberFieldComponent/NumberField";
import FuelTypeSelect from "../FuelTypeSelect";
import TransmissionTypeSelect from "../TransmissionTypeSelect";
import TransportTypeSelect from "../TransportTypeSelect";

const emptyBrand: IBrandRequestDto = {
    uuid: '',
    name: '',
    type: EBrandType.NONE
};

interface IProps {
    vehicle?: IVehicleResponseDto;
    copyVehicle?: IVehicleResponseDto;
    copy?: boolean;

    open: boolean;
    type?: EVehicleType;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: (entityId?: string) => void;
}
const VehicleDialog = (props: IProps) => {
    const { open, type, vehicle, copyVehicle, copy = false, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'vehicle-form';

    const { size, mass, consumption, enginePower } = useSelector((state: RootState) => state.preferenceSlice.global);
    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, setError, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IVehicleRequestDto>({
        defaultValues: {
            brand: vehicle ? vehicle?.brand : copy ? copyVehicle?.brand : emptyBrand,
            model: vehicle ? vehicle?.model : copy ? copyVehicle?.model : '',
            type: vehicle ? vehicle?.type : type ? type : copy ? copyVehicle?.type : EVehicleType.NONE,
            year: vehicle ? vehicle?.year : NaN,
            length: vehicle ? vehicle?.length : copy ? copyVehicle?.length : NaN,
            width: vehicle ? vehicle?.width : copy ? copyVehicle?.width : NaN,
            height: vehicle ? vehicle?.height : copy ? copyVehicle?.height : NaN,
            weight: vehicle ? vehicle?.weight : copy ? copyVehicle?.weight : NaN,
            grossWeight: vehicle ? vehicle?.grossWeight : copy ? copyVehicle?.grossWeight : NaN,
            fuelType: vehicle ? vehicle?.fuelType : copy ? copyVehicle?.fuelType : undefined,
            fuelConsumption: vehicle ? vehicle?.fuelConsumption : copy ? copyVehicle?.fuelConsumption : undefined,
            transmissionType: vehicle ? vehicle?.transmissionType : copy ? copyVehicle?.transmissionType : undefined,
            enginePower: vehicle ? vehicle?.enginePower : copy ? copyVehicle?.enginePower : undefined
        }
    });

    const updateData = useCallback((uuid: string, data: IVehicleRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await TransportService.updateVehicle(uuid, data);
            if (response) {
                displayNotification({ message: t('Vehicle was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: IVehicleRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await TransportService.createVehicle(data);
            if (response) {
                displayNotification({ message: t('Vehicle was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick(response.data.response.entityId);
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: IVehicleRequestDto) => {
        if (vehicle) {
            updateData(vehicle.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, vehicle, updateData]);

    const validateTypeField = useCallback((value: EVehicleType) => {
        return EVehicleType[value] !== undefined && value !== EVehicleType.NONE;
    }, []);

    register('type', { validate: validateTypeField });
    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: EVehicleType = event.target.value as EVehicleType;
        setValue('type', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateBrandField = useCallback((value: IBrandRequestDto | null) => {
        return value !== null;
    }, []);

    register('brand', { validate: validateBrandField });
    const onBrandChangeHandler = useCallback((value: IBrandResponseDto | null) => {
        setValue('brand', value === null ? emptyBrand : { ...value }, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateModel = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('model', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const validateAmountField = useCallback((value: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('year', { validate: validateAmountField });
    const onYearChangeField = useCallback((value: number) => {
        setValue('year', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('length', { validate: validateAmountField });
    const onLengthChangeField = useCallback((value: number) => {
        setValue('length', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('width', { validate: validateAmountField });
    const onWidthChangeField = useCallback((value: number) => {
        setValue('width', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('height', { validate: validateAmountField });
    const onHeightChangeField = useCallback((value: number) => {
        setValue('height', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('weight', { validate: validateAmountField });
    const onWeightChangeField = useCallback((value: number) => {
        setValue('weight', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('grossWeight', { validate: validateAmountField });
    const onGrossWeightChangeField = useCallback((value: number) => {
        setValue('grossWeight', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('fuelType');
    const onFuelChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: EFuelType = event.target.value as EFuelType;
        setValue('fuelType', value, {
            shouldDirty: true
        });
    }, [setValue]);

    register('fuelConsumption');
    const onFuelConsumptionChangeField = useCallback((value: number) => {
        setValue('fuelConsumption', value, {
            shouldDirty: true
        });
    }, [setValue]);

    register('transmissionType');
    const onTransmissionChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: ETransmissionType = event.target.value as ETransmissionType;
        setValue('transmissionType', value, {
            shouldDirty: true
        });
    }, [setValue]);

    register('enginePower');
    const onEnginePowerChangeField = useCallback((value: number) => {
        setValue('enginePower', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack direction='row' spacing={2}>
                    <Stack direction='column' width={'100%'} spacing={2}>
                        <TransportTypeSelect
                            required
                            label={t('TYPE')}
                            value={getValues('type')}
                            onChange={onTypeChangeHandler}
                            readonly={type !== undefined}
                        />

                        <BrandAutocomplete
                            required
                            label={t('BRAND')}
                            type={getValues('type') as unknown as EBrandType}
                            value={getValues('brand').name}
                            onChange={onBrandChangeHandler}
                        />

                        <Stack direction='row' spacing={2}>
                            <TextField
                                {...register('model', { validate: validateModel })}
                                label={t('MODEL')}
                                required
                                fullWidth
                                slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                                autoComplete='off'
                            />

                            <NumberField
                                required
                                label={t('YEAR')}
                                value={getValues('year')}
                                allowNegative={false}
                                scale={0}
                                thousandSeparator={false}
                                onChange={onYearChangeField}
                            />
                        </Stack>

                        <Stack direction='row' spacing={2}>
                            <NumberField
                                required
                                label={t('LENGTH')}
                                value={getValues('length')}
                                allowNegative={false}
                                thousandSeparator={false}
                                units={size}
                                onChange={onLengthChangeField}
                            />

                            <NumberField
                                required
                                label={t('WIDTH')}
                                value={getValues('width')}
                                allowNegative={false}
                                thousandSeparator={false}
                                units={size}
                                onChange={onWidthChangeField}
                            />

                            <NumberField
                                required
                                label={t('HEIGHT')}
                                value={getValues('height')}
                                allowNegative={false}
                                thousandSeparator={false}
                                units={size}
                                onChange={onHeightChangeField}
                            />
                        </Stack>
                    </Stack>

                    <Stack direction='column' width={'100%'} spacing={2}>
                        <Stack direction='row' spacing={2}>
                            <NumberField
                                required
                                label={t('WEIGHT')}
                                value={getValues('weight')}
                                allowNegative={false}
                                thousandSeparator={false}
                                units={mass}
                                onChange={onWeightChangeField}
                            />

                            <NumberField
                                required
                                label={t('GROSS WEIGHT')}
                                value={getValues('grossWeight')}
                                allowNegative={false}
                                thousandSeparator={false}
                                units={mass}
                                onChange={onGrossWeightChangeField}
                            />
                        </Stack>

                        {getValues('type') !== EVehicleType.NONE && getValues('type') !== EVehicleType.TRAILER &&
                            <Stack direction='row' spacing={2}>
                                <FuelTypeSelect
                                    label={t('FUEL TYPE')}
                                    value={getValues('fuelType')}
                                    onChange={onFuelChangeHandler}
                                />

                                <NumberField
                                    label={t('FUEL CONSUMPTION')}
                                    value={getValues('fuelConsumption') || NaN}
                                    allowNegative={false}
                                    thousandSeparator={false}
                                    units={consumption}
                                    onChange={onFuelConsumptionChangeField}
                                />
                            </Stack>
                        }

                        {getValues('type') !== EVehicleType.NONE && getValues('type') !== EVehicleType.TRAILER &&
                            <Stack direction='row' spacing={2}>
                                <TransmissionTypeSelect
                                    label={t('TRANSMISSION TYPE')}
                                    value={getValues('transmissionType')}
                                    onChange={onTransmissionChangeHandler}
                                />

                                <NumberField
                                    label={t('ENGINE POWER')}
                                    value={getValues('enginePower') || NaN}
                                    allowNegative={false}
                                    thousandSeparator={false}
                                    units={enginePower}
                                    onChange={onEnginePowerChangeField}
                                />
                            </Stack>
                        }
                    </Stack>
                </Stack>
            </form>
        );
    }, [
        consumption, enginePower, getValues, handleSubmit, mass, onBrandChangeHandler,
        onEnginePowerChangeField, onFuelChangeHandler, onFuelConsumptionChangeField,
        onGrossWeightChangeField, onHeightChangeField, onLengthChangeField, onSubmit,
        onTransmissionChangeHandler, onTypeChangeHandler, onWeightChangeField,
        onWidthChangeField, onYearChangeField, register, size, t, type, validateModel
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${vehicle ? 'EDIT' : 'CREATE'} VEHICLE`)}
            maxWidth={'md'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default VehicleDialog;