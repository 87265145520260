import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDateAndTimeFormat } from "../../../../helpers/dateHelper";
import { isEmpty } from "../../../../helpers/generalHelper";
import { useLoad } from "../../../../hooks/useLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { IFilter } from "../../../../models/CommonModels";
import { ILoadTransportResponseDto } from "../../../../models/LoadModels";
import { ITransportShortResponseDto } from "../../../../models/TransportModels";
import LoadService from "../../../../services/LoadService";
import { RootState } from "../../../../store/store";
import AvatarComponent from "../../../Base/AvatarComponent/AvatarComponent";
import BaseCrudGrid from "../../../Base/BaseCrudGridComponent/BaseCrudGrid";
import LoadTransportsDialog from "./LoadTransportsDialog";

interface IProps {
    loadId: string;
}
const LoadTransportsGrid = (props: IProps) => {
    const { loadId } = props;

    const { t } = useTranslation();
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.transportsGrid);
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { mass } = useSelector((state: RootState) => state.preferenceSlice.global);
    const { displayNotification } = useNotification();
    const { gridRefresh, transportsGridRefresh, stepRefresh } = useLoad();

    const [dialogToggle, setDialogToggle] = useState(false);
    const [loadTransport, setLoadTransport] = useState<ILoadTransportResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchTransports(loadId, filter);
    }, [loadId]);

    const onAddActionBtnClickHandler = useCallback(() => {
        setLoadTransport(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClickHandler = useCallback((loadTransport: ILoadTransportResponseDto) => {
        setLoadTransport(loadTransport);
        setDialogToggle(true);
    }, []);

    const refreshGrid = useCallback(() => {
        transportsGridRefresh();
    }, [transportsGridRefresh]);

    const onDeleteActionBtnClickHandler = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await LoadService.removeTransport(loadId, rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Load transport${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    refreshGrid();
                    stepRefresh();
                    gridRefresh();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t, refreshGrid]);

    const onAvatarColumnRender = useCallback((params: GridRenderCellParams) => {
        const transport: ITransportShortResponseDto = params.row.transport;
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                <AvatarComponent
                    data={transport.mainImage}
                    width={27}
                    height={27}
                    defaultValue={transport.brand}
                />
            </Box>
        );
    }, []);

    const onBrandModelColumnRender = useCallback((params: GridRenderCellParams) => {
        const transport: ITransportShortResponseDto = params.row.transport;
        const value: string = `${transport.brand} ${transport.model}`;
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onTypeColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = params.value.toString().replaceAll('_', ' ');
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onNumberColumnRender = useCallback((params: GridRenderCellParams) => {
        const transport: ITransportShortResponseDto = params.row.transport;
        return (
            <Tooltip title={transport.transportNumber}>
                <span>{transport.transportNumber}</span>
            </Tooltip>
        );
    }, []);

    const onLicensePlateColumnRender = useCallback((params: GridRenderCellParams) => {
        const transport: ITransportShortResponseDto = params.row.transport;
        return (
            <Tooltip title={transport.licensePlate}>
                <span>{transport.licensePlate}</span>
            </Tooltip>
        );
    }, []);

    const onWeightColumnRender = useCallback((params: GridRenderCellParams) => {
        const transport: ITransportShortResponseDto = params.row.transport;
        const weight: string = `${transport.weight} ${mass}`;
        const grossWeight: string = `${transport.grossWeight} ${mass}`;

        return (
            <Box sx={{ display: 'flex', gap: '12px' }}>
                <Tooltip title={`${t('WEIGHT: ')} ${weight}`}>
                    <span>{weight}</span>
                </Tooltip>

                <span>{'|'}</span>

                <Tooltip title={`${t('GROSS WEIGHT: ')} ${grossWeight}`}>
                    <span>{grossWeight}</span>
                </Tooltip>
            </Box>
        );
    }, [mass, t]);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'avatar',
            headerName: '',
            width: 45,
            headerAlign: 'center',
            sortable: false,
            resizable: false,
            disableColumnMenu: true,
            hideable: false,
            renderCell: onAvatarColumnRender
        }, {
            field: 'brandAndModel',
            headerName: t('BRAND & MODEL'),
            flex: 1,
            minWidth: 200,
            headerAlign: 'center',
            hideable: false,
            renderCell: onBrandModelColumnRender
        }, {
            field: 'type',
            headerName: t('TYPE'),
            width: 150,
            headerAlign: 'center',
            hideable: false,
            renderCell: onTypeColumnRender
        }, {
            field: 'transportNumber',
            headerName: t('NUMBER'),
            width: 80,
            headerAlign: 'center',
            align: 'center',
            hideable: false,
            renderCell: onNumberColumnRender
        }, {
            field: 'licensePlate',
            headerName: t('LICENSE PLATE'),
            width: 125,
            headerAlign: 'center',
            renderCell: onLicensePlateColumnRender
        }, {
            field: 'weight',
            headerName: t('WEIGHT'),
            width: 210,
            headerAlign: 'center',
            renderCell: onWeightColumnRender
        }, {
            field: "created",
            headerName: t('CREATED'),
            width: 150,
            headerAlign: 'center',
            renderCell: onCreatedColumnRender
        }];
    }, [
        onAvatarColumnRender, onBrandModelColumnRender, onCreatedColumnRender,
        onLicensePlateColumnRender, onNumberColumnRender, onTypeColumnRender, t,
        onWeightColumnRender
    ]);

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setLoadTransport(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        refreshGrid();
    }, [refreshGrid]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams, row: any) => {
        setLoadTransport(row);
        setDialogToggle(true);
    }, []);

    return (
        <>
            <BaseCrudGrid
                gridId='load-transport'
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClickHandler}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add transport')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClickHandler}
                editActionBtnTooltip={t('Edit transport')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClickHandler}
                deleteActionBtnTooltip={t('Delete transport(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
                onRowDoubleClick={onRowDoubleClickHandler}
            />

            {dialogToggle &&
                <LoadTransportsDialog
                    open={dialogToggle}
                    loadId={loadId}
                    entity={loadTransport}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default LoadTransportsGrid;