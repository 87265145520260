import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../helpers/generalHelper";
import { useLoad } from "../../../hooks/useLoad";
import { useNotification } from '../../../hooks/useNotification';
import { IAddressShortResponseDto } from "../../../models/AddressModels";
import { IFilter } from '../../../models/CommonModels';
import { ELoadAppointmentType, ELoadShipperConsigneeDirection, ILoadShipperConsigneeResponseDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";
import { RootState } from "../../../store/store";
import BaseCrudGrid from '../../Base/BaseCrudGridComponent/BaseCrudGrid';
import ShipperConsigneeAddButton from "./ShipperConsigneeAddButton";
import ShipperConsigneeDialog from "./ShipperConsigneeDialog";

const ShipperConsigneeGrid = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { shipperConsigneeGridRefresh } = useLoad();
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.shipperConsigneeGrid);

    const [dialogToggle, setDialogToggle] = useState(false);
    const [entity, setEntity] = useState<ILoadShipperConsigneeResponseDto>();
    const [entityDirection, setEntityDirection] = useState<ELoadShipperConsigneeDirection>();
    const [deleteBtnDisabled, setDeleteBtnDisabled] = useState<boolean>(true);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchShipperConsignee(filter);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await LoadService.removeShipperConsignee(rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t('Shipper(s) / Consignee(s) was successfully deleted.') });
                    shipperConsigneeGridRefresh();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipperConsigneeGridRefresh, t]);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onAddressColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: IAddressShortResponseDto = params.value;
        return (
            <Tooltip title={value.value}>
                <span>{value.value}</span>
            </Tooltip>
        );
    }, []);

    const onPhoneColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadShipperConsigneeResponseDto = params.row;

        const phone: string | undefined = row.phone;
        const tollFree: string = row.tollFree || 'N/A';
        const tooltip: string = `${t('PHONE: ')} ${phone || 'N/A'} | ${t('TOLL FREE: ')} ${tollFree}`

        return (
            <Tooltip title={tooltip}>
                <span>{phone}</span>
            </Tooltip>
        );
    }, [t]);

    const onApptColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: ELoadAppointmentType = params.value;
        const toltipStr: string = ELoadAppointmentType.FCFS === value ? 'FIRST COME FIRST SERVE' : value.toString();
        return (
            <Tooltip title={toltipStr}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'name',
            headerName: t('NAME'),
            flex: 1,
            minWidth: 150,
            headerAlign: 'center',
            hideable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'address',
            headerName: t('ADDRESS'),
            flex: 1,
            minWidth: 150,
            headerAlign: 'center',
            renderCell: onAddressColumnRender
        }, {
            field: 'direction',
            headerName: t('TYPE'),
            width: 110,
            headerAlign: 'center',
            hideable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'contactName',
            headerName: t('CONTACT NAME'),
            width: 140,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'contactEmail',
            headerName: t('EMAIL'),
            width: 130,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'phone',
            headerName: t('PHONE'),
            width: 130,
            headerAlign: 'center',
            renderCell: onPhoneColumnRender
        }, {
            field: 'hours',
            headerName: t('HOURS'),
            width: 150,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'appointments',
            headerName: t('APPT.'),
            width: 60,
            headerAlign: 'center',
            renderCell: onApptColumnRender
        }, {
            field: 'majorIntersectionsDirections',
            headerName: t('M.I.D.'),
            width: 100,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'status',
            headerName: t('STATUS'),
            width: 90,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }];
    }, [t, onTooltipColumnRender, onAddressColumnRender, onPhoneColumnRender, onApptColumnRender]);

    const onSubmitDialogHandler = useCallback(() => {
        shipperConsigneeGridRefresh();
    }, [shipperConsigneeGridRefresh]);

    const onEditActionBtnClick = useCallback((entity: ILoadShipperConsigneeResponseDto) => {
        setEntity(entity);
        setEntityDirection(entity.direction);
        setDialogToggle(true);
    }, []);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams, row: any) => {
        setEntity(row);
        setEntityDirection(row.direction);
        setDialogToggle(true);
    }, []);

    const dialogToggleHandler = useCallback(() => {
        setDialogToggle(dialogToggle => !dialogToggle);
    }, []);

    const onAddBtnClickHandler = useCallback((direction: ELoadShipperConsigneeDirection) => {
        setEntityDirection(direction);
        setEntity(undefined);
        dialogToggleHandler();
    }, [dialogToggleHandler]);

    const onCloseDialogHandler = useCallback(() => {
        dialogToggleHandler();
    }, [dialogToggleHandler]);

    const buildAddActionBtn = useCallback(() => {
        return (
            <ShipperConsigneeAddButton
                onBtnClick={onAddBtnClickHandler}
            />
        );
    }, [onAddBtnClickHandler]);

    const onSelectionModelChange = useCallback((selectedRows: ILoadShipperConsigneeResponseDto[]) => {
        const existNotDeleted: boolean = selectedRows.some(item => item.allowDelete === false);
        setDeleteBtnDisabled(existNotDeleted);
    }, []);

    return (
        <>
            <BaseCrudGrid
                gridId='shipper-consignee'
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                addCustomActionBtn={buildAddActionBtn()}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                deleteActionBtnDisabled={deleteBtnDisabled}
                refreshActionBtnTooltip={t('Refresh data')}
                onRowDoubleClick={onRowDoubleClickHandler}
                onSelectionModelChange={onSelectionModelChange}
            />

            {dialogToggle && entityDirection &&
                <ShipperConsigneeDialog
                    open={dialogToggle}
                    direction={entityDirection}
                    entity={entity}
                    onCloseBtnClick={onCloseDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default ShipperConsigneeGrid;