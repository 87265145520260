import { Box, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";

interface IProps {
    label?: string;
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    rows?: number;
    value?: string | null;
    showCount?: boolean;
    readOnly?: boolean;
    onChange?: (value: string) => void;
}
const TextareaField = (props: IProps) => {
    const {
        label, minLength, maxLength, rows, value = '', showCount = false, onChange,
        required = false, readOnly = false
    } = props;

    const maxCount = useRef<number | undefined>(maxLength);
    const [count, setCount] = useState<number>(value ? value.length : 0);

    const onChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value || '';
        if (onChange) {
            onChange(value);
        }
        setCount(value.length);
    }, [onChange]);

    return (
        <Box>
            <TextField
                required={required}
                fullWidth
                label={label}
                slotProps={{ htmlInput: { minLength, maxLength, readOnly } }}
                autoComplete='off'
                rows={rows}
                multiline
                value={value}
                onChange={onChangeHandler}
            />
            {showCount &&
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'right',
                        fontSize: '12px',
                        marginTop: '5px'
                    }}
                >
                    {count} / {maxCount.current}
                </span>
            }
        </Box>
    );
}
export default TextareaField;