import { APIProvider, Map, MapCameraChangedEvent, MapCameraProps } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../helpers/generalHelper";
import { IMapMarker } from "../../../models/CommonModels";
import { RootState } from "../../../store/store";
import MapsMarker from "./MapsMarker";

interface IProps {
    markers?: IMapMarker[];
    markerEnable?: boolean;
}
const GoogleMap = (props: IProps) => {
    const { markers = [], markerEnable = false } = props;

    const { googleMapsCommonServiceApiKey, googleMapsIdKey } = useSelector((state: RootState) => state.preferenceSlice.system);

    const [items, setItems] = useState<IMapMarker[]>([]);
    const [cameraProps, setCameraProps] = useState<MapCameraProps>(ONE_ITEM_CAMERA);

    useEffect(() => {
        const items: IMapMarker[] = isEmpty(markers)
            ? [{ ...ONE_ITEM_CAMERA.center }]
            : markers;

        const cameraProps: MapCameraProps = items.length === 1
            ? { center: { ...items[0] }, zoom: ONE_ITEM_CAMERA.zoom }
            : MANY_ITEMS_CAMERA;

        setItems(items);
        setCameraProps(cameraProps);
    }, [markers])

    const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => {
        setCameraProps(ev.detail);
    }, []);

    return (
        <APIProvider apiKey={googleMapsCommonServiceApiKey}>
            <Map {...cameraProps}
                style={{ width: '100%', height: '100%' }}
                reuseMaps
                onCameraChanged={handleCameraChange}
                mapId={googleMapsIdKey}
                disableDefaultUI={true}
            >
                {markerEnable && items.map(item =>
                    <MapsMarker
                        item={item}
                        key={`maps-marker-${item.lat}-${item.lng}-${item.title}`}
                    />
                )}
            </Map>
        </APIProvider>
    );
}

const ONE_ITEM_CAMERA: MapCameraProps = {
    center: { lat: 45.11758156771888, lng: -93.27245577338347 },
    zoom: 17
};

const MANY_ITEMS_CAMERA: MapCameraProps = {
    center: { lat: 39.39953812348819, lng: -100.04003389838347 },
    zoom: 4
};

export default GoogleMap;