import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { CSSProperties, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { getDateAndTimeFormat } from '../../../helpers/dateHelper';
import { isEmpty } from '../../../helpers/generalHelper';
import { textColorGreen, textColorOrange, textColorRed } from '../../../helpers/gridHelper';
import { useIssue } from '../../../hooks/useIssue';
import { useNotification } from '../../../hooks/useNotification';
import { EPriority, IBaseGridActionBtn, IFilter, IIdValue } from '../../../models/CommonModels';
import { EIssueStatus, EIssueType, IIssueBusinessResponseDto } from '../../../models/IssueModels';
import IssueService from '../../../services/IssueService';
import { RootState } from '../../../store/store';
import BaseCrudGrid from '../../Base/BaseCrudGridComponent/BaseCrudGrid';
import IssueResolveDialog from '../IssueResolveDialog';
import IssueBusinessDialog from './IssueBusinessDialog';

interface IProps {
    type: EIssueType;
    businessItemId: string;
    onSubmit: () => void;
}
const IssueBusinessGrid = (props: IProps) => {
    const { type, businessItemId, onSubmit } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { businessGridRefresh, businessGridUnselectRows } = useIssue();

    const [dialogToggle, setDialogToggle] = useState(false);
    const [resolveDialogToggle, setResolveDialogToggle] = useState(false);
    const [issue, setIssue] = useState<IIssueBusinessResponseDto | undefined>();
    const [editActionBtnDisabled, setEditActionBtnDisabled] = useState<boolean>(true);
    const [inProgressActionBtnDisabled, setInProgressActionBtnDisabled] = useState<boolean>(true);
    const [resolveActionBtnDisabled, setResolveActionBtnDisabled] = useState<boolean>(true);

    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.issueSlice.businessGrid);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return IssueService.fetchAllByBusinessItem(businessItemId, type, filter);
    }, [businessItemId, type]);

    const onAddActionBtnClick = useCallback(() => {
        setIssue(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClick = useCallback((issue: IIssueBusinessResponseDto) => {
        setIssue(issue);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await IssueService.remove(rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Task${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    businessGridRefresh();
                    onSubmit();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const refreshGrid = useCallback(() => {
        businessGridUnselectRows();
        businessGridRefresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectionModelChange = useCallback((selectedRows: IIssueBusinessResponseDto[]) => {
        const selectedRowsCount: number = selectedRows.length;
        const firstRow: IIssueBusinessResponseDto | null = selectedRowsCount > 0 ? selectedRows[0] : null;
        const firstRowStatus: EIssueStatus | null = firstRow ? firstRow.status : null;
        const isAssignedOn: boolean = firstRow != null && firstRow.assignedOn !== null;

        setEditActionBtnDisabled(firstRowStatus === EIssueStatus.DONE || firstRowStatus === EIssueStatus.UNRESOLVED);
        setInProgressActionBtnDisabled(selectedRowsCount !== 1 || firstRowStatus !== EIssueStatus.OPEN || !isAssignedOn);
        setResolveActionBtnDisabled(selectedRowsCount !== 1 || firstRowStatus !== EIssueStatus.IN_PROGRESS);
    }, []);

    const onInProgressActionBtnClickHandler = useCallback((issue: IIssueBusinessResponseDto) => {
        if (issue) {
            (async () => {
                const [error] = await IssueService.markInProgress(issue.uuid);

                if (error) {
                    displayNotification({ type: 'error', message: error?.message });
                } else {
                    displayNotification({ message: t('Task was successfully marked in progress.') });
                    refreshGrid();
                    onSubmit();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshGrid, t]);

    const onResolveActionBtnClickHandler = useCallback((issue: IIssueBusinessResponseDto) => {
        setIssue(issue);
        setResolveDialogToggle(true);
    }, []);

    const actionBtns: IBaseGridActionBtn[] = [{
        tooltip: t('Start working on the task'),
        onClick: onInProgressActionBtnClickHandler,
        icon: <PublishedWithChangesIcon fontSize='small' />,
        label: t('START'),
        disabled: inProgressActionBtnDisabled,
        key: 'mark-in-progress-action-btn',
        confirmation: true
    }, {
        tooltip: t('Resolve task'),
        onClick: onResolveActionBtnClickHandler,
        icon: <CheckCircleIcon fontSize='small' />,
        label: t('RESOLVE'),
        disabled: resolveActionBtnDisabled,
        key: 'resolve-action-btn'
    }];

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setIssue(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        refreshGrid();
        onSubmit();
    }, [onSubmit, refreshGrid]);

    const closeResolveDialogHandler = useCallback(() => {
        setResolveDialogToggle(false);
        setIssue(undefined);
    }, []);

    const onSubmitResolveDialogHandler = useCallback(() => {
        refreshGrid();
        onSubmit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshGrid]);

    const onPriorityColumnRender = useCallback((params: GridRenderCellParams) => {
        const priority: string = EPriority[params.value];

        let style: CSSProperties = {};
        switch (priority) {
            case EPriority[EPriority.LOW]: {
                style = textColorGreen;
                break;
            }
            case EPriority[EPriority.MEDIUM]: {
                style = textColorOrange;
                break;
            }
            case EPriority[EPriority.HIGH]: {
                style = textColorRed;
                break;
            }
        }

        return <span style={style}>{priority}</span>;
    }, []);

    const onTitleOrCauseOrIdColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onAssignedColumnRender = useCallback((params: GridRenderCellParams) => {
        const assignedOn: IIdValue = params.row.assignedOn;
        if (!assignedOn) {
            return '';
        }

        const author: string = assignedOn.name;
        const dateTime: string = getDateAndTimeFormat(params.row.assignedDate, dateFormat, timeFormat);
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onStatusColumnRender = useCallback((params: GridRenderCellParams) => {
        const status: EIssueStatus = params.value;
        const value: string = status.toString().replaceAll('_', ' ');
        const tooltip: string = (EIssueStatus.UNRESOLVED === status || EIssueStatus.DONE === status)
            ? params.row.conclusion
            : '';

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'idno',
            headerName: t('ID#'),
            width: 80,
            type: 'number',
            align: 'left',
            headerAlign: 'center',
            hideable: false,
            renderCell: onTitleOrCauseOrIdColumnRender
        }, {
            field: 'title',
            headerName: t('TITLE'),
            hideable: false,
            flex: 1,
            minWidth: 130,
            headerAlign: 'center',
            renderCell: onTitleOrCauseOrIdColumnRender
        }, {
            field: 'cause',
            headerName: `${t('CAUSE')}`,
            width: 200,
            headerAlign: 'center',
            renderCell: onTitleOrCauseOrIdColumnRender
        }, {
            field: 'priority',
            headerName: `${t('PRIORITY')}`,
            width: 85,
            headerAlign: 'center',
            align: 'left',
            renderCell: onPriorityColumnRender
        }, {
            field: 'assigned',
            headerName: `${t('ASSIGNED')}`,
            width: 130,
            headerAlign: 'center',
            renderCell: onAssignedColumnRender
        }, {
            field: 'status',
            headerName: `${t('STATUS')}`,
            width: 135,
            headerAlign: 'center',
            align: 'left',
            renderCell: onStatusColumnRender
        }];
    }, [
        onAssignedColumnRender, onPriorityColumnRender, onStatusColumnRender,
        onTitleOrCauseOrIdColumnRender, t
    ]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams, row: any) => {
        setIssue(row);
        setDialogToggle(true);
    }, []);

    return (
        <>
            <BaseCrudGrid
                gridId='issue-business'
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClick}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add task')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit task')}
                editActionBtnLabel={t('EDIT')}
                editActionBtnDisabled={editActionBtnDisabled}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete task(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
                actionBtns={actionBtns}
                onSelectionModelChange={onSelectionModelChange}
                onRowDoubleClick={onRowDoubleClickHandler}
            />

            {dialogToggle &&
                <IssueBusinessDialog
                    open={dialogToggle}
                    type={type}
                    businessItemId={businessItemId}
                    issue={issue}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }

            {resolveDialogToggle && issue &&
                <IssueResolveDialog
                    open={resolveDialogToggle}
                    issueId={issue.uuid}
                    idno={issue.idno}
                    onCloseBtnClick={closeResolveDialogHandler}
                    onSubmitBtnClick={onSubmitResolveDialogHandler}
                />
            }
        </>
    );
}
export default IssueBusinessGrid;