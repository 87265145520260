import { Autocomplete, AutocompleteRenderInputParams, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../../helpers/generalHelper";
import { ILoadChargeTypeResponseDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";

const buildRequiresProofValue = (value: boolean): string => {
    return `Requires proof: ${value ? 'YES' : 'NO'}`;
};

interface IProps {
    label?: string;
    required?: boolean;
    value?: string;
    disabled?: boolean;
    size?: 'medium' | 'small';
    refresh?: boolean;
    onInit?: (value: ILoadChargeTypeResponseDto | null) => void;
    onChange?: (newValue: ILoadChargeTypeResponseDto | null) => void;
}
const LoadChargeTypeAutocomplete = (props: IProps) => {
    const {
        size = 'medium', disabled, label = 'Type', required = false, onChange,
        onInit, value, refresh
    } = props;

    const [data, setData] = useState<ILoadChargeTypeResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<ILoadChargeTypeResponseDto | null>(null);

    useEffect(() => {
        setData([]);
        setSelectedValue(null);
        (async () => {
            const [, response] = await LoadService.fetchChargeTypesAutocomplete();
            if (response) {
                setData(response.data.body);
            }
        })();
    }, [refresh]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: ILoadChargeTypeResponseDto | null = data.find((item) => item.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }

            if (onInit) {
                onInit(selectedValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, data, selectedValue]);

    const onChangeHandler = useCallback((event: any, newValue: ILoadChargeTypeResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: ILoadChargeTypeResponseDto) => {
        return (
            <div {...props} key={item.uuid}>
                <Typography variant='body2'>{item.name} | {buildRequiresProofValue(item.requiresProof)}</Typography>
            </div>
        );
    }, []);

    const onBuildOptionLabel = useCallback((item: ILoadChargeTypeResponseDto) => {
        return `${item.name} | ${buildRequiresProofValue(item.requiresProof)}`;
    }, []);

    const onBuildOptionKey = useCallback((item: ILoadChargeTypeResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    return (
        <Autocomplete
            fullWidth
            size={size}
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
            disabled={disabled}
        />
    );
}
export default LoadChargeTypeAutocomplete;