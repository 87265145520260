import { IUserProfileRequestDto } from "../models/UserProfileModels";
import { API } from "./APIService";

class UserProfileService {
    public static fetch = async (): Promise<any> => {
        const [error, response] = await API('GET', "userProfile");
        return [error, response];
    };

    public static update = async (data: IUserProfileRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', "userProfile", JSON.stringify(data));
        return [error, response];
    };
}
export default UserProfileService;