import { Box, Paper } from "@mui/material";
import LoadUsersGrid from "./LoadUsersGrid";

interface IProps {
    loadId: string
}
const LoadUsersStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px', height: 300 }}>
                <LoadUsersGrid
                    loadId={props.loadId}
                />
            </Box>
        </Paper>
    );
}
export default LoadUsersStep;