import { Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDateAndTimeFormat, getDateFormat } from "../../../../helpers/dateHelper";
import { isEmpty } from "../../../../helpers/generalHelper";
import { textColorGreen, textColorRed } from "../../../../helpers/gridHelper";
import { currencyFormat } from "../../../../helpers/NumberHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { useUser } from "../../../../hooks/useUser";
import { IFilter } from "../../../../models/CommonModels";
import { EUserPayType, IUserPayResponseDto } from "../../../../models/UserModels";
import UserService from "../../../../services/UserService";
import { RootState } from "../../../../store/store";
import BaseCrudGrid from "../../../Base/BaseCrudGridComponent/BaseCrudGrid";
import UserPaysDialog from "./UserPaysDialog";

interface IProps {
    userId: string;
}
const UserPaysGrid = (props: IProps) => {
    const { userId } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.userSlice.paysGrid);
    const { paysGridRefresh } = useUser();

    const [dialogToggle, setDialogToggle] = useState(false);
    const [pay, setPay] = useState<IUserPayResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return UserService.fetchPays(userId, filter);
    }, [userId]);

    const onAddActionBtnClick = useCallback(() => {
        setPay(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClick = useCallback((pay: IUserPayResponseDto) => {
        setPay(pay);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await UserService.removePay(userId, rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`User pay${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    paysGridRefresh();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, userId]);

    const onTypeColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: EUserPayType = params.value as unknown as EUserPayType;

        let shortValue: string = '';
        switch (value) {
            case EUserPayType.ONE_TIME_BONUS: shortValue = 'ONE TIME BONUS'; break;
            case EUserPayType.ONE_TIME_DEDUCTION: shortValue = 'ONE TIME DEDUC.'; break;
            case EUserPayType.RECURRING_PLUS: shortValue = 'RECUR. +'; break;
            case EUserPayType.RECURRING_MINUS: shortValue = 'RECUR. -'; break;
        };

        const colorRed: boolean = EUserPayType.RECURRING_MINUS === value
            || EUserPayType.ONE_TIME_DEDUCTION === value;

        return (
            <Tooltip title={value.toString().replaceAll('_', ' ')}>
                <span style={colorRed ? textColorRed : textColorGreen}>
                    {shortValue}
                </span>
            </Tooltip>
        );
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onAmountColumnRender = useCallback((value: number) => {
        return currencyFormat(value)
    }, []);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onDateColumnRender = useCallback((params: GridRenderCellParams) => {
        const startDate: string = getDateFormat(params.row.startDate, dateFormat);
        const endDate: number = params.row.endDate;
        const endDateFormated: string | undefined = endDate ? getDateFormat(endDate, dateFormat) : undefined;

        let date: string = startDate;
        if (endDateFormated) {
            date += ` - ${endDateFormated}`;
        }

        return (
            <Tooltip title={date} placement={"bottom"}>
                <Typography variant='caption'>{date}</Typography>
            </Tooltip>
        );
    }, [dateFormat]);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'type',
            headerName: t('TYPE'),
            width: 150,
            headerAlign: 'center',
            hideable: false,
            renderCell: onTypeColumnRender
        }, {
            field: 'frequency',
            headerName: t('FREQ.'),
            width: 90,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'note',
            headerName: t('NOTES'),
            flex: 1,
            minWidth: 150,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: "date",
            headerName: t('DATE'),
            width: 180,
            headerAlign: 'center',
            renderCell: onDateColumnRender
        }, {
            field: 'amount',
            headerName: t('AMOUNT'),
            width: 110,
            headerAlign: 'center',
            type: 'number',
            valueFormatter: onAmountColumnRender
        }, {
            field: 'created',
            headerName: t('CREATED'),
            width: 130,
            headerAlign: 'center',
            renderCell: onCreatedColumnRender
        }];
    }, [
        onAmountColumnRender, onCreatedColumnRender, onTooltipColumnRender,
        onTypeColumnRender, onDateColumnRender, t
    ]);

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setPay(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        paysGridRefresh();
    }, [paysGridRefresh]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams, row: any) => {
        setPay(row);
        setDialogToggle(true);
    }, []);

    return (
        <>
            <BaseCrudGrid
                gridId='user-pays'
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClick}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add item')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
                onRowDoubleClick={onRowDoubleClickHandler}
            />

            {dialogToggle &&
                <UserPaysDialog
                    open={dialogToggle}
                    userId={userId}
                    pay={pay}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default UserPaysGrid;