import { SelectChangeEvent, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../../helpers/textHelper";
import { useLoad } from "../../../../hooks/useLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { ILoadTransportRequestDto, ILoadTransportResponseDto } from "../../../../models/LoadModels";
import { EVehicleType, ITransportAutocompleteResponseDto } from "../../../../models/TransportModels";
import LoadService from "../../../../services/LoadService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import TransportTypeAutocomplete from "../../../TransportModule/TransportTypeAutocomplete";
import TransportTypeSelect from "../../../TransportModule/TransportTypeSelect";

interface IProps {
    loadId: string;
    entity?: ILoadTransportResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const LoadTransportsDialog = (props: IProps) => {
    const { open, loadId, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useLoad();
    const formId: string = 'load-transports-form';

    const [type, setType] = useState<EVehicleType>(entity ? entity.type : EVehicleType.NONE);
    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, handleSubmit, formState: { isDirty, isValid } } = useForm<ILoadTransportRequestDto>({
        defaultValues: {
            transportId: entity ? entity.transport.uuid : ''
        }
    });

    const updateData = useCallback((uuid: string, transportId: string) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateTransport(loadId, uuid, transportId);
            if (response) {
                displayNotification({ message: t('Load transport was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t]);

    const createData = useCallback((transportId: string) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.createTransport(loadId, transportId);
            if (response) {
                displayNotification({ message: t('Load transport was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                stepRefresh();
                gridRefresh();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t]);

    const onSubmit = useCallback((data: ILoadTransportRequestDto) => {
        if (entity) {
            updateData(entity.uuid, data.transportId);
        } else {
            createData(data.transportId);
        }
    }, [entity, createData, updateData]);

    const validateTransportField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    register('transportId', { validate: validateTransportField });
    const onTransportChangeHandler = useCallback((value: ITransportAutocompleteResponseDto | null) => {
        setValue('transportId', value ? value.uuid : '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('transportId', '', {
            shouldValidate: true,
            shouldDirty: true
        });
        setType(event.target.value as EVehicleType);
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <TransportTypeSelect
                        required
                        label={t('TYPE')}
                        value={type}
                        onChange={onTypeChangeHandler}
                    />

                    <TransportTypeAutocomplete
                        required
                        label={t('TRANSPORT')}
                        type={type}
                        value={getValues('transportId')}
                        disableInactiveItems
                        onChange={onTransportChangeHandler}
                    />
                </Stack>
            </form>
        );
    }, [
        getValues, handleSubmit, onSubmit, onTransportChangeHandler,
        onTypeChangeHandler, t, type
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${entity ? 'EDIT' : 'CREATE'} LOAD TRANSPORT`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default LoadTransportsDialog;