import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Paper, SelectChangeEvent, Stack, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { usePreference } from "../../../../hooks/usePreference";
import { EMeasureConsumption, EMeasureDistance, EMeasureEnginePower, EMeasureEngineVolume, EMeasureMass, EMeasureSize, ETemperature, IGlobalPreference, IGlobalPreferenceMeasureRequestDto } from "../../../../models/PreferenceModels";
import GlobalPreferenceService from "../../../../services/GlobalPreferenceService";
import { RootState } from "../../../../store/store";
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";
import MeasureConsumptionSelect from '../../../Base/MeasureComponent/MeasureConsumptionSelect';
import MeasureDistanceSelect from '../../../Base/MeasureComponent/MeasureDistanceSelect';
import MeasureEnginePowerSelect from '../../../Base/MeasureComponent/MeasureEnginePowerSelect';
import MeasureEngineVolumeSelect from '../../../Base/MeasureComponent/MeasureEngineVolumeSelect';
import MeasureMassSelect from '../../../Base/MeasureComponent/MeasureMassSelect';
import MeasureSizeSelect from '../../../Base/MeasureComponent/MeasureSizeSelect';
import MeasureTemperatureSelect from '../../../Base/MeasureComponent/MeasureTemperatureSelect';

const formId: string = 'global-preference-measure-form';
const GlobalPreferenceMeasure = () => {
    const { t } = useTranslation();

    const { size, distance, consumption, mass, engineVolume, enginePower, temperature } = useSelector((state: RootState) => state.preferenceSlice.global);

    const { register, setValue, getValues, reset, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<IGlobalPreferenceMeasureRequestDto>({
        defaultValues: {
            size: size,
            distance: distance,
            consumption: consumption,
            mass: mass,
            engineVolume: engineVolume,
            enginePower: enginePower,
            temperature: temperature
        }
    });

    const { displayNotification } = useNotification();
    const { updateGlobalPreference } = usePreference();
    const [loading, setLoading] = useState(false);

    const validateIsBlank = useCallback((value: string) => {
        return !isBlank(value);
    }, []);

    const fetchGlobalPreference = useCallback(() => {
        (async () => {
            const [, response] = await GlobalPreferenceService.fetch();
            if (response) {
                const preferences: IGlobalPreference = response.data.body;
                updateGlobalPreference({ ...preferences, loaded: true });

                reset({
                    size: preferences.size,
                    distance: preferences.distance,
                    consumption: preferences.consumption,
                    mass: preferences.mass,
                    engineVolume: preferences.engineVolume,
                    enginePower: preferences.enginePower,
                    temperature: preferences.temperature
                });
            }
        })();
    }, [reset, updateGlobalPreference]);

    const onSubmit = useCallback((data: IGlobalPreferenceMeasureRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await GlobalPreferenceService.updateMeasure(data);
            if (response) {
                displayNotification({ message: t('Preferences was successfully updated.') });
                fetchGlobalPreference();
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchGlobalPreference, t]);

    register('size', { validate: validateIsBlank });
    const onSizeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('size', event.target.value as EMeasureSize, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('distance', { validate: validateIsBlank });
    const onDistanceChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('distance', event.target.value as EMeasureDistance, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('consumption', { validate: validateIsBlank });
    const onConsumptionChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('consumption', event.target.value as EMeasureConsumption, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('mass', { validate: validateIsBlank });
    const onMassChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('mass', event.target.value as EMeasureMass, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('engineVolume', { validate: validateIsBlank });
    const onEngineVolumeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('engineVolume', event.target.value as EMeasureEngineVolume, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('enginePower', { validate: validateIsBlank });
    const onEnginePowerChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('enginePower', event.target.value as EMeasureEnginePower, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('temperature', { validate: validateIsBlank });
    const onTemperatureChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('temperature', event.target.value as ETemperature, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    return (
        <Paper sx={{ marginTop: '10px' }}>
            <BackdropProgress open={loading || false}>
                <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ padding: '10px' }}>
                        <Stack spacing={2}>
                            <Stack direction='row' spacing={2}>
                                <MeasureSizeSelect
                                    required
                                    label={t('SIZE')}
                                    value={getValues('size')}
                                    onChange={onSizeChangeHandler}
                                />

                                <MeasureDistanceSelect
                                    required
                                    label={t('DISTANCE')}
                                    value={getValues('distance')}
                                    onChange={onDistanceChangeHandler}
                                />

                                <MeasureConsumptionSelect
                                    required
                                    label={t('CONSUMPTION')}
                                    value={getValues('consumption')}
                                    onChange={onConsumptionChangeHandler}
                                />
                            </Stack>

                            <Stack direction='row' spacing={2}>
                                <MeasureMassSelect
                                    required
                                    label={t('MASS')}
                                    value={getValues('mass')}
                                    onChange={onMassChangeHandler}
                                />

                                <MeasureEngineVolumeSelect
                                    required
                                    label={t('ENGINE VOLUME')}
                                    value={getValues('engineVolume')}
                                    onChange={onEngineVolumeChangeHandler}
                                />

                                <MeasureEnginePowerSelect
                                    required
                                    label={t('ENGINE POWER')}
                                    value={getValues('enginePower')}
                                    onChange={onEnginePowerChangeHandler}
                                />
                            </Stack>

                            <Stack direction='row' spacing={2}>
                                <MeasureTemperatureSelect
                                    required
                                    label={t('TEMPERATURE')}
                                    value={getValues('temperature')}
                                    onChange={onTemperatureChangeHandler}
                                />
                            </Stack>
                        </Stack>

                        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Tooltip title={t('SAVE')} arrow>
                                <span>
                                    <Button
                                        form={formId}
                                        type='submit'
                                        variant='contained'
                                        disabled={!isValid || !isDirty}
                                        size='small'
                                        startIcon={<SaveAsIcon />}
                                    >
                                        {t('SAVE')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>
                </form>
            </BackdropProgress>
        </Paper>
    );
}
export default GlobalPreferenceMeasure;