import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { IAddressShortResponseDto } from "./AddressModels";

export enum ELanguage {
    EN = 'en',
    RU = 'ru'
}

export enum ETimeFormat {
    h_12 = 'hh:mm a',
    h_24 = 'HH:MM'
}

export enum EDateFormat {
    yyyy_mm_dd = 'YYYY-MM-DD',
    mm_dd_yyyy = 'MM-DD-YYYY',
    dd_mm_yyyy = 'DD-MM-YYYY'
}

export enum EMeasureSize {
    INCH = 'in',
    FOOT = 'ft',
    CENTIMETER = 'cm',
    MILLIMETER = 'mm'
}

export enum EMeasureDistance {
    NAUTICAL_MILE = 'nm',
    MILE = 'mi',
    KILOMETER = 'km',
    METER = 'm'
}

export enum EMeasureConsumption {
    MILES_PER_GALLON = 'MPG',
    LITRES_100_KM = 'L/100km'
}

export enum EMeasureMass {
    POUND = 'lb',
    KILOGRAM = 'kg',
    TON = 't'
}

export enum ETemperature {
    CELSIUS = 'C',
    FAHRENHEIT = 'F'
}

export enum EMeasureEngineVolume {
    LITER = 'l'
}

export enum EMeasureEnginePower {
    HORSE_POWER = 'hp'
}

export interface IUserPreference {
    language: ELanguage;
    dateFormat: EDateFormat;
    timeFormat: ETimeFormat;
    notificationPushEnabled: boolean;
    notificationEmailEnabled: boolean;
    loaded?: boolean;
}

export interface IGridPreference {
    id: string;
    columnVisibility: GridColumnVisibilityModel;
}

export interface IAppPreference {
    grids: IGridPreference[];
}

export interface IGlobalPreference {
    size: EMeasureSize;
    distance: EMeasureDistance;
    consumption: EMeasureConsumption;
    mass: EMeasureMass;
    engineVolume: EMeasureEngineVolume;
    enginePower: EMeasureEnginePower;
    temperature: ETemperature;
    companyName: string;
    companyLogo: string;
    companyAddress: IAddressShortResponseDto;
    companyPhone: string;
    companyEmail: string;
    companyFax: string;
    invoiceRemitPaymentEnabled: boolean;
    invoiceRemitPaymentName: string;
    invoiceRemitPaymentAddress: IAddressShortResponseDto;
    dateFormat: EDateFormat;
    timeFormat: ETimeFormat;
    loaded?: boolean;
}

export interface IGlobalPreferenceCompanyRequestDto {
    name: string;
    addressId: string;
    phone: string;
    email: string;
    fax: string;
}

export interface IGlobalPreferenceMeasureRequestDto {
    size: EMeasureSize;
    distance: EMeasureDistance;
    consumption: EMeasureConsumption;
    mass: EMeasureMass;
    engineVolume: EMeasureEngineVolume;
    enginePower: EMeasureEnginePower;
    temperature: ETemperature;
}

export interface IGlobalPreferenceInvoiceRequestDto {
    remitPaymentEnabled: boolean;
    remitPaymentName: string;
    remitPaymentAddressId: string;
}

export interface IPreferenceDateTimeRequestDto {
    dateFormat: EDateFormat;
    timeFormat: ETimeFormat;
}

export interface IUserPreferenceNotificationRequestDto {
    pushEnabled: boolean;
    emailEnabled: boolean;
}

export interface ISystemPreference {
    hostWithDomain: string;
    corporateEmailHostDomain: string;
    googleMapsCommonServiceApiKey: string;
    googleMapsIdKey: string;
    loaded?: boolean;
}

export interface IPreferenceLanguageRequestDto {
    language: ELanguage;
}