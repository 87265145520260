import { Box, Paper } from "@mui/material";
import LoadPaysGrid from "./LoadPaysGrid";

interface IProps {
    loadId: string,
}
const LoadPaysStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px', height: 300 }}>
                <LoadPaysGrid loadId={props.loadId} />
            </Box>
        </Paper>
    );
}
export default LoadPaysStep;