import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Button, SelectChangeEvent, Stack, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { ELoadCopyItemType, ELoadItemDirection, ILoadCopyItemAutocompleteResponseDto } from "../../../models/LoadModels";
import { RootState } from '../../../store/store';
import BaseDialog from "../../Base/BaseDialogComponent/BaseDialog";
import LoadCopyItemsAutocomplete from './LoadCopyItemsAutocomplete';
import LoadCopyItemsTypeSelect from './LoadCopyItemsTypeSelect';

interface IProps {
    open: boolean;

    loadId: string;
    direction: ELoadItemDirection;

    onCloseBtnClick: () => void;
    onSubmitBtnClick: (entityId: string) => void;
}
const LoadCopyItemsDialog = (props: IProps) => {
    const { open, loadId, direction, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { mass } = useSelector((state: RootState) => state.preferenceSlice.global);

    const [type, setType] = useState<ELoadCopyItemType>(ELoadCopyItemType.LAST_CREATED_ITEM);
    const [copyEntityId, setCopyEntityId] = useState<string | undefined>();

    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setCopyEntityId(undefined);
        setType(event.target.value as ELoadCopyItemType);
    }, []);

    const onAutocompleteChangeHandler = useCallback((item: ILoadCopyItemAutocompleteResponseDto | null) => {
        setCopyEntityId(item ? item.uuid : undefined);
    }, []);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={2}>
                <LoadCopyItemsTypeSelect
                    label={t('FROM')}
                    required
                    value={type}
                    onChange={onTypeChangeHandler}
                />

                <LoadCopyItemsAutocomplete
                    label={ELoadCopyItemType.LAST_CREATED_ITEM === type ? t('LAST CREATED') : t(type)}
                    required
                    type={type}
                    massType={mass}
                    loadId={loadId}
                    onChange={onAutocompleteChangeHandler}
                />
            </Stack>
        );
    }, [loadId, mass, onAutocompleteChangeHandler, onTypeChangeHandler, t, type]);

    const onCopyBtnClickHandler = useCallback(() => {
        if (copyEntityId) {
            onSubmitBtnClick(copyEntityId);
        }
    }, [copyEntityId, onSubmitBtnClick]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('COPY')} arrow>
                <span>
                    <Button
                        variant='contained'
                        disabled={copyEntityId === undefined}
                        size='small'
                        startIcon={<SaveAsIcon />}
                        onClick={onCopyBtnClickHandler}
                    >
                        {t('DUPLICATE')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [copyEntityId, onCopyBtnClickHandler, t]);

    return (
        <BaseDialog
            open={open}
            title={t(`DUPLICATE ${direction}`)}
            maxWidth={'xs'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default LoadCopyItemsDialog;