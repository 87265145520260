import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useState } from "react";
import { ELoadCopyItemType } from "../../../models/LoadModels";

interface IProps {
    label?: string;
    value?: ELoadCopyItemType;
    required?: boolean;
    onChange?: (event: SelectChangeEvent) => void;
}
const LoadCopyItemsTypeSelect = (props: IProps) => {
    const { label = 'Item Type', required = false, onChange } = props;
    const [selectedValue, setSelectedValue] = useState<string>(props.value ? props.value.toString() : '');

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormControl fullWidth required={required}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem key={ELoadCopyItemType.LAST_CREATED_ITEM} value={ELoadCopyItemType.LAST_CREATED_ITEM}>{'LAST CREATED'}</MenuItem>
                <MenuItem key={ELoadCopyItemType.SHIPPER} value={ELoadCopyItemType.SHIPPER}>{'SHIPPER'}</MenuItem>
                <MenuItem key={ELoadCopyItemType.CONSIGNEE} value={ELoadCopyItemType.CONSIGNEE}>{'CONSIGNEE'}</MenuItem>
            </Select>
        </FormControl>
    );
}
export default LoadCopyItemsTypeSelect;