import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { getDateFormatAdapter, getDateFormatBEAdapter } from "../../../helpers/dateHelper";
import { EDateFormat } from "../../../models/PreferenceModels";

const dateNow: Date = new Date();
const getDateAsStr = (date: Date, format: EDateFormat): string => {
    return dayjs(date).format(format);
};

interface IProps {
    label?: string;
    required?: boolean;
    value?: EDateFormat;
    onChange?: (value: string) => void;
}
const DateFormatSelect = (props: IProps) => {
    const { label = 'Date format', required = false, onChange } = props;
    const [selectedValue, setSelectedValue] = useState<string>(props.value ? getDateFormatAdapter(props.value.toString()) : '');

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            const dateFormat: string = getDateFormatBEAdapter(event.target.value as EDateFormat);
            onChange(dateFormat);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormControl fullWidth required={required}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem key={EDateFormat.yyyy_mm_dd} value={EDateFormat.yyyy_mm_dd}>{`${EDateFormat.yyyy_mm_dd} (${getDateAsStr(dateNow, EDateFormat.yyyy_mm_dd)})`}</MenuItem>
                <MenuItem key={EDateFormat.mm_dd_yyyy} value={EDateFormat.mm_dd_yyyy}>{`${EDateFormat.mm_dd_yyyy} (${getDateAsStr(dateNow, EDateFormat.mm_dd_yyyy)})`}</MenuItem>
                <MenuItem key={EDateFormat.dd_mm_yyyy} value={EDateFormat.dd_mm_yyyy}>{`${EDateFormat.dd_mm_yyyy} (${getDateAsStr(dateNow, EDateFormat.dd_mm_yyyy)})`}</MenuItem>
            </Select>
        </FormControl>
    );
}
export default DateFormatSelect;