import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationUrl, removeEmptyFields } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { emailRegex } from "../../../helpers/ValidatorHelper";
import { useCustomer } from "../../../hooks/useCustomer";
import { useNotification } from "../../../hooks/useNotification";
import { IAddressShortResponseDto } from "../../../models/AddressModels";
import { ICustomerOverviewResponseDto, ICustomerRequestDto } from "../../../models/CustomerModels";
import CustomerService from "../../../services/CustomerService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import Address from "../../Base/GeolocationComponent/Address";
import IdnoGenerator from "../../Base/IdnoGeneratorComponent/IdnoGenerator";

interface IProps {
    statusActive?: boolean;
    open: boolean;
    entity?: ICustomerOverviewResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: (entityId?: string) => void;
}
const CustomerDialog = (props: IProps) => {
    const { statusActive, open, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useCustomer();
    const formId: string = 'customer-form';

    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, setError, clearErrors, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<ICustomerRequestDto>({
        defaultValues: {
            name: entity ? entity.name : '',
            idno: entity ? entity.idno : '',
            addressId: entity ? entity.address.uuid : '',
            email: '',
            phone: '',
            contact1: undefined,
            contact2: undefined,
            contact3: undefined,
            statusActive: statusActive ? statusActive : false
        }
    });

    const onCheckIdnoWithServerApi = useCallback((value: string) => {
        return CustomerService.isIdnoAvailable(value);
    }, []);

    const updateData = useCallback((uuid: string, data: ICustomerRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await CustomerService.updateOverview(uuid, data);
            if (response) {
                displayNotification({ message: t('Customer was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: ICustomerRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await CustomerService.create(data);
            if (response) {
                displayNotification({ message: t('Customer was successfully created.') });

                const uuid = response.data.response.entityId;
                if (statusActive && onSubmitBtnClick) {
                    onSubmitBtnClick(uuid);
                } else {
                    const newUrl: string = normalizeNavigationUrl(uuid);
                    navigate(`/${newUrl}`);
                    gridRefresh();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: ICustomerRequestDto) => {
        const normalisedData: ICustomerRequestDto = removeEmptyFields(data) as unknown as ICustomerRequestDto;
        if (entity) {
            updateData(entity.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [createData, entity, updateData]);

    const validateForIsBlankField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    const onNameChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setValue('name', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('idno');
    const onChangeIdnoHandler = useCallback((value: string, error?: string) => {
        if (error) {
            setError('idno', { message: error });
        } else {
            clearErrors('idno');
        }

        setValue('idno', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [clearErrors, setError, setValue]);

    register('addressId', { validate: validateForIsBlankField });
    const onChangeAddressHandler = useCallback((address: IAddressShortResponseDto) => {
        setValue('addressId', address.uuid, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateEmailField = useCallback((value?: string) => {
        if (value && !emailRegex.test(value)) {
            const message: string = t('Invalid email address.');
            setError('email', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const onEmailChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setValue('email', value ? value.trim() : '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validatePhoneField = useCallback((value?: string) => {
        if (value && isBlank(value)) {
            const message: string = t('Invalid phone.');
            setError('phone', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const onPhoneChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setValue('phone', value ? value.trim() : '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('statusActive');
    const onChangeStatusHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue('statusActive', event.target.checked, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3} direction='column'>
                    <Stack spacing={3} direction='row'>
                        <Stack width={'100%'} spacing={3} direction='column'>
                            <TextField
                                {...register('name', {
                                    required: true,
                                    validate: validateForIsBlankField
                                })}
                                required
                                autoComplete='off'
                                label={t('NAME')}
                                slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onNameChangeHandler}
                            />
                        </Stack>

                        <Stack width={'100%'} spacing={3} direction='column'>
                            <IdnoGenerator
                                value={getValues('idno')}
                                label={t('ID#')}
                                generateBtnTooltip={t('GENERATE')}
                                errorMessage={t('The value is used. Choose another value.')}
                                checkWithServerApi={onCheckIdnoWithServerApi}
                                onChange={onChangeIdnoHandler}
                            />
                        </Stack>
                    </Stack>

                    <Address
                        value={entity?.address}
                        required
                        onChange={onChangeAddressHandler}
                    />

                    {!entity &&
                        <Stack spacing={3} direction='row'>
                            <Stack width={'100%'} spacing={3} direction='column'>
                                <TextField
                                    {...register('email', { validate: validateEmailField })}
                                    autoComplete='off'
                                    label={t('EMAIL')}
                                    type='email'
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                    onChange={onEmailChangeHandler}
                                />

                                <TextField
                                    {...register('contact1.name')}
                                    autoComplete='off'
                                    label={t('CONTACT NAME 1')}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                                />

                                <TextField
                                    {...register('contact2.name')}
                                    autoComplete='off'
                                    label={t('CONTACT NAME 2')}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                                />

                                <TextField
                                    {...register('contact3.name')}
                                    autoComplete='off'
                                    label={t('CONTACT NAME 3')}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                                />
                            </Stack>

                            <Stack width={'100%'} spacing={3} direction='column'>
                                <TextField
                                    {...register('phone', { validate: validatePhoneField })}
                                    autoComplete='off'
                                    label={t('PHONE')}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 20 } }}
                                    error={!!errors.phone}
                                    helperText={errors.phone?.message}
                                    onChange={onPhoneChangeHandler}
                                />

                                <TextField
                                    {...register('contact1.value')}
                                    autoComplete='off'
                                    label={t('CONTACT VALUE 1')}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                                />

                                <TextField
                                    {...register('contact2.value')}
                                    autoComplete='off'
                                    label={t('CONTACT VALUE 2')}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                                />

                                <TextField
                                    {...register('contact3.value')}
                                    autoComplete='off'
                                    label={t('CONTACT VALUE 3')}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                                />
                            </Stack>
                        </Stack>
                    }

                    {!entity &&
                        <FormControlLabel
                            control={<Checkbox
                                checked={getValues('statusActive')}
                                onChange={onChangeStatusHandler}
                                disabled={statusActive}
                            />}
                            label={t('ACTIVATE')}
                        />
                    }
                </Stack>
            </form>
        );
    }, [
        handleSubmit, onSubmit, register, validateForIsBlankField, t,
        errors.name, errors.email, errors.phone, onNameChangeHandler,
        getValues, onCheckIdnoWithServerApi, onChangeIdnoHandler,
        entity, onChangeAddressHandler, validateEmailField,
        onEmailChangeHandler, validatePhoneField, onPhoneChangeHandler,
        onChangeStatusHandler, statusActive
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${entity ? 'EDIT' : 'CREATE'} CUSTOMER`)}
            maxWidth={'sm'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default CustomerDialog;