import { EPriority, IIdValue } from "./CommonModels";
import { ILoadShortResponseDto } from "./LoadModels";
import { ITransportShortResponseDto } from "./TransportModels";

export enum EIssueDocumentType {
    NONE,
    DOCUMENT = 'DOCUMENT'
}

export interface IIssueDocumentRequestDto {
    type: EIssueDocumentType;
    caption?: string;
}

export enum EIssueType {
    NONE,
    STANDARD = 'STANDARD',
    TRANSPORT = 'TRANSPORT',
    LOAD = 'LOAD'
}

export enum EIssueStatus {
    NONE,
    OPEN = 'OPEN',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
    UNRESOLVED = 'UNRESOLVED'
}

export interface IIssueRequestDto {
    title: string;
    cause: string;
    priority: EPriority;
    assignedOnId?: string;
    type: EIssueType;
    businessItemId?: string;
}

export interface IIssueResponseDto {
    uuid: string;
    idno: number;
    type: EIssueType;
    title: string;
    cause: string;
    priority: EPriority;
    assignedOn?: IIdValue;
    assignedDate?: number;
    executedBy?: string;
    executedDate?: number;
    conclusion?: string;
    status: EIssueStatus;
    createdBy: string;
    createdDate: number;
    updatedBy?: string;
    updatedDate?: number;
}

export interface IIssueBusinessResponseDto {
    uuid: string;
    idno: number;
    type: EIssueType;
    title: string;
    cause: string;
    priority: EPriority;
    assignedOn?: IIdValue;
    assignedDate?: number;
    executedBy?: string;
    executedDate?: number;
    conclusion?: string;
    status: EIssueStatus;
}

export interface IIssueResolveRequest {
    conclusion: string;
    status: EIssueStatus;
}

export interface IIssueShortResponseDto {
    uuid: string;
    idno: string;
    type: EIssueType;
    priority: EPriority;
    assignedOn?: IIdValue;
    status: EIssueStatus;
    readonly: boolean;
}

export interface IIssueLoadCoverFaceResponseDto {
    uuid: string;
    idno: string;
    type: EIssueType;
    priority: EPriority;
    assignedOn?: IIdValue;
    status: EIssueStatus;
    readonly: boolean;
    load: ILoadShortResponseDto;
}

export interface IIssueTransportCoverFaceResponseDto {
    uuid: string;
    idno: string;
    type: EIssueType;
    priority: EPriority;
    assignedOn?: IIdValue;
    status: EIssueStatus;
    readonly: boolean;
    transport: ITransportShortResponseDto;
}

export interface IIssueOverviewResponseDto {
    uuid: string;
    idno: number;
    type: EIssueType;
    title: string;
    cause: string;
    priority: EPriority;
    assignedOn?: IIdValue;
    businessItemId?: string;
}

export interface IIssueAssignedOnRequestDto {
    assignedOnId?: string;
}

export interface IIssueAssignedOnResponseDto {
    uuid: string;
    idno: number;
    assignedOn?: IIdValue;
}