import { useDispatch } from "react-redux";
import { UserProfileActions } from "../store/reducers/userProfileSlice";
import { IUserProfileResponseDto } from "../models/UserProfileModels";

export const useUserProfile = () => {
    const dispatch = useDispatch();

    const update = (profile: IUserProfileResponseDto) => {
        dispatch(UserProfileActions.update(profile));
    };

    return { update } as const;
};