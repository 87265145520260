import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CategoryIcon from '@mui/icons-material/Category';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Box, Toolbar, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router';
import { IMenuAppBarBtn } from '../../models/CommonModels';
import ChatBadgeBtn from './ChatBadgeBtn';
import DrawerMenu from './DrawerMenu';
import FavoriteBadgeBtn from './FavoriteBadgeBtn';
import HorizontalMenu from './HorizontalMenu';
import NotificationBadgeBtn from './NotificationBadgeBtn';
import ProfileMenuBtn from './ProfileMenuBtn';

const items: IMenuAppBarBtn[] = [{
    label: 'Dashboard',
    tooltip: 'Dashboard management',
    path: '/',
    icon: <DashboardIcon />,
    disabled: true
}, {
    label: 'Settlements',
    tooltip: 'Settlements management',
    path: '/settlements',
    icon: <AccountBalanceIcon />,
    disabled: true
}, {
    label: 'Invoices',
    tooltip: 'Invoices management',
    path: '/invoices',
    icon: <RequestPageIcon />
}, {
    label: 'Loads',
    tooltip: 'Loads management',
    path: '/loads',
    icon: <CategoryIcon />
}, {
    label: 'Tasks',
    tooltip: 'Tasks management',
    path: '/tasks',
    icon: <PlaylistAddCheckIcon />
}, {
    label: 'Customers',
    tooltip: 'Customers management',
    path: '/customers',
    icon: <BusinessCenterIcon />
}, {
    label: 'Warehouses',
    tooltip: 'Warehouses management',
    path: '/warehouses',
    icon: <WarehouseIcon />
}, {
    label: 'Transports',
    tooltip: 'Transports management',
    path: '/transports',
    icon: <LocalShippingIcon />
}, {
    label: 'Users',
    tooltip: 'Users management',
    path: '/users',
    icon: <PeopleAltIcon />
}, {
    label: 'Audit',
    tooltip: 'Audit management',
    path: '/audit',
    icon: <SettingsBackupRestoreIcon />,
    disabled: true
}];

const MenuAppBar = () => {
    const [activePath, setActivePath] = useState<string>('');
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const showHorizontalMenu = useMediaQuery('(min-width:1440px)');

    useEffect(() => {
        const path: Array<string> = (location.split('/').filter((element) => element !== ''));
        const pathItem: string = path[0];
        setActivePath(`/${pathItem !== '' ? pathItem : ''}`);
    }, [location]);

    const onClickHandler = useCallback((path: string) => {
        navigate(path);
    }, [navigate]);

    return (
        <AppBar position='static'>
            <Toolbar sx={{ marginLeft: 2, marginRight: 2 }}>
                {showHorizontalMenu
                    ? <HorizontalMenu items={items} activePath={activePath} onClick={onClickHandler} />
                    : <DrawerMenu items={items} activePath={activePath} onClick={onClickHandler} />
                }

                <Box flexGrow={1} />

                <Box>
                    <ChatBadgeBtn disabled />
                    <NotificationBadgeBtn disabled />
                    <FavoriteBadgeBtn disabled />
                </Box>

                <Box marginLeft={2}>
                    <ProfileMenuBtn />
                </Box>
            </Toolbar>
        </AppBar>
    );
}
export default MenuAppBar;