import dayjs from "dayjs";
import { EDateFormat, ETimeFormat } from "../models/PreferenceModels";

export const dateFormatAdapter: Map<string, EDateFormat> = new Map([
    ['yyyy-MM-dd', EDateFormat.yyyy_mm_dd],
    ['MM-dd-yyyy', EDateFormat.mm_dd_yyyy],
    ['dd-MM-yyyy', EDateFormat.dd_mm_yyyy]
]);

export const dateFormatBEAdapter: Map<EDateFormat, string> = new Map([
    [EDateFormat.yyyy_mm_dd, 'yyyy-MM-dd'],
    [EDateFormat.mm_dd_yyyy, 'MM-dd-yyyy'],
    [EDateFormat.dd_mm_yyyy, 'dd-MM-yyyy']
]);

export const timeFormatAdapter: Map<string, ETimeFormat> = new Map([
    ['hh:mm', ETimeFormat.h_24],
    ['hh:mm a', ETimeFormat.h_12]
]);

export const timeFormatBEAdapter: Map<ETimeFormat, string> = new Map([
    [ETimeFormat.h_24, 'HH:MM'],
    [ETimeFormat.h_12, 'hh:mm a']
]);

export const toTimestamp = (dateStr: string): number => {
    return Date.parse(dateStr);
};

export const covertDateStrToTimestampOrUndefined = (dateStr?: string): number | undefined => {
    if (!dateStr) {
        return undefined;
    }
    return Date.parse(dateStr);
};

export const convertTimestampToDateOrUndefined = (timestamp?: number): Date | undefined => {
    if (!timestamp) {
        return undefined;
    }
    return new Date(timestamp);
};

export const convertTimestampToDate = (timestamp: number): Date => {
    return new Date(timestamp);
};

export const convertTimestampToDateWithFormatOrUndefined = (timestamp?: number, format?: EDateFormat): Date | undefined => {
    if (!timestamp) {
        return undefined;
    }
    const date: Date = new Date(timestamp)
    const dateStr = dayjs(date).format(format);
    return dayjs(dateStr).toDate();
}

export const convertTimestampToDateStrWithFormatOrEmpty = (timestamp?: number, format?: EDateFormat): string => {
    if (!timestamp) {
        return '';
    }
    const date: Date = new Date(timestamp)
    return dayjs(date).format(getDateFormatAdapter(format?.toString()));
}

export const dateToYMD = (n: number) => {
    let timestamp = n;
    if (n < 1000) {
        timestamp = 0;
        return '';
    }
    const date = new Date(timestamp);
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

export const dateToHM = (n: number) => {
    let timestamp = n;
    if (n < 1000) {
        timestamp = 0;
        return '';
    }
    const date = new Date(timestamp);
    var h = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();
    return '' + (h <= 9 ? '0' + h : h) + ':' + (m <= 9 ? '0' + m : m) + ':' + (s <= 9 ? '0' + s : s) + ' ';
}

export const dateToYMDHM = (n: number) => {
    let timestamp = n;
    if (n < 1000) {
        timestamp = 0;
        return '';
    }
    const date = new Date(timestamp);
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    var hr = date.getHours();
    var mn = date.getMinutes();
    return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) + 'T' + (hr <= 9 ? '0' + hr : hr) + ':' + (mn <= 9 ? '0' + mn : mn);
}

export const getDateFormat = (timestamp: number | undefined, format: string): string => {
    if (!timestamp) {
        return '';
    }

    let date = new Date(timestamp)
    return dayjs(date).format(getDateFormatAdapter(format));
}

export const getTimeFormat = (time: any, format: ETimeFormat): string => {
    if (!time || (typeof time === 'number' && time <= 0)) {
        return '';
    }

    const timeFormat = getTimeFormatAdapter(format);

    return typeof time === 'number'
        ? dayjs(new Date(time)).format(timeFormat)
        : dayjs(time, 'hh:mm').format(timeFormat);
}

export const getDateAndTimeFormat = (timestamp: number, dateFormat: EDateFormat, timeFormat: ETimeFormat): string => {
    if (!timestamp || timestamp <= 0) {
        return '';
    }

    const date = getDateFormat(timestamp, dateFormat.toString()),
        time = getTimeFormat(timestamp, timeFormat);

    return date + ' ' + time;
}

export const getDateFormatAdapter = (format?: string): EDateFormat => {
    return dateFormatAdapter.get(format || EDateFormat.yyyy_mm_dd) || EDateFormat.yyyy_mm_dd;
}

export const getDateFormatBEAdapter = (format: EDateFormat): string => {
    return dateFormatBEAdapter.get(format) || 'yyyy-MM-dd';
}

export const getTimeFormatAdapter = (format: string): ETimeFormat => {
    return timeFormatAdapter.get(format) || ETimeFormat.h_12;
}

export const getTimeFormatBEAdapter = (format: ETimeFormat): string => {
    return timeFormatBEAdapter.get(format) || 'hh:mm a';
}

export const timeDiference = (n1: number, n2: number) => {
    const date1 = new Date(n1);
    var d1 = date1.getDate();
    var m1 = date1.getMonth() + 1;
    var y1 = date1.getFullYear();
    var hr1 = date1.getHours();
    var min1 = date1.getMinutes();

    const date2 = new Date(n2);
    var d2 = date2.getDate();
    var m2 = date2.getMonth() + 1;
    var y2 = date2.getFullYear();
    var hr2 = date2.getHours();
    var min2 = date2.getMinutes();

    let dy = 0; let dm = 0; let dz = 0;

    dy = y2 - y1;
    if (m2 > m1) { dm = m2 - m1; } else { dm = 12 - Math.abs(m2 - m1); dy = dy - 1 }
    let max = new Date(y2, m2, 0).getDate();
    if (d2 > d1) { dz = d2 - d1; } else { dz = Math.abs(max - Math.abs(d2 - d1)); dm = dm - 1 }

    if (dz === max) { dz = 0; dm++ }
    if (dm === 12) { dm = 0; dy++ }

    let req: string = '';
    if (dy) req += dy + 'y  ';
    if (dm) req += dm + 'm  ';
    if (dz) req += dz + 'd  ';

    if (dy === 0 && dm === 0 && dz === 0) {
        let dhr = hr2 - hr1;
        let dmin = min2 - min1;
        if (dmin < 0) {
            dhr--;
            dmin = 60 + min2 - min1;
        }
        if (dhr) req += dhr + 'h  ';
        if (dmin) req += dmin + 'min  ';
    }

    return req === '' ? '0' : req;
}

export const getTheHotOfDate = (date: number): number => {
    const askedDtMs = date;
    const currDtMs = Date.now();
    const currPlusWeekDtMs = new Date(Date.now()).setDate(new Date(Date.now()).getDate() + 7);

    let dt1 = currPlusWeekDtMs - askedDtMs;
    let dt2 = currPlusWeekDtMs - currDtMs;
    let result = 100 - Math.trunc(dt1 / dt2 * 100);
    return 120 + result * 0.8;
}

export const minusDaysFromDate = (date: Date, days: number): Date => {
    return new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
}

export const calculateDaysBetweenDates = (startDate: Date, endDate: Date): number => {
    const timeDifference = endDate.getTime() - startDate.getTime();
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
}

export const buildClock = (timestamp: number): string => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString();
    const minutes = date.getMinutes().toString();
    const seconds = date.getSeconds().toString();
    return ` | ${buildDigitClock(hours)}:${buildDigitClock(minutes)}:${buildDigitClock(seconds)} |`;
};

const buildDigitClock = (value: string): string => {
    return (value.length < 2) ? '0' + value : value;
};

