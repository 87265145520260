import { Box, Paper } from "@mui/material";
import { useCallback } from "react";
import { useLoad } from "../../../../hooks/useLoad";
import { EIssueType } from "../../../../models/IssueModels";
import IssueBusinessGrid from "../../../IssueModule/Business/IssueBusinessGrid";

interface IProps {
    loadId: string;
}
const LoadIssuesStep = (props: IProps) => {
    const { gridRefresh } = useLoad();

    const onSubmitHandler = useCallback(() => {
        gridRefresh();
    }, [gridRefresh]);

    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px', height: 300 }}>
                <IssueBusinessGrid
                    type={EIssueType.LOAD}
                    businessItemId={props.loadId}
                    onSubmit={onSubmitHandler}
                />
            </Box>
        </Paper>
    );
}
export default LoadIssuesStep;