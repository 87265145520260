import { Box, Paper } from "@mui/material";
import { useTranslation } from 'react-i18next';
import InfoBox from "../../../Base/NotificationComponent/InfoBox";
import LoadChargesGrid from "./LoadChargesGrid";

interface IProps {
    loadId: string;
}
const LoadChargesStep = (props: IProps) => {
    const { t } = useTranslation();
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px', height: 350 }}>
                <Box sx={{ height: 310 }}>
                    <LoadChargesGrid loadId={props.loadId} />
                </Box>
                <Box marginTop={2}>
                    <InfoBox
                        message={t('The records from this table will be present in the invoice and will afect the total rate of the invoice.')}
                    />
                </Box>
            </Box>
        </Paper>
    );
}
export default LoadChargesStep;