import DownloadIcon from '@mui/icons-material/Download';
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { IFilter } from '../../../models/CommonModels';

const circularProgressStyleIconBtn = {
    position: 'absolute',
    zIndex: 1,
    top: '15%',
    left: '18%'
};

interface IProps {
    downloadApi: (filter: IFilter) => Promise<any>;
    customApi?: boolean;
    tooltipPlacementTop?: boolean;
    tooltip?: string;
    tooltipEnabled?: boolean;
    iconBtnColor?: 'primary' | 'default';
    disabled?: boolean;
}
const BaseDownloadBtn = (props: IProps) => {
    const {
        downloadApi, tooltipPlacementTop = false, tooltip = 'Download',
        tooltipEnabled = false, iconBtnColor = 'default', disabled = false,
        customApi = false
    } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const processDownload = useCallback((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const fileName: string = response.headers['content-disposition']
            .split('filename=')[1]
            .replaceAll('"', '');
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);

        link.click();
        link.remove();
    }, []);

    const onBtnClickHandler = useCallback(() => {
        setLoading(true);

        const filter: IFilter = { criteria: [] };
        (async () => {
            const [error, response] = await downloadApi(filter);
            if (response) {
                processDownload(response);
                setLoading(false);
            }

            if (error) {
                setLoading(false);
            }
        })();
    }, [downloadApi, processDownload]);

    const onCustomBtnClickHandler = useCallback(() => {
        const filter: IFilter = { criteria: [] };
        downloadApi(filter);
    }, [downloadApi]);

    const buildIconBtn = useCallback(() => {
        return (
            <Tooltip
                title={tooltipEnabled ? tooltip : ''}
                placement={tooltipPlacementTop ? 'top' : 'bottom'}
            >
                <Box sx={{ position: 'relative' }}>
                    <IconButton
                        disabled={loading || disabled}
                        size='small'
                        color={iconBtnColor}
                        onClick={customApi ? onCustomBtnClickHandler : onBtnClickHandler}>
                        <DownloadIcon />
                    </IconButton>
                    {loading && <CircularProgress size={23} sx={circularProgressStyleIconBtn} />}
                </Box>
            </Tooltip>
        );
    }, [
        customApi, disabled, iconBtnColor, loading, onBtnClickHandler,
        onCustomBtnClickHandler, tooltip, tooltipEnabled, tooltipPlacementTop
    ]);

    return (buildIconBtn());
}
export default BaseDownloadBtn;