import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Paper, Stack, Step, StepButton, StepContent, Stepper, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { normalizeNavigationHomeUrl, normalizeNavigationUrl } from '../../../helpers/generalHelper';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { IStepItem } from '../../../models/CommonModels';
import { EButtonColor } from '../../../models/EnumGeneral';
import ButtonConfirmation from '../ActionConfirmationComponent/ButtonConfirmation';
import BackdropProgress from '../BackdropComponent/BackdropProgress';

const coverFaceStyle = {
    padding: '10px 15px 10px 15px'
};

const contentStyle = {
    marginTop: '5px',
    marginBottom: '5px',
    padding: '10px',
    overflowY: 'scroll'
};

const bottomStyle = {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
    padding: '15px'
};

interface IProps {
    retrieveDataApi: (id: string) => Promise<any>;
    refresh: boolean;

    buildCoverFace: (data: any) => React.ReactNode;
    buildItems: (data: any) => IStepItem[];

    onDeleteBtnClick?: (uuid: string) => void;
    deleteBtnHide?: boolean;
    deleteBtnTooltip?: string;

    closeBtnTooltip?: string;
}
const BaseStep = (props: IProps) => {
    const {
        retrieveDataApi, buildCoverFace, buildItems, onDeleteBtnClick, refresh,
        deleteBtnHide = false, deleteBtnTooltip = 'Delete item',
        closeBtnTooltip = 'Close and return to the main list'
    } = props;

    const { id, step } = useParams();
    const isAsStr: string = id as string;

    const navigate = useNavigate();
    const windowDimensions = useWindowDimensions();
    const unselectedStep: number = -1;
    const heightSize = windowDimensions.height - 260;

    const [activeStep, setActiveStep] = useState<number>(step ? Number(step) - 1 : unselectedStep);
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const fetchData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await retrieveDataApi(isAsStr);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                setLoading(false);
            }
        })();
    }, [retrieveDataApi, isAsStr]);

    useEffect(() => {
        fetchData();
    }, [fetchData, refresh]);

    const onChangeStepHandler = useCallback((step: number) => {
        let newUrl: string;
        if (step === activeStep) {
            setActiveStep(unselectedStep);
            newUrl = normalizeNavigationUrl(isAsStr, unselectedStep);
        } else {
            setActiveStep(step);
            newUrl = normalizeNavigationUrl(isAsStr, step + 1);
        }
        navigate(`/${newUrl}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, isAsStr, unselectedStep]);

    const onDeleteBtnClickHandler = useCallback(() => {
        if (onDeleteBtnClick) {
            onDeleteBtnClick(isAsStr);
        }
    }, [isAsStr, onDeleteBtnClick]);

    const onCloseButtonHandler = useCallback(() => {
        const newUrl: string = normalizeNavigationHomeUrl(true);
        navigate(`/${newUrl}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BackdropProgress open={(loading && !step) || false}>
            {data &&
                <Box>
                    <Paper sx={coverFaceStyle}>
                        <Box>
                            <Stack spacing={1} direction='row'>
                                {buildCoverFace(data)}

                                <div style={{ flex: '1 0 0' }} />

                                <Tooltip title={closeBtnTooltip} placement='top'>
                                    <IconButton onClick={onCloseButtonHandler}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Box>
                    </Paper>

                    <Box height={heightSize} maxHeight={heightSize} sx={contentStyle}>
                        <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
                            {
                                buildItems(data).map((item, index) =>
                                    <Step
                                        key={item.title}
                                        completed={false}
                                        disabled={item.disabled}
                                    >
                                        <StepButton
                                            sx={{ '&:hover': { backgroundColor: 'rgb(242 242 242 / 87%)' } }}
                                            color='inherit'
                                            onClick={() => onChangeStepHandler(index)}
                                        >
                                            <Box display='flex' alignItems='center' justifyContent='flex-start'>
                                                <Typography variant="body2">{item.title}</Typography>

                                                {item.required &&
                                                    <Typography
                                                        component='span'
                                                        variant='caption'
                                                        sx={{
                                                            verticalAlign: 'super',
                                                            marginLeft: 1,
                                                            color: '#1976d2',
                                                            fontSize: '0.65em'
                                                        }}
                                                    >
                                                        REQUIRED
                                                    </Typography>
                                                }
                                            </Box>
                                        </StepButton>
                                        <StepContent>
                                            {item.content}
                                        </StepContent>
                                    </Step>
                                )
                            }
                        </Stepper>
                    </Box>

                    <Box sx={bottomStyle}>
                        {!deleteBtnHide &&
                            <ButtonConfirmation
                                variant='contained'
                                onConfirm={onDeleteBtnClickHandler}
                                icon={<DeleteIcon fontSize='medium' />}
                                color={EButtonColor.error}
                                disabled={data.readonly}
                                tooltip={deleteBtnTooltip}
                            />
                        }
                    </Box>
                </Box>
            }
        </BackdropProgress>
    );
}
export default BaseStep;