import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { currencyFormat } from '../../../../helpers/NumberHelper';
import { useNotification } from "../../../../hooks/useNotification";
import { IItemDetails } from '../../../../models/CommonModels';
import { ILoadOverviewResponseDto } from '../../../../models/LoadModels';
import LoadService from '../../../../services/LoadService';
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";
import ItemsDetails from "../../../Base/ItemsDetailsComponent/ItemsDetails";
import LoadDialog from '../../Grid/LoadDialog';

interface IProps {
    loadId: string;
}
const LoadOverview = (props: IProps) => {
    const { loadId } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [data, setData] = useState<ILoadOverviewResponseDto>();

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const [dialog, setDialog] = useState(false);

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.fetchOverview(loadId);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId]);

    useEffect(() => {
        retrieveData();
    }, [refresh, retrieveData]);

    const items: IItemDetails[] = [{
        id: `${data?.customer.uuid}-customer`,
        primary: t('CUSTOMER'),
        secondary: data?.customer.name
    }, {
        id: `${data?.uuid}-idno`,
        primary: t('ID#'),
        secondary: data?.idno
    }, {
        id: `${data?.uuid}-rate`,
        primary: t('RATE'),
        secondary: data?.rate ? currencyFormat(data.rate) : ''
    }, {
        id: `${data?.uuid}-flat-rate`,
        primary: t('FLAT RATE'),
        secondary: data?.flatRate ? currencyFormat(data?.flatRate) : ''
    }];

    const dialogToggleHandler = useCallback(() => {
        setDialog(dialog => !dialog);
    }, []);

    const refreshHandler = useCallback(() => {
        setRefresh(refresh => !refresh);
    }, []);

    return (
        <>
            <BackdropProgress open={loading || false}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title={t('EDIT')} placement='top' >
                        <span>
                            <Button
                                onClick={dialogToggleHandler}
                                size='small'
                                variant='outlined'>
                                <EditIcon fontSize='small' />&nbsp;{t('EDIT')}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
                <ItemsDetails data={items} />
            </BackdropProgress>

            {dialog &&
                <LoadDialog
                    open={dialog}
                    onCloseBtnClick={dialogToggleHandler}
                    load={data}
                    onSubmitBtnClick={refreshHandler}
                />
            }
        </>
    );
}
export default LoadOverview;