import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationHomeUrl } from "../../../helpers/generalHelper";
import { useIssue } from "../../../hooks/useIssue";
import { useNotification } from "../../../hooks/useNotification";
import { IStepItem } from "../../../models/CommonModels";
import IssueService from "../../../services/IssueService";
import { RootState } from "../../../store/store";
import BaseStep from "../../Base/BaseStepComponent/BaseStep";
import IssueAssignedOnStep from "./AssignedOn/IssueAssignedOnStep";
import IssueStepCoverFace from "./IssueStepCoverFace";
import IssueOverviewStep from "./Overview/IssueOverviewStep";
import IssueDocumentsStep from "./Documents/IssueDocumentsStep";

const IssueStep = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const navigate = useNavigate();
    const { refresh } = useSelector((state: RootState) => state.issueSlice.step);
    const { gridRefresh } = useIssue();

    const onRetrieveDataApiHandler = useCallback((id: string) => {
        return IssueService.fetchCoverFaceData(id);
    }, []);

    const onBuildCoverFaceHandler = useCallback((data: any): React.ReactNode => {
        return <IssueStepCoverFace data={data} />;
    }, []);

    const onBuildItemsHandler = useCallback((data: any): IStepItem[] => {
        const id: string = data.uuid;
        return [{
            title: t('OVERVIEW'),
            content: <IssueOverviewStep entityId={id} status={data.status} />
        }, {
            title: t('ASSIGNED'),
            content: <IssueAssignedOnStep entityId={id} status={data.status} />
        }, {
            title: t('DOCUMENTS'),
            content: <IssueDocumentsStep entityId={id} />
        }];
    }, [t]);

    const onDeleteBtnClickHandler = useCallback((uuid: string) => {
        (async () => {
            const [error] = await IssueService.remove(uuid);

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
            } else {
                const newUrl: string = normalizeNavigationHomeUrl(true);
                navigate(`/${newUrl}`);
                displayNotification({ message: t('Task was successfully deleted.') });
                gridRefresh();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    return (
        <BaseStep
            retrieveDataApi={onRetrieveDataApiHandler}
            refresh={refresh}
            buildCoverFace={onBuildCoverFaceHandler}
            buildItems={onBuildItemsHandler}
            onDeleteBtnClick={onDeleteBtnClickHandler}
            deleteBtnTooltip={t('Delete task')}
            closeBtnTooltip={t('Close and return to the main list')}
        />
    );
}
export default IssueStep;