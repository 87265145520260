import DownloadIcon from '@mui/icons-material/Download';
import PageviewIcon from '@mui/icons-material/Pageview';
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDateAndTimeFormat } from "../../../../helpers/dateHelper";
import { isEmpty } from "../../../../helpers/generalHelper";
import { currencyFormat } from "../../../../helpers/NumberHelper";
import { useLoad } from "../../../../hooks/useLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { IFilter } from "../../../../models/CommonModels";
import { IFileDetailsResponseDto } from "../../../../models/FileModel";
import { ILoadChargeResponseDto, ILoadChargeTypeResponseDto } from "../../../../models/LoadModels";
import FileService from '../../../../services/FileService';
import LoadService from "../../../../services/LoadService";
import { RootState } from "../../../../store/store";
import BaseCrudGrid from "../../../Base/BaseCrudGridComponent/BaseCrudGrid";
import FileViewDialog from '../../../Base/FileComponent/FileViewDialog';
import LoadChargesDialog from "./LoadChargesDialog";

interface IProps {
    loadId: string;
}
const LoadChargesGrid = (props: IProps) => {
    const { loadId } = props;

    const { t } = useTranslation();
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.chargesGrid);
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { displayNotification } = useNotification();
    const { chargesGridRefresh, gridRefresh, stepRefresh } = useLoad();
    const [previewFileDialogToggle, setPreviewFileDialogToggle] = useState(false);
    const [previewFile, setPreviewFile] = useState<IFileDetailsResponseDto>();

    const [dialogToggle, setDialogToggle] = useState(false);
    const [charge, setCharge] = useState<ILoadChargeResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchCharges(loadId, filter);
    }, [loadId]);

    const onAddActionBtnClick = useCallback(() => {
        setCharge(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClick = useCallback((charge: ILoadChargeResponseDto) => {
        setCharge(charge);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await LoadService.removeCharge(loadId, rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Load charge${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    stepRefresh();
                    chargesGridRefresh();
                    gridRefresh();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId]);

    const onTypeColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: ILoadChargeTypeResponseDto = params.value;
        const row: ILoadChargeResponseDto = params.row;
        const fileNotExists: boolean = row.file === undefined || row.file === null;

        const style = value.requiresProof && fileNotExists ? { color: 'red' } : {};

        return (
            <Tooltip title={value.name}>
                <span style={style}>{value.name}</span>
            </Tooltip>
        );
    }, []);

    const onAmountColumnRender = useCallback((params: GridRenderCellParams) => {
        return currencyFormat(params.value);
    }, []);

    const onPreviewFileDialogToggle = useCallback(() => {
        setPreviewFileDialogToggle(previewFileDialogToggle => !previewFileDialogToggle);
    }, []);

    const onPreviewFileActionBtnClick = useCallback((file: IFileDetailsResponseDto) => {
        setPreviewFile(file);
        onPreviewFileDialogToggle();
    }, [onPreviewFileDialogToggle]);

    const onDownloadFileActionBtnClick = useCallback((url: string) => {
        (async () => {
            const elements = url.split('/');
            const uuid = elements[elements.length - 1];
            FileService.downloadByUrl(`file/download/${uuid}`);
        })();
    }, []);

    const onFileColumnRender = useCallback((params: GridRenderCellParams,) => {
        const value: IFileDetailsResponseDto = params.value;
        if (!value) {
            return '';
        }

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'center' }}>
                <IconButton size='small' color='info' onClick={() => onPreviewFileActionBtnClick(value)}>
                    <PageviewIcon />
                </IconButton>

                <IconButton size='small' color='info' onClick={() => onDownloadFileActionBtnClick(value.url)}>
                    <DownloadIcon />
                </IconButton>
            </Box>
        );
    }, [onDownloadFileActionBtnClick, onPreviewFileActionBtnClick]);

    const onProofColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadChargeResponseDto = params.row;
        const value: boolean = row.type.requiresProof;
        const valueAsStr: string = value ? t('YES') : t('NO');

        return (
            <Tooltip title={valueAsStr}>
                <span>{valueAsStr}</span>
            </Tooltip>
        );
    }, [t]);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'type',
            headerName: t('TYPE'),
            minWidth: 200,
            flex: 1,
            headerAlign: 'center',
            hideable: false,
            renderCell: onTypeColumnRender
        }, {
            field: 'type.requiresProof',
            headerName: t('REQUIRES PROOF'),
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: onProofColumnRender
        }, {
            field: 'file',
            headerName: t('PROOF'),
            minWidth: 100,
            headerAlign: 'center',
            renderCell: onFileColumnRender
        }, {
            field: 'amount',
            headerName: t('AMOUNT'),
            width: 150,
            headerAlign: 'center',
            type: 'number',
            renderCell: onAmountColumnRender
        }, {
            field: 'created',
            headerName: t('CREATED'),
            width: 190,
            headerAlign: 'center',
            renderCell: onCreatedColumnRender
        }];
    }, [
        onAmountColumnRender, onCreatedColumnRender, onFileColumnRender,
        onProofColumnRender, onTypeColumnRender, t
    ]);

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setCharge(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        chargesGridRefresh();
        stepRefresh();
        gridRefresh();
    }, [chargesGridRefresh, gridRefresh, stepRefresh]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams, row: any) => {
        setCharge(row);
        setDialogToggle(true);
    }, []);

    return (
        <>
            <BaseCrudGrid
                gridId='load-charge'
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClick}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add item')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
                onRowDoubleClick={onRowDoubleClickHandler}
            />

            {dialogToggle &&
                <LoadChargesDialog
                    open={dialogToggle}
                    loadId={loadId}
                    entity={charge}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }

            {previewFileDialogToggle && previewFile &&
                <FileViewDialog
                    fileId={''}
                    file={previewFile}
                    open={previewFileDialogToggle}
                    navigationBtnsHide
                    onCloseBtnClick={onPreviewFileDialogToggle}
                />
            }
        </>
    );
}
export default LoadChargesGrid;