import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { currencyFormat } from '../../../helpers/NumberHelper';
import { useNotification } from '../../../hooks/useNotification';
import { IFilter } from '../../../models/CommonModels';
import { EInvoiceType, IInvoiceSummaryResponseDto } from '../../../models/InvoiceModels';
import InvoiceService from '../../../services/InvoiceService';
import BaseDialog from '../../Base/BaseDialogComponent/BaseDialog';
import InfoBox from '../../Base/NotificationComponent/InfoBox';

interface IProps {
    open: boolean;
    filter: IFilter;
    summary: IInvoiceSummaryResponseDto;

    onCloseBtnClick: () => void;
}
const InvoiceDownloadDialog = (props: IProps) => {
    const { open, filter, summary, onCloseBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [type, setType] = useState<EInvoiceType>(EInvoiceType.COMPLETE_INVOICE);

    const onTypeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value: EInvoiceType = event.target.value as EInvoiceType;
        setType(value);
    };

    const processDownload = useCallback((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const fileName: string = response.headers['content-disposition']
            .split('filename=')[1]
            .replaceAll('"', '');
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);

        link.click();
        link.remove();
    }, []);

    const onSubmitBtnClickHandler = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await InvoiceService.downloadInvoices(filter, type);
            if (response) {
                processDownload(response);
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [displayNotification, filter, onCloseBtnClick, processDownload, type]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack direction='column' spacing={2}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby='radio-buttons-group-label'
                        name='type'
                        value={type}
                        onChange={onTypeChangeHandler}
                    >
                        <FormControlLabel
                            value={EInvoiceType.INVOICE}
                            control={<Radio />}
                            label={EInvoiceType.INVOICE.toString()}
                        />
                        <FormControlLabel
                            value={EInvoiceType.COMPLETE_INVOICE}
                            control={<Radio />}
                            label={EInvoiceType.COMPLETE_INVOICE.toString().replaceAll('_', ' ')}
                        />
                    </RadioGroup>
                </FormControl>

                <Divider />

                <Box>
                    <Box display={'flex'} gap={'5px'}>
                        <Typography variant={'body1'}>
                            <b>{t('TOTAL INVOICES')}:</b>
                        </Typography>

                        <Typography variant={'body1'}>
                            {summary.count}
                        </Typography>
                    </Box>

                    <Box display={'flex'} gap={'5px'}>
                        <Typography variant={'body1'}>
                            <b>{t('TOTAL RATE')}:</b>
                        </Typography>

                        <Typography variant={'body1'}>
                            {currencyFormat(summary.rate)}
                        </Typography>
                    </Box>
                </Box>

                <Divider />

                <InfoBox
                    title={t('PDF file')}
                    message={t('The downloaded PDF file will include all the invoices that are visible in the table based on the selected filter or only the selected invoices from the table.')}
                />
            </Stack>
        );
    }, [summary.count, summary.rate, t, type]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('DOWNLOAD')} arrow>
                <span>
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<SaveAsIcon />}
                        onClick={onSubmitBtnClickHandler}
                    >
                        {t('DOWNLOAD')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [onSubmitBtnClickHandler, t]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            title={t('DOWNLOAD INVOICE(S)')}
            maxWidth={'xs'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default InvoiceDownloadDialog;