import GradeIcon from '@mui/icons-material/Grade';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDateAndTimeFormat, getDateFormat } from "../../../helpers/dateHelper";
import { isEmpty, normalizeNavigationUrl } from "../../../helpers/generalHelper";
import { currencyFormat } from '../../../helpers/NumberHelper';
import { IAddressResponseDto } from '../../../models/AddressModels';
import { IBaseGridActionBtn, IFilter } from '../../../models/CommonModels';
import { ICustomerResponseDto } from '../../../models/CustomerModels';
import CustomerService from '../../../services/CustomerService';
import { RootState } from "../../../store/store";
import BaseCrudGrid from "../../Base/BaseCrudGridComponent/BaseCrudGrid";
import DetailsBtn from '../../Base/DetailsBtnComponent/DetailsBtn';
import RatingView from '../../Base/RatingComponent/RatingView';
import CustomerMapsViewDialog from '../MapsView/CustomerMapsViewDialog';
import CustomerDialog from './CustomerDialog';
import CustomerFilter from './CustomerFilter';

const CustomerGrid = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.customerSlice.grid);

    const [createDialogToggle, setCreateDialogToggle] = useState<boolean>(false);
    const [filterToggle, setFilterToggle] = useState<boolean>(false);
    const [mapViewToggle, setMapViewToggle] = useState<boolean>(false);
    const [data, setData] = useState<ICustomerResponseDto[]>([]);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return CustomerService.fetchAllByFilter(filter);
    }, []);

    const onInitData = useCallback((data: ICustomerResponseDto[]) => {
        setData(data);
    }, []);

    const onDetailsActionBtnClick = useCallback((uuid: string) => {
        const newUrl = normalizeNavigationUrl(uuid);
        navigate(`/${newUrl}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDownloadActionBtnApiHandler = useCallback((filter: IFilter) => {
        return CustomerService.downloadReport(filter);
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onActionsColumnRender = useCallback((record: any) => {
        const row = record.row;
        return [
            <DetailsBtn onClick={() => onDetailsActionBtnClick(row.uuid)} />
        ];
    }, [onDetailsActionBtnClick]);

    const buildIconOnCenterColumn = useCallback((icon: any) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                {icon}
            </Box>
        );
    }, []);

    const onFavoriteColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: boolean = params.value;
        return (
            buildIconOnCenterColumn(value
                ? <GradeIcon color='action' fontSize='small' />
                : <GradeOutlinedIcon color='action' fontSize='small' />
            )
        );
    }, [buildIconOnCenterColumn]);

    const onStatusOrReputationColumnRender = useCallback((value: string) => {
        return value.toString().replace('_', ' ');
    }, []);

    const onLastLoadDateColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = params.value ? getDateFormat(params.value, dateFormat) : '';
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, [dateFormat]);

    const onAddressColumnRender = useCallback((params: GridRenderCellParams) => {
        const address: IAddressResponseDto = params.value;
        return (
            <Tooltip title={address.fullAddress}>
                <span>{address.fullAddress}</span>
            </Tooltip>
        );
    }, []);

    const onLoadsColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ICustomerResponseDto = params.row;
        return (
            <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                <Tooltip title={`${t('TOTAL: ')} ${row.totalNumberOfLoads}`}>
                    <span style={{ color: 'black' }}>{row.totalNumberOfLoads}</span>
                </Tooltip>

                <Tooltip title={`${t('PAID: ')} ${row.numberOfPayedLoads}`}>
                    <span style={{ color: 'blue' }}>{row.numberOfPayedLoads}</span>
                </Tooltip>
            </Box>
        );
    }, [t]);

    const onAmountColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ICustomerResponseDto = params.row;
        const total: string = currencyFormat(row.totalAmount);
        const payed: string = currencyFormat(row.payedAmount);
        return (
            <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'right' }}>
                <Tooltip title={`${t('TOTAL: ')} ${total}`}>
                    <span style={{ color: 'black' }}>{total}</span>
                </Tooltip>

                <Tooltip title={`${t('PAID: ')} ${payed}`}>
                    <span style={{ color: 'blue' }}>{payed}</span>
                </Tooltip>
            </Box>
        );
    }, [t]);

    const onReviewColumnRender = useCallback((params: GridRenderCellParams) => {
        return <RatingView value={params.value} />
    }, []);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 50,
            align: 'center',
            resizable: false,
            disableColumnMenu: true,
            hideable: false,
            getActions: onActionsColumnRender
        }, {
            field: 'favorite',
            headerName: '',
            width: 50,
            align: 'center',
            resizable: false,
            disableColumnMenu: true,
            sortable: false,
            hideable: false,
            renderCell: onFavoriteColumnRender
        }, {
            field: 'idno',
            headerName: t('ID#'),
            width: 150,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'name',
            headerName: t('NAME'),
            flex: 1,
            minWidth: 170,
            headerAlign: 'center',
            hideable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'email',
            headerName: t('EMAIL'),
            flex: 1,
            minWidth: 170,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'phone',
            headerName: t('PHONE'),
            width: 150,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'address',
            headerName: t('ADDRESS'),
            flex: 1,
            minWidth: 200,
            headerAlign: 'center',
            sortable: false,
            renderCell: onAddressColumnRender
        }, {
            field: 'lastLoadDate',
            headerName: t('LAST LOAD'),
            width: 100,
            headerAlign: 'center',
            renderCell: onLastLoadDateColumnRender
        }, {
            field: 'loads',
            headerName: t('LOADS'),
            width: 120,
            headerAlign: 'center',
            align: 'center',
            renderCell: onLoadsColumnRender
        }, {
            field: 'amount',
            headerName: t('AMOUNT'),
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: onAmountColumnRender
        }, {
            field: 'reputationLevel',
            headerName: t('REPUTATION'),
            width: 115,
            headerAlign: 'center',
            valueFormatter: onStatusOrReputationColumnRender
        }, {
            field: 'reviewMark',
            headerName: t('REVIEW'),
            width: 110,
            headerAlign: 'center',
            renderCell: onReviewColumnRender
        }, {
            field: 'status',
            headerName: t('STATUS'),
            width: 115,
            headerAlign: 'center',
            valueFormatter: onStatusOrReputationColumnRender
        }, {
            field: 'created',
            headerName: t('CREATED'),
            width: 180,
            headerAlign: 'center',
            renderCell: onCreatedColumnRender
        }];
    }, [
        onActionsColumnRender, onAddressColumnRender, onAmountColumnRender,
        onCreatedColumnRender, onFavoriteColumnRender, onLastLoadDateColumnRender,
        onLoadsColumnRender, onReviewColumnRender, onStatusOrReputationColumnRender,
        onTooltipColumnRender, t
    ]);

    const createDialogToggleHandler = useCallback(() => {
        setCreateDialogToggle(createDialogToggle => !createDialogToggle);
    }, []);

    const onFilterToggleHandler = useCallback(() => {
        setFilterToggle(!filterToggle);
    }, [filterToggle]);

    const onMapViewToggleHandler = useCallback(() => {
        setMapViewToggle(mapViewToggle => !mapViewToggle);
    }, []);

    const actionBtns: IBaseGridActionBtn[] = [{
        tooltip: t('Maps view'),
        onClick: onMapViewToggleHandler,
        icon: <RoomOutlinedIcon />,
        key: 'maps-view-action-btn',
        disabled: isEmpty(data),
        iconBtn: true
    }];

    const onRowDoubleClickHandler = useCallback((params: GridRowParams) => {
        onDetailsActionBtnClick(params.id.toString());
    }, [onDetailsActionBtnClick]);

    return (
        <>
            <BaseCrudGrid
                gridId='customer'
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                refreshActionBtnTooltip={t('Refresh data')}
                addActionBtnTooltip={t('Create customer')}
                onAddActionBtnClick={createDialogToggleHandler}
                editActionBtnHide={true}
                deleteActionBtnHide={true}
                filterActionBtnShow
                filterActionBtnTooltip={t('Search customer(s)')}
                onFilterActionBtnClick={onFilterToggleHandler}
                downloadActionBtnShow
                downloadActionBtnTooltip={t('Download customer(s)')}
                downloadActionBtnApi={onDownloadActionBtnApiHandler}
                actionBtns={actionBtns}
                onInitData={onInitData}
                onRowDoubleClick={onRowDoubleClickHandler}
            />

            {filterToggle &&
                <CustomerFilter
                    open={filterToggle}
                    onClose={onFilterToggleHandler}
                />
            }

            {createDialogToggle &&
                <CustomerDialog
                    open={createDialogToggle}
                    onCloseBtnClick={createDialogToggleHandler}
                />
            }

            {mapViewToggle &&
                <CustomerMapsViewDialog
                    open={mapViewToggle}
                    data={data}
                    onCloseBtnClick={onMapViewToggleHandler}
                />
            }
        </>
    );
}
export default CustomerGrid;