import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Paper, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { useUserProfile } from '../../../../hooks/useUserProfile';
import { IUserProfileRequestDto, IUserProfileResponseDto } from '../../../../models/UserProfileModels';
import UserProfileService from '../../../../services/UserProfileService';
import { RootState } from "../../../../store/store";
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";
import ColorPickerField from '../../../Base/ColorComponent/ColorPickerField';
import DateField from '../../../Base/DateComponent/DateField';

const formId: string = 'personal-profile-form';
const PersonalProfile = () => {
    const { t } = useTranslation();

    const { firstName, lastName, color, birthday } = useSelector((state: RootState) => state.userProfileSlice.profile);
    const { dateFormat } = useSelector((state: RootState) => state.preferenceSlice.user);

    const { register, setValue, getValues, reset, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<IUserProfileRequestDto>({
        defaultValues: {
            firstName: firstName,
            lastName: lastName,
            color: color,
            birthday: birthday
        }
    });

    const { displayNotification } = useNotification();
    const { update } = useUserProfile();
    const [loading, setLoading] = useState(false);

    const fetchUserProfile = useCallback(() => {
        (async () => {
            const [, response] = await UserProfileService.fetch();
            if (response) {
                const profile: IUserProfileResponseDto = response.data.body;
                update({ ...profile, loaded: true });

                reset({ ...profile });
            }
        })();
    }, [reset, update]);

    const onSubmit = useCallback((data: IUserProfileRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserProfileService.update(data);
            if (response) {
                displayNotification({ message: t('Profile was successfully updated.') });
                fetchUserProfile();
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchUserProfile, t]);

    const validateIsNotBlank = useCallback((value: string) => {
        return !isBlank(value);
    }, []);

    register('color', { validate: validateIsNotBlank });
    const onColorChangeHandler = useCallback((value: string) => {
        setValue('color', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('birthday');
    const onChangeBirthdayHandler = useCallback((value?: number) => {
        setValue('birthday', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    return (
        <Paper sx={{ marginTop: '10px' }}>
            <BackdropProgress open={loading || false}>
                <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ padding: '10px' }}>
                        <Stack spacing={2}>
                            <Stack direction='row' spacing={2}>
                                <TextField
                                    {...register('firstName', { required: true, validate: validateIsNotBlank })}
                                    required
                                    label={t('FIRST NAME')}
                                    fullWidth
                                    slotProps={{ htmlInput: { minLength: 2, maxLength: 55 } }}
                                />

                                <TextField
                                    {...register('lastName', { required: true, validate: validateIsNotBlank })}
                                    required
                                    label={t('LAST NAME')}
                                    fullWidth
                                    slotProps={{ htmlInput: { minLength: 2, maxLength: 55 } }}
                                />
                            </Stack>

                            <Stack direction='row' spacing={2}>
                                <ColorPickerField
                                    required
                                    label={t('COLOR')}
                                    value={getValues('color')}
                                    onChange={onColorChangeHandler}
                                />

                                <DateField
                                    label={t('BIRTHDAY')}
                                    size='medium'
                                    value={getValues('birthday')}
                                    format={dateFormat}
                                    onChange={onChangeBirthdayHandler}
                                />
                            </Stack>
                        </Stack>

                        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Tooltip title={t('SAVE')} arrow>
                                <span>
                                    <Button
                                        form={formId}
                                        type='submit'
                                        variant='contained'
                                        disabled={!isValid || !isDirty}
                                        size='small'
                                        startIcon={<SaveAsIcon />}
                                    >
                                        {t('SAVE')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>
                </form>
            </BackdropProgress>
        </Paper>
    );
}
export default PersonalProfile;