import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../helpers/textHelper";
import { useNotification } from "../../../hooks/useNotification";
import { EPriority } from "../../../models/CommonModels";
import { EIssueStatus, EIssueType, IIssueBusinessResponseDto, IIssueRequestDto } from "../../../models/IssueModels";
import { IUserAutocompleteResponseDto } from "../../../models/UserModels";
import IssueService from "../../../services/IssueService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import PrioritySelect from "../../Base/PrioritySelectComponent/PrioritySelect";
import UserAutocomplete from "../../UserModule/UserAutocomplete";

interface IProps {
    type: EIssueType;
    businessItemId?: string;
    issue?: IIssueBusinessResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const IssueBusinessDialog = (props: IProps) => {
    const { open, type, businessItemId, issue, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'issue-business-form';

    const isEdit = useRef<boolean>(issue !== undefined);
    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, setError, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IIssueRequestDto>({
        defaultValues: {
            title: isEdit.current ? issue?.title : '',
            cause: isEdit.current ? issue?.cause : '',
            priority: isEdit.current ? issue?.priority : EPriority.LOW,
            assignedOnId: isEdit.current ? issue?.assignedOn?.uuid : undefined,
            type: type,
            businessItemId: businessItemId
        }
    });

    const updateData = useCallback((uuid: string, data: IIssueRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await IssueService.update(uuid, data);
            if (response) {
                displayNotification({ message: t('Task was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: IIssueRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await IssueService.create(data);
            if (response) {
                displayNotification({ message: t('Task was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: IIssueRequestDto) => {
        if (issue) {
            updateData(issue.uuid, data);
        } else {
            createData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issue]);

    const validatePriorityField = useCallback((value: EPriority) => {
        return EPriority[value] !== undefined && value !== EPriority.NONE;
    }, []);

    const validateAssignedOnField = useCallback((value?: string) => {
        if (isEdit.current && issue && issue.status === EIssueStatus.IN_PROGRESS) {
            return value !== undefined;
        }
        return true;
    }, [issue]);

    register('priority', { validate: validatePriorityField });
    const onPriorityChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('priority', Number(event.target.value), {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('assignedOnId', { validate: validateAssignedOnField });
    const onAssignedOnChangeHandler = useCallback((value: IUserAutocompleteResponseDto | null) => {
        setValue('assignedOnId', value?.uuid, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateTitle = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('title', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const validateCause = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('cause', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <TextField
                        {...register('title', {
                            required: true,
                            validate: validateTitle
                        })}
                        required
                        autoFocus
                        label={t('TITLE')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 100 } }}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                    />

                    <TextField
                        {...register('cause', {
                            required: true,
                            validate: validateCause
                        })}
                        required
                        error={!!errors.cause}
                        label={t('CAUSE')}
                        multiline
                        rows={5}
                        slotProps={{ htmlInput: { minLength: 1 } }}
                        helperText={errors.cause?.message}
                    />

                    <Stack direction="row" spacing={2}>
                        <div style={{ width: '200px' }}>
                            <PrioritySelect
                                required
                                label={t('PRIORITY')}
                                value={getValues('priority')}
                                onChange={onPriorityChangeHandler}
                            />
                        </div>

                        <UserAutocomplete
                            label={t('ASSIGNED ON')}
                            value={getValues('assignedOnId')}
                            onChange={onAssignedOnChangeHandler}
                            showStatus
                            disableInactiveItems
                            required={isEdit.current && issue && issue.status === EIssueStatus.IN_PROGRESS}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [errors.cause, errors.title, getValues, handleSubmit, issue, onAssignedOnChangeHandler,
        onPriorityChangeHandler, onSubmit, register, t, validateCause, validateTitle
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={isEdit.current ? t(`EDIT TASK #${issue?.idno}`) : t('CREATE TASK')}
            maxWidth={'sm'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default IssueBusinessDialog;