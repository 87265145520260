import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../helpers/textHelper";
import { useNotification } from "../../../hooks/useNotification";
import { ILoadChargeTypeRequestDto, ILoadChargeTypeResponseDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";

const formId: string = 'load-charge-type-form';
interface IProps {
    entity?: ILoadChargeTypeResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: (entityId?: string) => void;
}
const LoadChargeTypeDialog = (props: IProps) => {
    const { open, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const { register, getValues, setValue, setError, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<ILoadChargeTypeRequestDto>({
        defaultValues: {
            name: entity ? entity.name : '',
            requiresProof: entity ? entity.requiresProof : false
        }
    });

    const updateData = useCallback((uuid: string, data: ILoadChargeTypeRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateChargeType(uuid, data);
            if (response) {
                displayNotification({ message: t('Charge type was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: ILoadChargeTypeRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.createChargeType(data);
            if (response) {
                displayNotification({ message: t('Charge type was successfully created.') });

                if (onSubmitBtnClick) {
                    const uuid = response.data.response.entityId;
                    onSubmitBtnClick(uuid);
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: ILoadChargeTypeRequestDto) => {
        if (entity) {
            updateData(entity.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, entity, updateData]);

    const validateName = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('name', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    register('requiresProof');
    const onChangeStatusHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue('requiresProof', event.target.checked, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <TextField
                        {...register('name', {
                            required: true,
                            validate: validateName
                        })}
                        required
                        autoFocus
                        autoComplete='off'
                        label={t('NAME')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 30 } }}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />

                    <FormControlLabel
                        control={<Checkbox
                            checked={getValues('requiresProof')}
                            onChange={onChangeStatusHandler}
                        />}
                        label={t('REQUIRES PROOF')}
                    />
                </Stack>
            </form>
        );
    }, [
        errors.name, getValues, handleSubmit, onChangeStatusHandler,
        onSubmit, register, t, validateName
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${entity ? 'EDIT' : 'CREATE'} CHARGE TYPE`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default LoadChargeTypeDialog;