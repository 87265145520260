import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ELoadAppointmentType } from "../../../models/LoadModels";

interface IProps {
    label?: string;
    required?: boolean;
    value?: ELoadAppointmentType;
    size?: 'small' | 'medium';
    onChange?: (event: SelectChangeEvent) => void;
}
const ShipperConsigneeAppointmentsSelect = (props: IProps) => {
    const { label = 'Type', required = false, size = 'small', onChange } = props;
    const [selectedValue, setSelectedValue] = useState<string>(props.value ? props.value.toString() : '');

    useEffect(() => {
        setSelectedValue(props.value ? props.value.toString() : '');
    }, [props.value]);

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
    }, [onChange]);

    return (
        <FormControl fullWidth required={required} size={size}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem value=''><em>None</em></MenuItem>
                <MenuItem key={ELoadAppointmentType.YES} value={ELoadAppointmentType.YES}>{ELoadAppointmentType.YES}</MenuItem>
                <MenuItem key={ELoadAppointmentType.NO} value={ELoadAppointmentType.NO}>{ELoadAppointmentType.NO}</MenuItem>
                <MenuItem key={ELoadAppointmentType.FCFS} value={ELoadAppointmentType.FCFS}>{'FIRST COME FIRST SERVE (FCFS)'}</MenuItem>
            </Select>
        </FormControl>
    );
}
export default ShipperConsigneeAppointmentsSelect;