import { Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { useNotification } from "../../../hooks/useNotification";
import { IPalletTypeRequestDto, IPalletTypeResponseDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";
import { RootState } from "../../../store/store";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import NumberField from "../../Base/NumberFieldComponent/NumberField";

const formId: string = 'pallet-type-form';
interface IProps {
    palletType?: IPalletTypeResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: (entityId?: string) => void;
}
const PalletTypeDialog = (props: IProps) => {
    const { open, palletType, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();

    const { size, mass } = useSelector((state: RootState) => state.preferenceSlice.global);
    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, setError, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IPalletTypeRequestDto>({
        defaultValues: {
            name: palletType ? palletType?.name : '',
            height: palletType ? palletType?.height : NaN,
            width: palletType ? palletType?.width : NaN,
            length: palletType ? palletType?.length : NaN,
            weight: palletType ? palletType?.weight : NaN
        }
    });

    const updateData = useCallback((uuid: string, data: IPalletTypeRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updatePalletType(uuid, data);
            if (response) {
                displayNotification({ message: t('Pallet was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: IPalletTypeRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.createPalletType(data);
            if (response) {
                displayNotification({ message: t('Pallet was successfully created.') });

                if (onSubmitBtnClick) {
                    const uuid = response.data.response.entityId;
                    onSubmitBtnClick(uuid);
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: IPalletTypeRequestDto) => {
        if (palletType) {
            updateData(palletType.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, palletType, updateData]);

    const validateName = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('name', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const validateAmountField = useCallback((value: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('height', { validate: validateAmountField });
    const onHeightChangeField = useCallback((value: number) => {
        setValue('height', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('width', { validate: validateAmountField });
    const onWidthChangeField = useCallback((value: number) => {
        setValue('width', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('length', { validate: validateAmountField });
    const onLengthChangeField = useCallback((value: number) => {
        setValue('length', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('weight');
    const onWeightChangeField = useCallback((value: number) => {
        setValue('weight', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <TextField
                        {...register('name', {
                            required: true,
                            validate: validateName
                        })}
                        required
                        autoFocus
                        autoComplete='off'
                        label={t('NAME')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                    />

                    <Stack direction="row" spacing={2}>
                        <NumberField
                            label={t('HEIGHT')}
                            units={size}
                            allowNegative={false}
                            required
                            value={getValues('height')}
                            onChange={onHeightChangeField}
                        />

                        <NumberField
                            label={t('WIDTH')}
                            units={size}
                            allowNegative={false}
                            required
                            value={getValues('width')}
                            onChange={onWidthChangeField}
                        />
                    </Stack>

                    <Stack direction="row" spacing={2}>
                        <NumberField
                            label={t('LENGTH')}
                            units={size}
                            allowNegative={false}
                            required
                            value={getValues('length')}
                            onChange={onLengthChangeField}
                        />

                        <NumberField
                            label={t('WEIGHT')}
                            units={mass}
                            allowNegative={false}
                            value={getValues('weight')}
                            onChange={onWeightChangeField}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [
        handleSubmit, onSubmit, register, validateName, t, size, getValues, 
        onHeightChangeField, onWidthChangeField, onLengthChangeField, mass, 
        onWeightChangeField
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${palletType ? 'EDIT' : 'CREATE'} PALLET`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default PalletTypeDialog;