import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    onBtnClick: () => void;
    onCopyBtnClick: () => void;
    copyDisabled?: boolean;
}
const VehicleAddButton = (props: IProps) => {
    const { onBtnClick, onCopyBtnClick, copyDisabled } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const onClickHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onCloseHandler = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        < Tooltip title={t('Add item')} placement='top' >
            <div>
                <Button
                    aria-label='Vehicle item add menu'
                    onClick={onClickHandler}
                    aria-controls={open ? 'vehicle-item-add-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    variant='outlined'
                    size='small'
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    <AddIcon fontSize='small' />&nbsp;{t('ADD')}
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    id="vehicle-item-add-menu"
                    open={open}
                    onClose={onCloseHandler}
                    onClick={onCloseHandler}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                >
                    <MenuItem onClick={onBtnClick}>
                        <ListItemIcon>
                            <AddIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant='caption'>{t('ADD')}</Typography>
                    </MenuItem>
                    <MenuItem onClick={onCopyBtnClick} disabled={copyDisabled}>
                        <ListItemIcon>
                            <ContentCopyIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant='caption'>{t('DUPLICATE')}</Typography>
                    </MenuItem>
                </Menu>
            </div>
        </Tooltip>
    );
}
export default VehicleAddButton;