import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useState } from "react";
import { EMeasureMass } from "../../../models/PreferenceModels";

interface IProps {
    label?: string;
    required?: boolean;
    value?: EMeasureMass;
    onChange?: (event: SelectChangeEvent) => void;
}
const MeasureMassSelect = (props: IProps) => {
    const { label = 'Mass', required = false, onChange } = props;
    const [selectedValue, setSelectedValue] = useState<string>(props.value ? props.value.toString() : '');

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormControl fullWidth required={required}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem key={EMeasureMass.KILOGRAM} value={EMeasureMass.KILOGRAM}>{`KILOGRAM (${EMeasureMass.KILOGRAM})`}</MenuItem>
                <MenuItem key={EMeasureMass.POUND} value={EMeasureMass.POUND}>{`POUND (${EMeasureMass.POUND})`}</MenuItem>
                <MenuItem key={EMeasureMass.TON} value={EMeasureMass.TON}>{`TON (${EMeasureMass.TON})`}</MenuItem>
            </Select>
        </FormControl>
    );
}
export default MeasureMassSelect;