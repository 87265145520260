import { useDispatch } from "react-redux";
import { ICriteria } from "../models/CommonModels";
import { InvoiceActions } from "../store/reducers/invoiceSlice";

export const useInvoice = () => {
    const dispatch = useDispatch();

    const resetState = () => {
        dispatch(InvoiceActions.resetState());
    };

    const gridRefresh = () => {
        dispatch(InvoiceActions.gridRefresh());
    };

    const applyFilter = (criteria: ICriteria[]) => {
        dispatch(InvoiceActions.applyFilter(criteria));
    };

    return { resetState, gridRefresh, applyFilter } as const;
};