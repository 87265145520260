import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../../../hooks/useNotification";
import { IDocument } from '../../../../models/DocumentModels';
import { EFileType } from "../../../../models/FileModel";
import UserService from "../../../../services/UserService";
import BaseDocument from "../../../Base/BaseDocumentComponent/BaseDocument";
import NoData from "../../../Base/NoDataComponent/NoData";
import UserDocumentDialog from "./UserDocumentDialog";
import UserDocumentItem from "./UserDocumentItem";

interface IProps {
    userId: string;
}
const UserDocument = (props: IProps) => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { userId } = props;
    const maxHeight = useRef<string | number>('350px');

    const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [data, setData] = useState<IDocument[]>([]);
    const [document, setDocument] = useState<IDocument | undefined>();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.fetchDocuments(userId);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    useEffect(() => {
        retrieveData();
    }, [refresh, retrieveData]);

    const openDocumentDialogHandler = useCallback((document?: IDocument) => {
        setDocument(document);
        setOpenDocumentDialog(true);
    }, []);

    const closeDocumentDialogHandler = useCallback(() => {
        setOpenDocumentDialog(false);
    }, []);

    const onDeleteDocumentBtnClick = useCallback((uuid: string) => {
        setLoading(true);
        (async () => {
            const [error] = await UserService.removeDocument(userId, uuid);

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            } else {
                displayNotification({ message: t('Document was successfully deleted.') });
                setLoading(false);
                refreshHandler();
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, userId]);

    const onSubmitAttachment = useCallback((documentId: string, file: File) => {
        setLoading(true);
        (async () => {
            const requestBody = {
                'documentId': documentId,
                'type': EFileType.MAIN
            };
            const data = new FormData();
            data.append('data', new Blob([JSON.stringify(requestBody)], { type: 'application/json' }));
            data.append('file', file);

            const [error] = await UserService.createDocumentAttachment(userId, documentId, data);

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            } else {
                displayNotification({ message: t('Attachment was successfully created.') });
                setLoading(false);
                refreshHandler();
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const onDeleteAttachment = useCallback((documentId: string, attachmentId: string) => {
        setLoading(true);
        (async () => {
            const [error] = await UserService.removeDocumentAttachment(userId, documentId, attachmentId);

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            } else {
                displayNotification({ message: t('Attachment was successfully deleted.') });
                setLoading(false);
                refreshHandler();
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const onChangeAcordeonHandler = useCallback((value: string | false) => {
        setExpanded(value);
    }, []);

    const refreshHandler = useCallback(() => {
        setRefresh(refresh => !refresh);
    }, []);

    return (
        <>
            <BaseDocument
                loading={loading}
                addBtnLabel={t('DOCUMENT')}
                addBtnTooltip={t('Create document')}
                onAddBtnClick={() => openDocumentDialogHandler()}
                maxHeight={maxHeight.current}
                content={
                    data
                        ? data.map((item) =>
                            <UserDocumentItem
                                key={`user-document-${item.uuid}`}
                                document={item}
                                expanded={expanded === item.uuid}
                                maxHeight={maxHeight.current}
                                onChangeAcordeon={onChangeAcordeonHandler}
                                onEditBtnClick={openDocumentDialogHandler}
                                onDeleteBtnClick={onDeleteDocumentBtnClick}
                                onSubmitAttachment={onSubmitAttachment}
                                onDeleteAttachment={onDeleteAttachment}
                            />
                        )
                        : <NoData label={t('There are no documents')} height={maxHeight.current} />
                }
            />

            {openDocumentDialog &&
                <UserDocumentDialog
                    open={openDocumentDialog}
                    onCloseBtnClick={closeDocumentDialogHandler}
                    userId={userId}
                    document={document}
                    onSubmitBtnClick={refreshHandler}
                />
            }
        </>
    );
}
export default UserDocument;