import { IAddressResponseDto, IAddressShortResponseDto } from "./AddressModels";
import { EReputationLevel } from "./CommonModels";

export enum EWarehouseStatus {
   NONE,
   ACTIVE = 'ACTIVE',
   INACTIVE = 'INACTIVE',
   INCOMPLETE = 'INCOMPLETE'
}

export interface IWarehouseAutocompleteResponseDto {
   uuid: string;
   name: string;
   status: EWarehouseStatus;
   reputationLevel: EReputationLevel;
   reviewMark: number;
   address: IAddressShortResponseDto;
}

export interface IWarehouseResponseDto {
   uuid: string;
   name: string;
   contactName: string;
   address: IAddressResponseDto;
   email?: string;
   phone?: string;
   types: IWarehouseTypeResponseDto[];
   reviewMark: number;
   favorite: boolean;
   reputationLevel: EReputationLevel;
   status: EWarehouseStatus;
   createdBy: string;
   createdDate: number;
   updatedBy?: string;
   updatedDate?: number;
}

export interface IWarehouseTypeResponseDto {
   uuid: string;
   name: string;
}

export interface IWarehouseOverviewResponseDto {
   uuid: string;
   name: string;
   contactName: string;
   address: IAddressShortResponseDto;
   types: IWarehouseTypeResponseDto[];
}

export interface IWarehouseRequestDto {
   name: string;
   contactName: string;
   typeIds: string[];
   addressId: string;
   email?: string;
   phone?: string;
   statusActive?: boolean;
}

export interface IWarehouseCoverFaceResponseDto {
   uuid: string;
   name: string;
   types: IWarehouseTypeResponseDto[];
   reviewMark: number;
   favorite: boolean;
   reputationLevel: EReputationLevel;
   status: EWarehouseStatus;
   readonly: boolean;
}

export interface IWarehouseContactResponseDto {
   email?: string;
   phone?: string;
   cell?: string;
   fax?: string;
}

export interface IWarehouseContactRequestDto {
   email?: string;
   phone?: string;
   cell?: string;
   fax?: string;
}

export interface IWarehouseStatusReputationRequestDto {
   status: EWarehouseStatus;
   reputationLevel: EReputationLevel;
}

export interface IWarehouseStatusReputationResponseDto {
   uuid: string;
   status: EWarehouseStatus;
   reputationLevel: EReputationLevel;
}

export interface IWarehouseIdNameAddressResponseDto {
   uuid: string;
   name: string;
   address: IAddressShortResponseDto;
}

export interface IFavoritesWarehouse {
   uuid: string,
   address: IAddressResponseDto,
   contactName: string,
   email: string,
   name: string,
   phone: string,
   reputationLevel: string,
   status: string,
   types: IWarehouseTypeResponseDto[]
}

export interface IWarehouseShipperConsigneeResponseDto {
   name: string;
   contactName: string;
   address: IAddressShortResponseDto;
   email?: string;
   phone?: string;
}