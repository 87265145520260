import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Stack, TextField } from "@mui/material";
import { ReactNode, useCallback, useState } from "react";
import FileUploadBtn from './FileUploadBtn';

interface IProps {
    label: string;
    tooltip?: string;
    variant?: 'outlined' | 'contained',
    size?: 'small' | 'medium',
    icon?: ReactNode;
    disabled?: boolean;
    acceptedFiles?: string;
    onSelect: (file: File) => void;
}
const FileUploadField = (props: IProps) => {
    const {
        label, tooltip, variant = 'contained', size = 'medium',
        icon = <CloudUploadIcon />, acceptedFiles = '*', onSelect, disabled = false
    } = props;

    const [fileName, setFileName] = useState<string>('File not selected.');

    const onSelectHandler = useCallback((file: File) => {
        setFileName(file.name);
        onSelect(file);
    }, [onSelect]);

    return (
        <Stack direction='row' spacing={1}>
            <FileUploadBtn
                label={label}
                tooltip={tooltip}
                variant={variant}
                size={size}
                icon={icon}
                disabled={disabled}
                fullHeight
                acceptedFiles={acceptedFiles}
                onSelect={onSelectHandler}
            />

            <TextField
                fullWidth
                slotProps={{ input: { readOnly: true } }}
                value={fileName}
            />
        </Stack>
    );
}
export default FileUploadField;