import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Divider, IconButton, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getDateFormat } from '../../../helpers/dateHelper';
import { isEmpty } from '../../../helpers/generalHelper';
import { useNotification } from '../../../hooks/useNotification';
import { EButtonColor } from '../../../models/EnumGeneral';
import { IReviewRequestDto, IReviewResponseDto } from '../../../models/ReviewModels';
import { RootState } from '../../../store/store';
import ButtonConfirmation from '../ActionConfirmationComponent/ButtonConfirmation';
import BackdropProgress from "../BackdropComponent/BackdropProgress";
import NoData from '../NoDataComponent/NoData';
import RatingView from '../RatingComponent/RatingView';
import BaseReviewDialog from './BaseReviewDialog';

interface IProps {
    maxHeight: string | number;
    fetchApi: () => Promise<any>;
    createApi: (data: IReviewRequestDto) => Promise<any>;
    updateApi: (uuid: string, data: IReviewRequestDto) => Promise<any>;
    deleteApi: (uuid: string) => Promise<any>;
    onRefresh: () => void;
}
const BaseReview = (props: IProps) => {
    const { maxHeight, fetchApi, createApi, updateApi, deleteApi, onRefresh } = props

    const [refresh, setRefresh] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IReviewResponseDto[]>([]);
    const [entity, setEntity] = useState<IReviewResponseDto | undefined>();
    const [currentItem, setCurrentItem] = useState<IReviewResponseDto | undefined>();
    const [dialogToggle, setDialogToggle] = useState<boolean>(false);
    const [prevBtnDisabled, setPrevBtnDisabled] = useState<boolean>(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState<boolean>(true);

    const textHeight = useRef<string>(`calc(${maxHeight} - 115px)`);
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { dateFormat } = useSelector((state: RootState) => state.preferenceSlice.user);

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await fetchApi();
            if (response) {
                const responseData: IReviewResponseDto[] = response.data.body || [];
                setData(responseData);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchApi]);

    const findItemIndex = useCallback((itemId: string) => {
        return data.findIndex(item => item.uuid === itemId);
    }, [data]);

    const setPrevNextBtnDisabled = useCallback((item?: IReviewResponseDto) => {
        if (item) {
            const currentIndex: number = findItemIndex(item.uuid);
            setPrevBtnDisabled(currentIndex === 0);
            setNextBtnDisabled(currentIndex === data.length - 1);
        }
    }, [data.length, findItemIndex]);

    useEffect(() => {
        retrieveData();
    }, [refresh, retrieveData]);

    useEffect(() => {
        if (!isEmpty(data)) {
            let item: IReviewResponseDto | undefined;
            if (currentItem) {
                item = data.find((item) => item.uuid === currentItem.uuid);
            } else {
                item = data[0];
            }
            setCurrentItem(item);
            setPrevNextBtnDisabled(item);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onAddBtnClickHandler = useCallback(() => {
        setDialogToggle(true);
        setEntity(undefined);
    }, []);

    const onEditBtnClickHandler = useCallback(() => {
        setDialogToggle(true);
        setEntity(currentItem);
    }, [currentItem]);

    const onRefreshHandler = useCallback(() => {
        onRefresh();
        setRefresh(!refresh);
    }, [onRefresh, refresh]);

    const onCreateBtnClickHandler = useCallback(() => {
        setCurrentItem(undefined);
        onRefreshHandler();
    }, [onRefreshHandler]);

    const onUpdateBtnClickHandler = useCallback(() => {
        onRefreshHandler();
    }, [onRefreshHandler]);

    const onDeleteBtnClickHandler = useCallback(() => {
        if (currentItem) {
            setLoading(true);
            (async () => {
                const [error, response] = await deleteApi(currentItem.uuid);
                if (response) {
                    displayNotification({ message: t('Review was successfully deleted.') });
                    setCurrentItem(undefined);
                    onRefreshHandler();
                }

                if (error) {
                    displayNotification({ type: 'error', message: error?.message });
                    setLoading(false);
                }
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentItem, deleteApi, onRefreshHandler, t]);

    const onDialogToggleHandler = useCallback(() => {
        setDialogToggle(!dialogToggle);
    }, [dialogToggle]);

    const onPrevBtnClickHandler = useCallback((uuid: string) => {
        const currentIndex: number = findItemIndex(uuid);
        if (currentIndex > 0) {
            const prevIndex = currentIndex - 1;
            const item: IReviewResponseDto = data[prevIndex];
            setCurrentItem(item);
            setPrevNextBtnDisabled(item);
        }
    }, [data, findItemIndex, setPrevNextBtnDisabled]);

    const onNextBtnClickHandler = useCallback((uuid: string) => {
        const currentIndex: number = findItemIndex(uuid);
        if (currentIndex < data.length - 1) {
            const nextIndex = currentIndex + 1;
            const item: IReviewResponseDto = data[nextIndex];
            setCurrentItem(item);
            setPrevNextBtnDisabled(item);
        }
    }, [data, findItemIndex, setPrevNextBtnDisabled]);

    return (
        <Box>
            <Stack direction='column' spacing={1}>
                <Stack direction='row' spacing={2}>
                    <Tooltip title={t('Add review')} arrow={true} placement='top'>
                        <Button
                            variant='outlined'
                            size={'small'}
                            startIcon={<AddIcon fontSize={'small'} />}
                            onClick={onAddBtnClickHandler}
                        >
                            {t('ADD')}
                        </Button>
                    </Tooltip>

                    <Tooltip title={t('Edit review')} arrow={true} placement='top'>
                        <span>
                            <Button
                                variant='outlined'
                                size={'small'}
                                startIcon={<EditIcon fontSize={'small'} />}
                                onClick={onEditBtnClickHandler}
                                disabled={isEmpty(data) || currentItem === undefined || loading}
                            >
                                {t('Edit')}
                            </Button>
                        </span>
                    </Tooltip>

                    <ButtonConfirmation
                        variant='outlined'
                        onConfirm={onDeleteBtnClickHandler}
                        label={t('Delete')}
                        icon={<DeleteIcon fontSize='small' />}
                        color={EButtonColor.error}
                        disabled={isEmpty(data) || currentItem === undefined || loading}
                        tooltip={t('Delete review')}
                    />
                </Stack>

                <Divider />

                {!isEmpty(data)
                    ?
                    <BackdropProgress open={loading}>
                        <Stack direction='column' spacing={1} sx={{ height: maxHeight, maxHeight: maxHeight, padding: '5px 10px 0px 10px' }}>
                            <Stack direction='row'>
                                <Stack flex={1} direction='column'>
                                    {currentItem
                                        ? <Typography variant='body1'>{currentItem.createdBy}</Typography>
                                        : <Skeleton animation='wave' height={20} />
                                    }

                                    {currentItem
                                        ? <Typography variant='caption'>{getDateFormat(currentItem.createdDate, dateFormat)}</Typography>
                                        : <Skeleton animation='wave' height={20} />
                                    }
                                </Stack>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <RatingView value={currentItem?.mark || 0} />
                                </Box>
                            </Stack>

                            <Divider />

                            <Box sx={{ height: textHeight.current, maxHeight: textHeight.current, overflowY: 'scroll' }}>
                                <Typography align='justify' paragraph>
                                    {currentItem?.message}
                                </Typography>
                            </Box>

                            <Divider />

                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Tooltip title={t('Previous')} placement='left' >
                                    <span>
                                        <IconButton
                                            color='primary'
                                            onClick={() => onPrevBtnClickHandler(currentItem?.uuid || '')}
                                            disabled={prevBtnDisabled}
                                        >
                                            <KeyboardArrowLeftIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip >

                                <Tooltip title={t('Next')} placement='right' >
                                    <span>
                                        <IconButton
                                            color='primary'
                                            onClick={() => onNextBtnClickHandler(currentItem?.uuid || '')}
                                            disabled={nextBtnDisabled}
                                        >
                                            <KeyboardArrowRightIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip >
                            </Box>
                        </Stack>
                    </BackdropProgress>
                    : <NoData label={t('There are no reviews.')} height={maxHeight} />
                }
            </Stack>

            {dialogToggle &&
                <BaseReviewDialog
                    open={dialogToggle}
                    entity={entity}
                    createApi={createApi}
                    updateApi={updateApi}
                    onCreateBtnClick={onCreateBtnClickHandler}
                    onUpdateBtnClick={onUpdateBtnClickHandler}
                    onCloseBtnClick={onDialogToggleHandler}
                />
            }
        </Box>
    );
}
export default BaseReview;