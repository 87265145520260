import { Box, Paper } from "@mui/material";
import LoadTransportsGrid from "./LoadTransportsGrid";

interface IProps {
    loadId: string;
}
const LoadTransportsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px', height: 300 }}>
                <LoadTransportsGrid
                    loadId={props.loadId}
                />
            </Box>
        </Paper>
    );
}
export default LoadTransportsStep;