import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Checkbox, FormControlLabel, Paper, Stack, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNotification } from "../../../../hooks/useNotification";
import { usePreference } from "../../../../hooks/usePreference";
import { IUserPreference, IUserPreferenceNotificationRequestDto } from "../../../../models/PreferenceModels";
import UserPreferenceService from '../../../../services/UserPreferenceService';
import { RootState } from "../../../../store/store";
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";

const formId: string = 'personal-preference-notification-form';
const PersonalPreferenceNotification = () => {
    const { t } = useTranslation();

    const { notificationEmailEnabled, notificationPushEnabled } = useSelector((state: RootState) => state.preferenceSlice.user);

    const { register, setValue, getValues, reset, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<IUserPreferenceNotificationRequestDto>({
        defaultValues: {
            pushEnabled: notificationPushEnabled,
            emailEnabled: notificationEmailEnabled
        }
    });

    const { displayNotification } = useNotification();
    const { updateUserPreference } = usePreference();
    const [loading, setLoading] = useState(false);

    const fetchPersonalPreference = useCallback(() => {
        (async () => {
            const [, response] = await UserPreferenceService.fetch();
            if (response) {
                const preferences: IUserPreference = response.data.body;
                updateUserPreference({ ...preferences, loaded: true });

                reset({
                    pushEnabled: preferences.notificationPushEnabled,
                    emailEnabled: preferences.notificationEmailEnabled
                });
            }
        })();
    }, [reset, updateUserPreference]);

    const onSubmit = useCallback((data: IUserPreferenceNotificationRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserPreferenceService.updateNotification(data);
            if (response) {
                displayNotification({ message: t('Preferences was successfully updated.') });
                fetchPersonalPreference();
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchPersonalPreference, t]);

    register('pushEnabled');
    const onChangePushEnabledHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue('pushEnabled', event.target.checked, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('emailEnabled');
    const onChangeEmailEnabledHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue('emailEnabled', event.target.checked, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    return (
        <Paper sx={{ marginTop: '10px' }}>
            <BackdropProgress open={loading || false}>
                <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ padding: '10px' }}>
                        <Stack direction='row' spacing={2}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={getValues('pushEnabled')}
                                    onChange={onChangePushEnabledHandler}
                                />}
                                label={t('PUSH')}
                            />

                            <FormControlLabel
                                control={<Checkbox
                                    checked={getValues('emailEnabled')}
                                    onChange={onChangeEmailEnabledHandler}
                                />}
                                label={t('EMAIL')}
                            />
                        </Stack>

                        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Tooltip title={t('SAVE')} arrow>
                                <span>
                                    <Button
                                        form={formId}
                                        type='submit'
                                        variant='contained'
                                        disabled={!isValid || !isDirty}
                                        size='small'
                                        startIcon={<SaveAsIcon />}
                                    >
                                        {t('SAVE')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>
                </form>
            </BackdropProgress>
        </Paper>
    );
}
export default PersonalPreferenceNotification;