import CategoryIcon from '@mui/icons-material/Category';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { currencyFormat } from '../../../helpers/NumberHelper';
import { useLoad } from '../../../hooks/useLoad';
import { ECriteriaExpression, ICriteria, IFilter } from '../../../models/CommonModels';
import { ELoadStatus, ILoadCoverFaceResponseDto } from '../../../models/LoadModels';
import LoadService from '../../../services/LoadService';
import { RootState } from '../../../store/store';
import BaseStepCoverFace from '../../Base/BaseStepComponent/BaseStepCoverFace';

interface IProps {
    data: ILoadCoverFaceResponseDto;
}
const LoadStepCoverFace = (props: IProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const { mass } = useSelector((state: RootState) => state.preferenceSlice.global);
    const { gridRefresh, stepRefresh } = useLoad();

    const style: {} = data.chargesRequiresProofs ? {} : { color: 'red' };

    const content: React.ReactNode = <Box flex={1}>
        <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
            <CategoryIcon style={{ color: 'rgb(106, 104, 104)' }} />

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    <b>#{data.idno}</b>
                </Typography>

                <Typography sx={{ fontSize: '13px', color: `${data.status === ELoadStatus.INCOMPLETE ? 'red' : 'black'}` }}>
                    <b>{data.status.toString().replaceAll('_', ' ')}</b>
                </Typography>
            </Stack>

            <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    <Link to={`/customers/${data.customer.uuid}`} target='_blank'>
                        {data.customer.name.toUpperCase()}
                    </Link>
                </Typography>

                <Typography>
                    {
                        data.flatRate
                            ? <b>{`${t('RATE')}:`} {currencyFormat(data.rate)} | {t('FLAT RATE:')} {currencyFormat(data.flatRate)}</b>
                            : <b>{`${t('RATE')}:`} {currencyFormat(data.rate)}</b>
                    }
                </Typography>
            </Stack>

            <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    <b>{`${t('O. CHARGES')}:`} <span style={style}>{currencyFormat(data.charges)}</span></b>
                </Typography>

                <Typography>
                    <b>{`${t('TOTAL RATE')}:`} {currencyFormat(data.totalRate)}</b>
                </Typography>
            </Stack>

            <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    <b>{t('SHP WT:')} {data.shipperWeight} {mass}</b>
                </Typography>

                <Typography>
                    <b>{t('CNE WT:')} {data.consigneeWeight} {mass}</b>
                </Typography>
            </Stack>
        </Stack>
    </Box>;

    const onFavoriteBtnClickHandler = useCallback(() => {
        (async () => {
            const ids: string[] = [data.uuid];
            if (data.favorite) {
                LoadService.removeFavorites(ids);
            } else {
                LoadService.createFavorites(ids);
            }
            gridRefresh();
            stepRefresh();
        })();
    }, [data.favorite, data.uuid, gridRefresh, stepRefresh]);

    const onDownloadApiHandler = useCallback((filter: IFilter) => {
        const criterias: ICriteria[] = [{
            property: "uuid",
            value: data.uuid,
            expression: ECriteriaExpression.EQUALS
        }];
        filter.criteria = criterias;

        return LoadService.downloadReport(filter);
    }, [data.uuid]);

    return (
        <BaseStepCoverFace
            content={content}

            favoriteBtnAddTooltip={t('Add to favorites')}
            favoriteBtnRemoveTooltip={t('Remove from favorites')}
            favoriteBtn={data.favorite}
            onFavoriteBtnClick={onFavoriteBtnClickHandler}

            downloadBtnTooltip={t('Download load')}
            downloadApi={onDownloadApiHandler}
        />
    );
}
export default LoadStepCoverFace;