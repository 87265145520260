import { Checkbox, Divider, FormControlLabel, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../helpers/textHelper";
import { useNotification } from "../../../hooks/useNotification";
import { IAddressShortResponseDto } from "../../../models/AddressModels";
import { ELoadAppointmentType, ELoadShipperConsigneeDirection, ELoadShipperConsigneeStatus, ILoadShipperConsigneeRequestDto, ILoadShipperConsigneeResponseDto } from "../../../models/LoadModels";
import { IWarehouseAutocompleteResponseDto, IWarehouseShipperConsigneeResponseDto } from "../../../models/WarehouseModels";
import LoadService from "../../../services/LoadService";
import WarehouseService from "../../../services/WarhouseService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import Address from "../../Base/GeolocationComponent/Address";
import StatusSelect from "../../Base/StatusSelectComponent/StatusSelect";
import WarehouseAutocomplete from "../../WarehouseModule/WarehouseAutocomplete";
import ShipperConsigneeAppointmentsSelect from "./ShipperConsigneeAppointmentsSelect";

const statusData: string[] = Object.keys(ELoadShipperConsigneeStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== ELoadShipperConsigneeStatus[ELoadShipperConsigneeStatus.NONE])
    .map(key => key.toString());

interface IProps {
    direction: ELoadShipperConsigneeDirection;
    entity?: ILoadShipperConsigneeResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: (entityId?: string) => void;
}
const ShipperConsigneeDialog = (props: IProps) => {
    const { open, direction, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'shipper-consignee-form';
    const [loading, setLoading] = useState(false);

    const [warehouseId, setWarehouseId] = useState<string>();
    const [address, setAddress] = useState<IAddressShortResponseDto>();

    const { register, setValue, getValues, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<ILoadShipperConsigneeRequestDto>({
        defaultValues: {
            direction: entity ? entity.direction : direction,
            name: entity ? entity.name : '',
            addressId: entity ? entity.address.uuid : '',
            contactName: entity ? entity.contactName : '',
            contactEmail: entity ? entity.contactEmail : '',
            phone: entity ? entity.phone : '',
            tollFree: entity ? entity.tollFree : '',
            hours: entity ? entity.hours : '',
            appointments: entity ? entity.appointments : ELoadAppointmentType.NONE,
            majorIntersectionsDirections: entity ? entity.majorIntersectionsDirections : '',
            status: entity ? entity.status : ELoadShipperConsigneeStatus.NONE,
            duplicate: false
        }
    });

    const copyFieldsFromWarehouse = useCallback((warehouseId: string) => {
        (async () => {
            setLoading(true);
            const [error, response] = await WarehouseService.fetchDetailsForShipperConsignee(warehouseId);
            if (response) {
                const warehouse: IWarehouseShipperConsigneeResponseDto = response.data.body;
                setValue('name', warehouse.name, { shouldValidate: true, shouldDirty: true });
                setValue('addressId', warehouse.address.uuid, { shouldValidate: true, shouldDirty: true });
                setValue('contactName', warehouse.contactName, { shouldValidate: true, shouldDirty: true });
                setValue('contactEmail', warehouse.email || '', { shouldValidate: true, shouldDirty: true });
                setValue('phone', warehouse.phone || '', { shouldValidate: true, shouldDirty: true });

                setAddress(warehouse.address);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue]);

    useEffect(() => {
        if (!entity && warehouseId && !isBlank(warehouseId)) {
            copyFieldsFromWarehouse(warehouseId);
        }
    }, [entity, warehouseId, copyFieldsFromWarehouse]);

    const updateData = useCallback((uuid: string, data: ILoadShipperConsigneeRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateShipperConsignee(uuid, data);
            if (response) {
                displayNotification({ message: t(`${direction} was successfully updated.`) });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [direction, t]);

    const createData = useCallback((data: ILoadShipperConsigneeRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.createShipperConsignee(data);
            if (response) {
                displayNotification({ message: t(`${direction} was successfully created.`) });

                const uuid = response.data.response.entityId;
                if (onSubmitBtnClick) {
                    onSubmitBtnClick(uuid);
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [direction, t]);

    const onSubmit = useCallback((data: ILoadShipperConsigneeRequestDto) => {
        if (entity) {
            updateData(entity.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, entity, updateData]);

    const validateForBlankField = useCallback((value: string) => {
        return !isBlank(value);
    }, []);

    register('addressId', { validate: validateForBlankField });
    const onAddressChangeHandler = useCallback((value: IAddressShortResponseDto | null) => {
        setValue('addressId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateAppointmentsType = useCallback((value: ELoadAppointmentType) => {
        return ELoadAppointmentType[value] !== undefined
            && value !== 0
            && value !== ELoadAppointmentType[ELoadAppointmentType.NONE];
    }, []);

    register('appointments', { validate: validateAppointmentsType });
    const onChangeAppointmentsHandler = useCallback((event: SelectChangeEvent) => {
        setValue('appointments', event.target.value as ELoadAppointmentType, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateStatusType = useCallback((value: ELoadShipperConsigneeStatus) => {
        return ELoadShipperConsigneeStatus[value] !== undefined
            && value !== 0
            && value !== ELoadShipperConsigneeStatus[ELoadShipperConsigneeStatus.NONE];
    }, []);

    register('status', { validate: validateStatusType });
    const onChangeStatusHandler = useCallback((event: SelectChangeEvent) => {
        setValue('status', event.target.value as ELoadShipperConsigneeStatus, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('duplicate');
    const onChangeDuplicateHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue('duplicate', event.target.checked, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onWarehouseChangeHandler = useCallback((value: IWarehouseAutocompleteResponseDto | null) => {
        setWarehouseId(value?.uuid)
    }, []);

    const onNameChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setValue('name', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onContactNameChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setValue('contactName', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onContactEmailChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setValue('contactEmail', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onPhoneChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setValue('phone', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    {!entity &&
                        <WarehouseAutocomplete
                            label={t('COPY FROM WAREHOUSE')}
                            showAddress
                            showMark
                            disableInactiveItems
                            value={warehouseId}
                            onChange={onWarehouseChangeHandler}
                        />
                    }

                    {!entity &&
                        <Divider />
                    }

                    <TextField
                        {...register('name', { required: true, validate: validateForBlankField })}
                        required
                        label={t('NAME')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        value={getValues('name')}
                        onChange={onNameChangeHandler}
                    />

                    <Address
                        required
                        value={entity ? entity.address : address}
                        onChange={onAddressChangeHandler}
                    />

                    <Stack direction='row' spacing={2}>
                        <TextField
                            {...register('contactName')}
                            label={t('CONTACT NAME')}
                            fullWidth
                            slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                            value={getValues('contactName')}
                            onChange={onContactNameChangeHandler}
                        />

                        <TextField
                            {...register('contactEmail')}
                            label={t('CONTACT EMAIL')}
                            fullWidth
                            slotProps={{ htmlInput: { minLength: 1, maxLength: 60 } }}
                            value={getValues('contactEmail')}
                            onChange={onContactEmailChangeHandler}
                        />
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <TextField
                            {...register('phone')}
                            label={t('PHONE')}
                            fullWidth
                            slotProps={{ htmlInput: { minLength: 1, maxLength: 20 } }}
                            value={getValues('phone')}
                            onChange={onPhoneChangeHandler}
                        />

                        <TextField
                            {...register('tollFree')}
                            label={t('TOLL FREE')}
                            fullWidth
                            slotProps={{ htmlInput: { minLength: 1, maxLength: 20 } }}
                        />
                    </Stack>

                    <TextField
                        {...register('hours')}
                        label={t('HOURS')}
                        fullWidth
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                    />

                    <ShipperConsigneeAppointmentsSelect
                        label={t('APPOINTMENTS')}
                        required
                        value={getValues('appointments')}
                        size='medium'
                        onChange={onChangeAppointmentsHandler}
                    />

                    <TextField
                        {...register('majorIntersectionsDirections')}
                        label={t('MAJOR INTERSECTIONS / DIRECTIONS')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                    />

                    <StatusSelect
                        label={t('STATUS')}
                        required
                        data={statusData}
                        value={getValues('status')}
                        size='medium'
                        fullWidth
                        onChange={onChangeStatusHandler}
                    />

                    {!entity &&
                        <FormControlLabel
                            control={<Checkbox
                                checked={getValues('duplicate')}
                                onChange={onChangeDuplicateHandler}
                            />}
                            label={t(`ADD AS ${direction === ELoadShipperConsigneeDirection.CONSIGNEE ? 'SHIPPER' : 'CONSIGNEE'}`)}
                        />
                    }
                </Stack>
            </form>
        );
    }, [
        handleSubmit, onSubmit, entity, t, warehouseId, onWarehouseChangeHandler,
        register, validateForBlankField, getValues, onNameChangeHandler, address,
        onAddressChangeHandler, onContactNameChangeHandler, onContactEmailChangeHandler,
        onPhoneChangeHandler, onChangeAppointmentsHandler, onChangeStatusHandler,
        onChangeDuplicateHandler, direction
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${entity ? 'EDIT' : 'CREATE'} ${direction}`)}
            maxWidth={'sm'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default ShipperConsigneeDialog;