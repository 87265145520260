import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TranslateIcon from '@mui/icons-material/Translate';
import { Box, Step, StepButton, StepContent, Stepper, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IStepItem } from "../../../models/CommonModels";
import BaseDialog from '../../Base/BaseDialogComponent/BaseDialog';
import PersonalPreferenceDateTime from './Step/PersonalPreferenceDateTime';
import PersonalPreferenceLanguage from './Step/PersonalPreferenceLanguage';
import PersonalPreferenceNotification from './Step/PersonalPreferenceNotification';
import PersonalProfile from './Step/PersonalProfile';

interface IProps {
    open: boolean;
    onCloseBtnClick: () => void;
}
const PersonalPreferenceDialog = (props: IProps) => {
    const { open, onCloseBtnClick } = props;
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState<number>(- 1);

    const onChangeStepHandler = useCallback((step: number) => {
        if (step === activeStep) {
            setActiveStep(-1);
        } else {
            setActiveStep(step);
        }
    }, [activeStep]);

    const items = useMemo((): IStepItem[] => {
        return [{
            title: t('PROFILE'),
            content: <PersonalProfile />,
            icon: <AccountCircleIcon />
        }, {
            title: t('LANGUAGE'),
            content: <PersonalPreferenceLanguage />,
            icon: <TranslateIcon />
        }, {
            title: t('DATE AND TIME FORMAT'),
            content: <PersonalPreferenceDateTime />,
            icon: <CalendarMonthIcon />
        }, {
            title: t('NOTIFICATIONS'),
            content: <PersonalPreferenceNotification />,
            icon: <NotificationsIcon />
        }];
    }, [t]);

    const onBuildContent = useCallback(() => {
        return (
            <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
                {
                    items.map((item, index) =>
                        <Step
                            key={item.title}
                            completed={false}
                        >
                            <StepButton
                                sx={{ '&:hover': { backgroundColor: 'rgb(242 242 242 / 87%)' } }}
                                color='inherit'
                                icon={item.icon ? item.icon : undefined}
                                onClick={() => onChangeStepHandler(index)}
                            >
                                <Box display='flex' alignItems='center' justifyContent='flex-start'>
                                    <Typography variant="body2">{item.title}</Typography>
                                </Box>
                            </StepButton>
                            <StepContent>
                                {item.content}
                            </StepContent>
                        </Step>
                    )
                }
            </Stepper>
        );
    }, [activeStep, items, onChangeStepHandler]);

    return (
        <BaseDialog
            open={open}
            title={t('PERSONAL PROFILE')}
            maxWidth={'md'}
            buildContent={onBuildContent}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnColor={'primary'}
            closeBtnVariant={'text'}
            closeBtnIconHide={true}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default PersonalPreferenceDialog;