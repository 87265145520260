import { Autocomplete, AutocompleteRenderInputParams, Box, Divider, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../helpers/generalHelper";
import { EUserStatus, IUserAutocompleteResponseDto } from "../../models/UserModels";
import UserService from "../../services/UserService";
import AvatarComponent from "../Base/AvatarComponent/AvatarComponent";

interface IProps {
    label?: string;
    required?: boolean;
    value?: string;
    disabled?: boolean;
    size?: 'medium' | 'small';
    showStatus?: boolean;
    disableInactiveItems?: boolean;
    onChange?: (newValue: IUserAutocompleteResponseDto | null) => void;
}
const UserAutocomplete = (props: IProps) => {
    const {
        size = 'medium', disabled, label = 'Users', required = false, onChange, value,
        showStatus = false, disableInactiveItems = false
    } = props;

    const [data, setData] = useState<IUserAutocompleteResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<IUserAutocompleteResponseDto | null>(null);

    useEffect(() => {
        if (isEmpty(data)) {
            (async () => {
                const [, response] = await UserService.fetchAutocomplete();
                if (response) {
                    setData(response.data.body);
                }
            })();
        }
    }, [data]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: IUserAutocompleteResponseDto | null = data.find((item) => item.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }
        }
    }, [value, data]);

    const onChangeHandler = useCallback((event: any, newValue: IUserAutocompleteResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: IUserAutocompleteResponseDto) => {
        return (
            <div {...props} key={item.uuid} style={{ display: 'flex', gap: '10px' }}>
                <Box width={'30px'}>
                    <AvatarComponent
                        data={item.avatar}
                        defaultValue={item.fullName}
                        color={item.color}
                    />
                </Box>

                <Box>
                    <Typography variant={'body2'}>{item.fullName}</Typography>
                    <Typography variant='caption'>
                        <Divider />
                        {item.role.toString().replaceAll('_', ' ')}{showStatus ? ` [ ${item.status} ]` : ''}
                    </Typography>
                </Box>
            </div>
        );
    }, [showStatus]);

    const onBuildOptionLabel = useCallback((item: IUserAutocompleteResponseDto) => {
        return item.fullName;
    }, []);

    const onBuildOptionKey = useCallback((item: IUserAutocompleteResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    const getOptionDisabledHandler = useCallback((item: IUserAutocompleteResponseDto) => {
        return disableInactiveItems
            ? item.status === EUserStatus.INACTIVE || item.status === EUserStatus.INCOMPLETE
            : false;
    }, [disableInactiveItems]);

    return (
        <Autocomplete
            fullWidth
            size={size}
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionDisabled={getOptionDisabledHandler}
            disabled={disabled}
        />
    );
}
export default UserAutocomplete;