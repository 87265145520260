import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { removeEmptyFields } from "../../../../helpers/generalHelper";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { IDocument } from "../../../../models/DocumentModels";
import { ELoadDocumentType, ILoadDocumentRequestDto } from "../../../../models/LoadModels";
import LoadService from "../../../../services/LoadService";
import { RootState } from "../../../../store/store";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import DateField from "../../../Base/DateComponent/DateField";
import DocumentTypeSelect from "../../../Base/DocumentTypeSelectComponent/DocumentTypeSelect";

const documentTypeData: string[] = Object.keys(ELoadDocumentType)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== ELoadDocumentType[ELoadDocumentType.NONE])
    .filter(key => key !== ELoadDocumentType[ELoadDocumentType.CHARGE])
    .filter(key => key !== ELoadDocumentType[ELoadDocumentType.INVOICE])
    .map(key => key.toString());

interface IProps {
    loadId: string;
    document?: IDocument;
    rateConfirmation: boolean;
    billOfLading: boolean;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const LoadDocumentDialog = (props: IProps) => {
    const {
        open, loadId, document, rateConfirmation, billOfLading,
        onCloseBtnClick, onSubmitBtnClick
    } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'load-document-form';
    const isEdit = useRef<boolean>(document !== undefined);
    const { dateFormat } = useSelector((state: RootState) => state.preferenceSlice.user);

    const [loading, setLoading] = useState(false);

    const { register, setError, clearErrors, setValue, getValues, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<ILoadDocumentRequestDto>({
        defaultValues: {
            type: isEdit.current ? document?.type as ELoadDocumentType : undefined,
            expirationDate: isEdit.current ? document?.expirationDate : NaN,
            number: isEdit.current ? document?.number : undefined,
            caption: isEdit.current ? document?.caption : undefined
        }
    });

    const updateData = useCallback((uuid: string, data: ILoadDocumentRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateDocument(loadId, uuid, data);
            if (response) {
                displayNotification({ message: t('Document was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId]);

    const createData = useCallback((data: ILoadDocumentRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.createDocument(loadId, data);
            if (response) {
                displayNotification({ message: t('Document was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId]);

    const onSubmit = useCallback((data: ILoadDocumentRequestDto) => {
        const normalisedData: ILoadDocumentRequestDto = removeEmptyFields(data) as unknown as ILoadDocumentRequestDto;
        if (document) {
            updateData(document.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [createData, document, updateData]);

    const validateType = useCallback((value: ELoadDocumentType) => {
        return ELoadDocumentType[value] !== undefined && value !== ELoadDocumentType.NONE;
    }, []);

    register('type', { validate: validateType });
    const onDocumentTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('type', event.target.value as ELoadDocumentType, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateNumber = useCallback((value?: string) => {
        if (value && value.length > 0 && isBlank(value)) {
            const message: string = t('Only blank spaces are not allowed.');
            setError('number', { message: message });
            return false;
        }
        clearErrors('number');
        return true;
    }, [clearErrors, setError, t]);

    const validateCaption = useCallback((value?: string) => {
        if (value && value.length > 0 && isBlank(value)) {
            const message: string = t('Only blank spaces are not allowed.');
            setError('caption', { message: message });
            return false;
        }
        clearErrors('caption');
        return true;
    }, [clearErrors, setError, t]);

    register('expirationDate');
    const onChangeExpirationDateHandler = useCallback((timestamp?: number, dateStr?: string) => {
        setValue('expirationDate', timestamp, { shouldDirty: true });
    }, [setValue]);

    const disableTypes = useMemo((): string[] => {
        const types: string[] = [];

        if (rateConfirmation) {
            types.push(ELoadDocumentType.RATE_CONFIRMATION.toString());
        }

        if (billOfLading) {
            types.push(ELoadDocumentType.BILL_OF_LADING.toString());
        }

        return types;
    }, [billOfLading, rateConfirmation]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <DocumentTypeSelect
                        label={t('TYPE')}
                        size='medium'
                        data={documentTypeData}
                        required
                        value={getValues('type')}
                        disableItems={disableTypes}
                        onChange={onDocumentTypeChangeHandler}
                    />

                    {getValues('type') === ELoadDocumentType.DOCUMENT &&
                        <TextField
                            {...register('number', { validate: validateNumber })}
                            autoComplete={'off'}
                            label={t('NUMBER')}
                            slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                            error={!!errors.number}
                            helperText={errors.number?.message}
                        />
                    }

                    {(getValues('type') !== ELoadDocumentType.BILL_OF_LADING && getValues('type') !== ELoadDocumentType.RATE_CONFIRMATION) &&
                        <TextField
                            {...register('caption', { validate: validateCaption })}
                            autoComplete={'off'}
                            label={t('NOTES')}
                            slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                            error={!!errors.caption}
                            helperText={errors.caption?.message}
                        />
                    }

                    {getValues('type') === ELoadDocumentType.DOCUMENT &&
                        <DateField
                            label={t('EXPIRATION DATE')}
                            size='medium'
                            value={getValues('expirationDate')}
                            format={dateFormat}
                            onChange={onChangeExpirationDateHandler}
                        />
                    }
                </Stack>
            </form>
        );
    }, [
        errors.caption, errors.number, getValues, handleSubmit, dateFormat,
        onChangeExpirationDateHandler, onDocumentTypeChangeHandler, onSubmit,
        register, t, validateCaption, validateNumber, disableTypes
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} DOCUMENT`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    )
}
export default LoadDocumentDialog;