import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { ILoadChargeRequestDto, ILoadChargeResponseDto, ILoadChargeTypeResponseDto } from "../../../../models/LoadModels";
import LoadService from "../../../../services/LoadService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import CurrencyField from "../../../Base/CurrencyFieldComponent/CurrencyField";
import FileUploadField from '../../../Base/FileComponent/Upload/FileUploadField';
import LoadChargeTypeAutocomplete from "../../ChargeType/LoadChargeTypeAutocomplete";
import LoadChargeTypeDialog from '../../ChargeType/LoadChargeTypeDialog';

const formId: string = 'load-charges-form';
interface IProps {
    loadId: string;
    entity?: ILoadChargeResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const LoadChargesDialog = (props: IProps) => {
    const { open, loadId, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const [typeToggle, setTypeToggle] = useState<boolean>(false);
    const [typeRefresh, setTypeRefresh] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<ILoadChargeRequestDto>({
        defaultValues: {
            typeId: entity ? entity.type.uuid : '',
            amount: entity ? entity.amount : NaN,
            file: undefined,
            removeFile: false
        }
    });

    const updateData = useCallback((uuid: string, data: ILoadChargeRequestDto) => {
        setLoading(true);
        (async () => {
            const requestBody = { 'typeId': data.typeId, 'amount': data.amount, 'removeFile': data.removeFile };
            const request = new FormData();
            request.append('request', new Blob([JSON.stringify(requestBody)], { type: 'application/json' }));
            if (data.file) {
                request.append('file', data.file);
            }

            const [error, response] = await LoadService.updateCharge(loadId, uuid, request);
            if (response) {
                displayNotification({ message: t('Load charge was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t]);

    const createData = useCallback((data: ILoadChargeRequestDto) => {
        setLoading(true);
        (async () => {
            const requestBody = { 'typeId': data.typeId, 'amount': data.amount };
            const request = new FormData();
            request.append('request', new Blob([JSON.stringify(requestBody)], { type: 'application/json' }));
            if (data.file) {
                request.append('file', data.file);
            }

            const [error, response] = await LoadService.createCharge(loadId, request);
            if (response) {
                displayNotification({ message: t('Load charge was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t]);

    const onSubmit = useCallback((data: ILoadChargeRequestDto) => {
        if (entity) {
            updateData(entity.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, entity, updateData]);

    const onTypeToggleHandler = useCallback(() => {
        setTypeToggle(typeToggle => !typeToggle);
    }, []);

    const validateTypeField = useCallback((value: string) => {
        return !isBlank(value);
    }, []);

    register('typeId', { validate: validateTypeField });
    const onTypeChangeHandler = useCallback((value: ILoadChargeTypeResponseDto | null) => {
        setValue('typeId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateAmountField = useCallback((value: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('amount', { validate: validateAmountField });
    const onAmountChangeHandler = useCallback((value: number) => {
        setValue('amount', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('file');
    const onSelectFileHandler = useCallback((file: File) => {
        setValue('file', file, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('removeFile');
    const onRemoveFileHandler = useCallback(() => {
        setValue('removeFile', true, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <LoadChargeTypeAutocomplete
                            required
                            label={t('TYPE')}
                            value={getValues('typeId')}
                            refresh={typeRefresh}
                            onChange={onTypeChangeHandler}
                        />

                        <Tooltip title={t('CREATE TYPE')}>
                            <IconButton onClick={onTypeToggleHandler}>
                                <PlaylistAddIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <CurrencyField
                        required
                        label={t("AMOUNT")}
                        value={getValues('amount')}
                        onChange={onAmountChangeHandler}
                    />

                    {((!entity || !entity.file) || getValues('removeFile') || (entity && !entity.file && getValues('file'))) &&
                        <FileUploadField
                            label={t('UPLOAD')}
                            variant='outlined'
                            onSelect={onSelectFileHandler}
                        />
                    }

                    {entity && entity.file && !getValues('removeFile') &&
                        <Button
                            variant='outlined'
                            size={'small'}
                            color='error'
                            startIcon={<DeleteIcon fontSize={'small'} />}
                            sx={{ height: '53px', width: '150px' }}
                            onClick={onRemoveFileHandler}
                        >
                            {t('REMOVE FILE')}
                        </Button>
                    }
                </Stack>
            </form>
        );
    }, [
        handleSubmit, onSubmit, t, getValues, typeRefresh, onTypeChangeHandler, entity,
        onTypeToggleHandler, onAmountChangeHandler, onSelectFileHandler, onRemoveFileHandler
    ]);

    const onSubmitTypeHandler = useCallback((entityId?: string) => {
        if (entityId) {
            setValue('typeId', entityId);
            setTypeRefresh(typeRefresh => !typeRefresh);
        }
    }, [setValue]);

    return (
        <>
            <BaseCrudDialog
                loading={loading}
                open={open}
                title={t(`${entity ? 'EDIT' : 'CREATE'} CHARGE`)}
                maxWidth={'xs'}
                formId={formId}
                buildContent={onBuildContent}
                saveBtnDisabled={!isValid || !isDirty}
                saveBtnLabel={t('SAVE')}
                onCloseBtnClick={onCloseBtnClick}
                closeBtnLabel={t('CLOSE')}
            />

            {typeToggle &&
                <LoadChargeTypeDialog
                    open={typeToggle}
                    onSubmitBtnClick={onSubmitTypeHandler}
                    onCloseBtnClick={onTypeToggleHandler}
                />
            }
        </>
    );
}
export default LoadChargesDialog;