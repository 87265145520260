import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoBox from '../../../Base/NotificationComponent/InfoBox';

const data: { title: string, message: string }[] = [{
    title: 'INCOMPLETE',
    message: 'it means that the SHIPPER(S) / CONSIGNEE(S), DRIVER(S) and the TRANSPORT(S) have not been set.'
}, {
    title: 'PENDING',
    message: 'it means that the SHIPPER(S) / CONSIGNEE(S), DRIVER(S) and the TRANSPORT(S) have been set. Load is ready to start.'
}, {
    title: 'IN PROGRESS',
    message: 'it means that the LOAD is in the process of delivery.'
}, {
    title: 'DELIVERED',
    message: 'it means that the load is delivered and there is a RATE CONFIRMATION and at least a BILL OF LADING.'
}, {
    title: 'INVOICED',
    message: 'it means that all required proofs are present and an invoice was issued.'
}, {
    title: 'UNPAID',
    message: 'it means that an invoice was issued and sent to payment but the payment has not yet been made.'
}, {
    title: 'PAID',
    message: 'it means that the customer has paid the invoice.'
}];

interface IProps {
    selectItems?: string[];
    excludeItems?: string[];
}
const LoadStatusLegend = (props: IProps) => {
    const { selectItems = [], excludeItems = [] } = props;
    const { t } = useTranslation();

    return (
        <Stack spacing={2}>
            {data.filter((item) => !excludeItems.includes(item.title)).map((item, index) =>
                <InfoBox
                    key={`legend-${index}`}
                    title={t(item.title)}
                    message={t(item.message)}
                    colorRed={selectItems.includes(item.title)}
                />
            )}
        </Stack>
    );
}
export default LoadStatusLegend;