import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICriteria } from "../../models/CommonModels";
import { IReduxGrid } from "../../models/ReduxModels";

interface InvoiceState {
    grid: IReduxGrid;
};

const initialState: InvoiceState = {
    grid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    }
};

export const InvoiceSlice = createSlice({
    name: 'invoiceSlice',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
        gridRefresh(state: InvoiceState) {
            state.grid.refresh = !state.grid.refresh;
        },
        applyFilter(state: InvoiceState, action: PayloadAction<ICriteria[]>) {
            state.grid.criterias = action.payload;
        }
    }
});

export const InvoiceActions = InvoiceSlice.actions;
export const InvoiceReducer = InvoiceSlice.reducer;