import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Paper, Stack, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { usePreference } from "../../../../hooks/usePreference";
import { EDateFormat, ETimeFormat, IPreferenceDateTimeRequestDto, IUserPreference } from "../../../../models/PreferenceModels";
import UserPreferenceService from '../../../../services/UserPreferenceService';
import { RootState } from "../../../../store/store";
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";
import DateFormatSelect from '../../../Base/DateComponent/DateFormatSelect';
import TimeFormatSelect from '../../../Base/DateComponent/TimeFormatSelect';

const formId: string = 'personal-preference-datetime-form';
const PersonalPreferenceDateTime = () => {
    const { t } = useTranslation();

    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);

    const { register, setValue, getValues, reset, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<IPreferenceDateTimeRequestDto>({
        defaultValues: {
            dateFormat: dateFormat,
            timeFormat: timeFormat
        }
    });

    const { displayNotification } = useNotification();
    const { updateUserPreference } = usePreference();
    const [loading, setLoading] = useState(false);

    const validateIsBlank = useCallback((value: string) => {
        return !isBlank(value);
    }, []);

    const fetchPersonalPreference = useCallback(() => {
        (async () => {
            const [, response] = await UserPreferenceService.fetch();
            if (response) {
                const preferences: IUserPreference = response.data.body;
                updateUserPreference({ ...preferences, loaded: true });

                reset({
                    dateFormat: preferences.dateFormat,
                    timeFormat: preferences.timeFormat
                });
            }
        })();
    }, [reset, updateUserPreference]);

    const onSubmit = useCallback((data: IPreferenceDateTimeRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserPreferenceService.updateDateTime(data);
            if (response) {
                displayNotification({ message: t('Preferences was successfully updated.') });
                fetchPersonalPreference();
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchPersonalPreference, t]);

    register('dateFormat', { validate: validateIsBlank });
    const onDateChangeHandler = useCallback((value: string) => {
        setValue('dateFormat', value as EDateFormat, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('timeFormat', { validate: validateIsBlank });
    const onTimeChangeHandler = useCallback((value: string) => {
        setValue('timeFormat', value as ETimeFormat, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    return (
        <Paper sx={{ marginTop: '10px' }}>
            <BackdropProgress open={loading || false}>
                <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ padding: '10px' }}>
                        <Stack direction='row' spacing={2}>
                            <DateFormatSelect
                                required
                                label={t('DATE FORMAT')}
                                value={getValues('dateFormat')}
                                onChange={onDateChangeHandler}
                            />

                            <TimeFormatSelect
                                required
                                label={t('TIME FORMAT')}
                                value={getValues('timeFormat')}
                                onChange={onTimeChangeHandler}
                            />
                        </Stack>

                        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Tooltip title={t('SAVE')} arrow>
                                <span>
                                    <Button
                                        form={formId}
                                        type='submit'
                                        variant='contained'
                                        disabled={!isValid || !isDirty}
                                        size='small'
                                        startIcon={<SaveAsIcon />}
                                    >
                                        {t('SAVE')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>
                </form>
            </BackdropProgress>
        </Paper>
    );
}
export default PersonalPreferenceDateTime;