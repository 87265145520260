import { Autocomplete, AutocompleteRenderInputParams, Divider, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../../helpers/generalHelper";
import { ELoadCopyItemType, ILoadCopyItemAutocompleteResponseDto } from "../../../models/LoadModels";
import { EMeasureMass } from "../../../models/PreferenceModels";
import LoadService from "../../../services/LoadService";

interface IProps {
    label?: string;
    required?: boolean;
    loadId: string;
    type: ELoadCopyItemType;
    massType: EMeasureMass;
    onChange?: (newValue: ILoadCopyItemAutocompleteResponseDto | null) => void;
}
const LoadCopyItemsAutocomplete = (props: IProps) => {
    const { label = 'Items', required = false, massType, onChange, loadId, type } = props;

    const { t } = useTranslation();
    const [data, setData] = useState<ILoadCopyItemAutocompleteResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<ILoadCopyItemAutocompleteResponseDto | null>(null);

    useEffect(() => {
        setData([]);
        setSelectedValue(null);
        (async () => {
            const [, response] = await LoadService.fetchCopyItemsAutocomplete(loadId, type);
            if (response) {
                setData(response.data.body);
            }
        })();
    }, [type, loadId]);

    useEffect(() => {
        if (ELoadCopyItemType.LAST_CREATED_ITEM === type && !isEmpty(data)) {
            setSelectedValue(data[0]);

            if (onChange) {
                onChange(data[0]);
            }
        }
    }, [data, onChange, type]);

    const onChangeHandler = useCallback((event: any, newValue: ILoadCopyItemAutocompleteResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: ILoadCopyItemAutocompleteResponseDto) => {
        return (
            <div {...props} key={item.uuid} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Typography variant='body2'>{item.shipperConsigneeName}</Typography>
                <Typography variant='caption' sx={{ fontSize: 11 }}>
                    <Divider />
                    {`#${item.idno} | ${item.type} | ${t('QTY')} ${item.quantity} | ${t('WT')} ${item.weight} ${massType}`}
                </Typography>
            </div>
        );
    }, [massType, t]);

    const onBuildOptionLabel = useCallback((item: ILoadCopyItemAutocompleteResponseDto) => {
        return `${item.shipperConsigneeName} | #${item.idno} | ${item.type}`;
    }, []);

    const onBuildOptionKey = useCallback((item: ILoadCopyItemAutocompleteResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    return (
        <Autocomplete
            fullWidth
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
}
export default LoadCopyItemsAutocomplete;