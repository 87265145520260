import { Navigate, Route, Routes } from "react-router-dom";
import AuthInitConfig from "../components/AuthModule/Control/AuthInitConfig";
import AuthRefreshToken from "../components/AuthModule/Control/AuthRefreshToken";
import AuthSocket from "../components/AuthModule/Socket/AuthSocket";
import AuditPage from "../pages/AuditPage";
import CustomerPage from "../pages/CustomerPage";
import InvoicePage from "../pages/InvoicePage";
import IssuePage from "../pages/IssuePage";
import LayoutPage from "../pages/LayoutPage";
import LoadPage from "../pages/LoadPage";
import LogoutPage from "../pages/LogoutPage";
import TransportPage from "../pages/TransportPage";
import UserPage from "../pages/UserPage";
import WarehousePage from "../pages/WarehousePage";

const PrivateRoutes = () => {
    return (
        <>
            <AuthRefreshToken />
            <AuthSocket />
            <AuthInitConfig />

            <Routes>
                <Route path="/" element={<LayoutPage />}>
                    <Route index element={<div></div>} />
                    <Route path="logout" element={<LogoutPage />} />
                    <Route path="audit" element={<AuditPage />} />
                    <Route path="users" element={<UserPage />} />
                    <Route path="users/:id" element={<UserPage />} />
                    <Route path='users/:id/:step' element={<UserPage />} />
                    <Route path="transports" element={<TransportPage />} />
                    <Route path="transports/:id" element={<TransportPage />} />
                    <Route path='transports/:id/:step' element={<TransportPage />} />
                    <Route path='loads' element={<LoadPage />} />
                    <Route path='loads/:id' element={<LoadPage />} />
                    <Route path='loads/:id/:step' element={<LoadPage />} />
                    <Route path="tasks" element={<IssuePage />} />
                    <Route path="tasks/:id" element={<IssuePage />} />
                    <Route path="tasks/:id/:step" element={<IssuePage />} />
                    <Route path="customers" element={<CustomerPage />} />
                    <Route path="customers/:id" element={<CustomerPage />} />
                    <Route path='customers/:id/:step' element={<CustomerPage />} />
                    <Route path="warehouses" element={<WarehousePage />} />
                    <Route path="warehouses/:id" element={<WarehousePage />} />
                    <Route path='warehouses/:id/:step' element={<WarehousePage />} />
                    <Route path="invoices" element={<InvoicePage />} />
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </>
    );
}
export default PrivateRoutes;