import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useIssue } from "../../../../hooks/useIssue";
import { useNotification } from "../../../../hooks/useNotification";
import { EIssueStatus, IIssueAssignedOnRequestDto, IIssueAssignedOnResponseDto } from "../../../../models/IssueModels";
import { IUserAutocompleteResponseDto } from "../../../../models/UserModels";
import IssueService from "../../../../services/IssueService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import UserAutocomplete from "../../../UserModule/UserAutocomplete";

interface IProps {
    open: boolean;
    entity: IIssueAssignedOnResponseDto;
    status: EIssueStatus;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const IssueAssignedOnDialog = (props: IProps) => {
    const { open, entity, status, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useIssue();
    const formId: string = 'issue-assignedon-form';

    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IIssueAssignedOnRequestDto>({
        defaultValues: {
            assignedOnId: entity.assignedOn ? entity.assignedOn.uuid : undefined
        }
    });

    const onSubmit = useCallback((data: IIssueAssignedOnRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await IssueService.assignUser(entity.uuid, data.assignedOnId);
            if (response) {
                displayNotification({ message: t('Task was successfully assigned.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity.uuid, onCloseBtnClick, onSubmitBtnClick, t]);

    const validateAssignedOnField = useCallback((value?: string) => {
        if (entity && status === EIssueStatus.IN_PROGRESS) {
            return value !== undefined;
        }
        return true;
    }, [entity, status]);

    register('assignedOnId', { validate: validateAssignedOnField });
    const onAssignedOnChangeHandler = useCallback((value: IUserAutocompleteResponseDto | null) => {
        setValue('assignedOnId', value?.uuid, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <UserAutocomplete
                        label={t('ASSIGNED ON')}
                        value={getValues('assignedOnId')}
                        onChange={onAssignedOnChangeHandler}
                        showStatus
                        disableInactiveItems
                        required={entity && status === EIssueStatus.IN_PROGRESS}
                    />
                </Stack>
            </form>
        );
    }, [entity, status, getValues, handleSubmit, onAssignedOnChangeHandler, onSubmit, t]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`ASSIGN USER #${entity.idno}`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default IssueAssignedOnDialog;