import { IFilter } from "../models/CommonModels";
import { IAuthority } from "../models/PermissionModels";
import { IUserContactRequestDto, IUserDocumentRequestDto, IUserEmployeeRequestDto, IUserIncomeRequestDto, IUserPayRequestDto, IUserRequestDto, IUserStatusRequestDto } from "../models/UserModels";
import { API } from "./APIService";

class UserService {
  public static fetchAllByFilter = async (filter: IFilter): Promise<any> => {
    const [error, response] = await API('POST', 'user/all', JSON.stringify(filter));
    return [error, response];
  };

  public static downloadReport = async (filter: IFilter): Promise<any> => {
    const [error, response] = await API('POST', 'user/report', filter, undefined, undefined, 'blob');
    return [error, response];
  };

  public static create = async (data: IUserRequestDto): Promise<any> => {
    const [error, response] = await API('POST', 'user', JSON.stringify(data));
    return [error, response];
  };

  public static fetchOverview = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', `user/${uuid}/overview`);
    return [error, response];
  };

  public static updateOverview = async (uuid: string, data: IUserRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', `user/${uuid}/overview`, data);
    return [error, response];
  };

  public static isEmailAvailable = async (email: string): Promise<any> => {
    const [error, response] = await API('GET', `user/available/email/${email}`);
    return [error, response];
  };

  public static fetchCoverFaceData = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', `user/${uuid}/coverFace`);
    return [error, response];
  };

  public static remove = async (uuid: string): Promise<any> => {
    const [error, response] = await API('DELETE', `user/${uuid}`);
    return [error, response];
  };

  public static createFavorites = async (ids: string[]): Promise<any> => {
    const [error, response] = await API('POST', 'user/favorite', ids);
    return [error, response];
  };

  public static removeFavorites = async (ids: string[]): Promise<any> => {
    const [error, response] = await API('DELETE', 'user/favorite', ids);
    return [error, response];
  };

  public static fetchDocuments = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', `user/${uuid}/documents`);
    return [error, response];
  };

  public static createDocument = async (uuid: string, data: IUserDocumentRequestDto): Promise<any> => {
    const [error, response] = await API('POST', `user/${uuid}/documents`, data);
    return [error, response];
  };

  public static updateDocument = async (uuid: string, documentId: string, data: IUserDocumentRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', `user/${uuid}/documents/${documentId}`, data);
    return [error, response];
  };

  public static removeDocument = async (uuid: string, documentId: string): Promise<any> => {
    const [error, response] = await API('DELETE', `user/${uuid}/documents/${documentId}`);
    return [error, response];
  };

  public static createDocumentAttachment = async (uuid: string, documentId: string, data: FormData): Promise<any> => {
    const [error, response] = await API('PUT', `user/${uuid}/documents/${documentId}/attachments`, data, undefined, 'multipart/form-data');
    return [error, response];
  };

  public static removeDocumentAttachment = async (uuid: string, documentId: string, attachmentId: string): Promise<any> => {
    const [error, response] = await API('DELETE', `user/${uuid}/documents/${documentId}/attachments/${attachmentId}`);
    return [error, response];
  };

  public static fetchDescription = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', `user/${uuid}/description`);
    return [error, response];
  };

  public static updateDescription = async (uuid: string, description?: string): Promise<any> => {
    const [error, response] = await API('PUT', `user/${uuid}/description`, description);
    return [error, response];
  };

  public static fetchStatus = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', `user/status/${uuid}`);
    return [error, response];
  };

  public static updateStatus = async (data: IUserStatusRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', 'user/status', data);
    return [error, response];
  };

  public static fetchPays = async (uuid: string, filter: IFilter): Promise<any> => {
    const [error, response] = await API('POST', `user/${uuid}/pays/all`, JSON.stringify(filter));
    return [error, response];
  };

  public static createPay = async (uuid: string, data: IUserPayRequestDto): Promise<any> => {
    const [error, response] = await API('POST', `user/${uuid}/pays`, data);
    return [error, response];
  };

  public static updatePay = async (uuid: string, payId: string, data: IUserPayRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', `user/${uuid}/pays/${payId}`, data);
    return [error, response];
  };

  public static removePay = async (uuid: string, payId: string): Promise<any> => {
    const [error, response] = await API('DELETE', `user/${uuid}/pays/${payId}`);
    return [error, response];
  };

  public static fetchAutocomplete = async (): Promise<any> => {
    const [error, response] = await API('GET', 'user/autocomplete');
    return [error, response];
  };

  public static fetchByTypeAutocomplete = async (role: IAuthority): Promise<any> => {
    const [error, response] = await API('GET', `user/${role}/autocomplete`);
    return [error, response];
  };

  public static fetchContacts = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', `user/${uuid}/contacts`);
    return [error, response];
  };

  public static updateContacts = async (uuid: string, data: IUserContactRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', `user/${uuid}/contacts`, data);
    return [error, response];
  };

  public static fetchIncomesAutocomplete = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', `/user/${uuid}/incomes/autocomplete`);
    return [error, response];
  };

  public static fetchIncomes = async (uuid: string, filter: IFilter): Promise<any> => {
    const [error, response] = await API('POST', `user/${uuid}/incomes/all`, JSON.stringify(filter));
    return [error, response];
  };

  public static removeIncome = async (uuid: string, incomeId: string): Promise<any> => {
    const [error, response] = await API('DELETE', `user/${uuid}/incomes/${incomeId}`);
    return [error, response];
  };

  public static createIncome = async (uuid: string, data: IUserIncomeRequestDto): Promise<any> => {
    const [error, response] = await API('POST', `user/${uuid}/incomes`, data);
    return [error, response];
  };

  public static updateIncome = async (uuid: string, incomeId: string, data: IUserIncomeRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', `user/${uuid}/incomes/${incomeId}`, data);
    return [error, response];
  };

  public static fetchEmployee = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', `user/${uuid}/employee`);
    return [error, response];
  };

  public static updateEmployee = async (uuid: string, data: IUserEmployeeRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', `user/${uuid}/employee`, data);
    return [error, response];
  };
}
export default UserService;