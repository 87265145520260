import { Box, Stack } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IMapMarker } from "../../../models/CommonModels";
import { ICustomerResponseDto } from "../../../models/CustomerModels";
import BaseDialog from "../../Base/BaseDialogComponent/BaseDialog";
import GoogleMap from "../../Base/GeolocationComponent/GoogleMap";
import CustomerMapsMarker from "./CustomerMapsMarker";

interface IProps {
    data: ICustomerResponseDto[];
    open: boolean;
    onCloseBtnClick: () => void;
}
const CustomerMapsViewDialog = (props: IProps) => {
    const { open, onCloseBtnClick, data } = props;
    const { t } = useTranslation();

    const mapsData: IMapMarker[] = data.map((item) => ({
        lat: item.address.latitude,
        lng: item.address.longitude,
        title: item.name,
        content: <CustomerMapsMarker data={item} />
    }));

    const onBuildContent = useCallback(() => {
        return (
            <Stack direction='row'>
                <Box flex={1} minHeight={500}>
                    <GoogleMap markers={mapsData} markerEnable />
                </Box>
            </Stack>
        );
    }, [mapsData]);

    return (
        <BaseDialog
            open={open}
            title={t('CUSTOMERS')}
            maxWidth={'lg'}
            buildContent={onBuildContent}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
            closeBtnColor={'primary'}
            closeBtnVariant={'text'}
            closeBtnIconHide={true}
        />
    );
}
export default CustomerMapsViewDialog;