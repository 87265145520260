import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { getTimeFormatAdapter, getTimeFormatBEAdapter } from "../../../helpers/dateHelper";
import { ETimeFormat } from "../../../models/PreferenceModels";

const dateNow: Date = new Date();
const getTimeAsStr = (date: Date, format: ETimeFormat): string => {
    return dayjs(date).format(format);
};

interface IProps {
    label?: string;
    required?: boolean;
    value?: ETimeFormat;
    onChange?: (value: string) => void;
}
const TimeFormatSelect = (props: IProps) => {
    const { label = 'Time format', required = false, onChange } = props;
    const [selectedValue, setSelectedValue] = useState<string>(props.value ? getTimeFormatAdapter(props.value.toString()) : '');

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            const timeFormat: string = getTimeFormatBEAdapter(event.target.value as ETimeFormat);
            onChange(timeFormat);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormControl fullWidth required={required}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem key={ETimeFormat.h_12} value={ETimeFormat.h_12}>{`12h (${getTimeAsStr(dateNow, ETimeFormat.h_12)})`}</MenuItem>
                <MenuItem key={ETimeFormat.h_24} value={ETimeFormat.h_24}>{`24h (${getTimeAsStr(dateNow, ETimeFormat.h_24)})`}</MenuItem>
            </Select>
        </FormControl>
    );
}
export default TimeFormatSelect;